/** Split button component to select colors. */
import * as React from "react"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import { colors } from "../../utils/color"
import Color from "color"
import { Square as SquareIcon, ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material"

export interface NoColor {
  keyword: () => string
}
const nocolor: NoColor = { keyword: () => "nocolor" }
const colorCode = (color: NoColor | Color) => {
  const key = color.keyword()
  return key === "nocolor" ? "#00000000" : key
}

const options: (NoColor | Color)[] = [nocolor, ...colors]

interface ColorButtonProps {
  disabled: boolean
  onSelectColor: (color: NoColor | Color) => void
}

// MUI SplitButton
const SplitButton = (props: ColorButtonProps) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleClick = () => {
    const color = options[selectedIndex]
    props.onSelectColor(color)
  }

  const handleMenuItemClick = (_event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    const color = options[index]
    props.onSelectColor(color)
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" disabled={props.disabled}>
        <Button size="small" onClick={handleClick}>
          Color
          <SquareIcon sx={{ color: colorCode(options[selectedIndex]) }} />
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.keyword()}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <SquareIcon sx={{ color: colorCode(option) }} />
                      {option.keyword()}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default SplitButton

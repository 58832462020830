import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  InputLabel,
  FormControl
} from "@mui/material"
import { useRef } from "react"
import { AddParameter, Datatype } from "./ManageParametersTab"
import { TextFieldBooted } from "../../utils/bootstrap"
import React from "react"
import { Query } from "../FilterTab/query"

interface AddParameterDialogProps {
  open: boolean
  projectId: string
  query: Query
  onCancel: () => void
  onSubmit: (parameter: AddParameter | undefined) => void
}

export const AddParameterDialog = (props: AddParameterDialogProps) => {
  const inputProjectId = useRef<HTMLInputElement>()
  const inputParameterName = useRef<HTMLInputElement>()
  const inputUnit = useRef<HTMLInputElement>()

  const [datatype, setDatatype] = React.useState<Datatype>("text")
  const handleSetDatatype = (_event: React.MouseEvent<HTMLElement>, value: Datatype) => setDatatype(value)

  const handleCancel = () => props.onCancel()

  const handleSubmit = () => {
    const projectId = inputProjectId.current?.value
    const name = inputParameterName.current?.value

    const getUnit = () => {
      if (inputUnit.current?.value === "" || !inputUnit.current?.value) return "<null>"
      else return inputUnit.current?.value
    }

    const unit = getUnit()
    const query = JSON.stringify(props.query)

    const parameter: AddParameter | undefined =
      projectId?.length && name?.length
        ? {
            projectId,
            name,
            datatype,
            unit,
            query
          }
        : undefined
    props.onSubmit(parameter)
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>Digital Twin Add Parameter</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <TextFieldBooted
            id={"id"}
            inputRef={inputProjectId}
            label={"Project Id"}
            defaultValue={props.projectId}
            disabled={true}
          />
          <TextFieldBooted id={"name"} inputRef={inputParameterName} label={"Name"} autoFocus={true} required={true} />
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Type
            </InputLabel>
            <ToggleButtonGroup
              color="primary"
              value={datatype}
              exclusive
              onChange={handleSetDatatype}
              aria-label="Platform"
              style={{ marginTop: 24 }}
            >
              <ToggleButton value="text">Text</ToggleButton>
              <ToggleButton value="number">Number</ToggleButton>
              <ToggleButton value="boolean">Boolean</ToggleButton>
              <ToggleButton value="date">Date</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
          {(datatype === "number" || datatype === "text") && (
            <TextFieldBooted id={"unit"} inputRef={inputUnit} label={"Unit"} />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

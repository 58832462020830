/** Wrapper for table component. */
import { TableContainer, Table, TableHead, TableRow, TableBody } from "@mui/material"

const Tabular = (props: { toHeader: () => JSX.Element[]; toRows: () => JSX.Element[] }) => {
  const header = props.toHeader()
  const rows = props.toRows()

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>{header}</TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default Tabular

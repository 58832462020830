import { DriveItem } from "@microsoft/microsoft-graph-types"
import { SharepointData } from "../useHooks/useDocuments"

export interface TreeNode {
  itemPath: string
  name: string | undefined
  children: TreeNode[]
  itemId: string
  isFolder: boolean
}

function createNode(splitPath: string[], tree: TreeNode[], path: string, itemId: string, isFolder: boolean): void {
  const name = splitPath.shift()
  const idx = tree.findIndex((e: TreeNode) => {
    return e.name === name
  })
  if (idx < 0) {
    tree.push({
      itemPath: path,
      name: name,
      children: [],
      itemId: itemId,
      isFolder: isFolder
    })
    if (splitPath.length !== 0) {
      createNode(splitPath, tree[tree.length - 1].children, path, itemId, isFolder)
    }
  } else {
    createNode(splitPath, tree[idx].children, path, itemId, isFolder)
  }
}

export function parsePath(data: DriveItem[]): TreeNode[] {
  const tree: TreeNode[] = []
  for (let i = 0; i < data.length; i++) {
    var path: string = data[i].parentReference?.path!
    path += "/" + data[i].name!
    const split: string[] = path.split("/")
    const rootindex = split.indexOf("root:")
    split.splice(0, rootindex + 1)
    const isFolder = data[i].folder !== null
    createNode(split, tree, data[i].webUrl!, data[i].id!, isFolder)
  }
  return tree
}

const filterEmptyFolders = (nodes: TreeNode[]) => {
  return nodes
    .filter((item) => item.children.length > 0 || !item.isFolder)
    .map((item) => {
      if (!item.isFolder) return item
      item.children = filterEmptyFolders(item.children)
      return item
    })
}

//generate root-Nodes for the different libraries and add the associated child notes
export const getTree = (documents: SharepointData[]): TreeNode[] => {
  if (!documents) return []
  const tree = documents.map((document) => {
    const subTree = parsePath(document.allItems)
    //itemId is used for treeViewItems-Id
    const libraryTree: TreeNode = {
      isFolder: true,
      itemPath: document.libraryName,
      name: document.libraryName,
      children: subTree,
      itemId: document.libraryId
    }
    return libraryTree
  })

  const filteredTree = filterEmptyFolders(tree).filter((item) => item.children.length > 0)
  return filterEmptyFolders(filteredTree)
}

/** Highlight elements.
 * Use scene overlays, fragement geometry and material to highlight elements.
 */
import Color from "color"
import { colorToThree } from "../utils/color"
import { Selections } from "./utils"
import { Model, Viewer3D } from "./Viewer"

type InstanceTree = Autodesk.Viewing.InstanceTree
type MaterialManager = Autodesk.Viewing.Private.MaterialManager

export interface Highlight {
  selections: Selections
  color: Color
}

export type Highlights = Highlight[]

const scene = "digitaltwin-higlight-scene"

/** Creates a scene with separate depth buffer */
const createOverlayScene = (viewer: Viewer3D): void =>
  viewer.impl.createOverlayScene(scene, undefined, undefined, undefined, undefined, true)

const ensureOverlayScene = (viewer: Viewer3D): void => {
  // @ts-ignore
  if (!viewer.overlays.hasScene(scene)) {
    createOverlayScene(viewer)
  }
}

const addOverlayMesh = (viewer: Viewer3D, mesh: THREE.Mesh): void => {
  ensureOverlayScene(viewer)
  // @ts-ignore
  viewer.overlays.addMesh(mesh, scene)
}

// const removeOverlayMesh = (viewer: Viewer3D, mesh: THREE.Mesh): void => {
//   ensureOverlayScene(viewer)
//   // @ts-ignore
//   viewer.overlays.removeMesh(mesh, scene)
// }

const addOverlayMeshes = (viewer: Viewer3D, meshes: THREE.Mesh[]): void =>
  meshes.forEach((mesh) => addOverlayMesh(viewer, mesh))

// const removeOverlayMeshes = (viewer: Viewer3D, meshes: THREE.Mesh[]): void =>
//   meshes.forEach((mesh) => removeOverlayMesh(viewer, mesh))

const getMaterial = (manager: MaterialManager, name: string): THREE.Material =>
  // @ts-ignore
  manager._materials[name]

const ensureMaterial = (viewer: Viewer3D, name: string, create: () => THREE.Material): THREE.Material => {
  const manager = viewer.impl.matman()
  if (!manager) throw Error("Missing material manager")

  const existing = getMaterial(manager, name)
  if (existing) return existing

  const material = create()
  manager.addMaterial(name, material, true)

  return material
}

const createMaterial = (color: Color): THREE.Material =>
  new THREE.MeshBasicMaterial({
    // @ts-ignore
    color: colorToThree(color),
    opacity: 0.25,
    transparent: true
    //side: THREE.DoubleSide
  })

const highlightMaterial = (viewer: Viewer3D, highlight: Highlight): THREE.Material => {
  const name = `digitaltwin-highlight-${highlight.color}`
  return ensureMaterial(viewer, name, () => createMaterial(highlight.color))
}

const tryFindModel = (viewer: Viewer3D, modelId: number): Model | undefined =>
  viewer.getAllModels().find((model) => model.id === modelId)

const getInstances = (model: Model): InstanceTree => {
  const instances = model.getInstanceTree()
  if (!instances) throw Error(`Model has no instance tree ${model.id}`)
  return instances
}

const getFragments = (instances: InstanceTree, dbId: number, recursive = true): number[] => {
  const fragments: number[] = []
  instances.enumNodeFragments(dbId, (id: number) => fragments.push(id), recursive)
  return fragments
}

const getFragmentMesh = (viewer: Viewer3D, model: Model, fragId: number): THREE.Mesh =>
  viewer.impl.getRenderProxy(model, fragId) as THREE.Mesh

const cloneFragmentMesh = (viewer: Viewer3D, model: Model, fragId: number, material?: THREE.Material): THREE.Mesh => {
  const mesh = getFragmentMesh(viewer, model, fragId)
  const clone = new THREE.Mesh(mesh.geometry, material ?? mesh.material)

  clone.matrix.copy(mesh.matrixWorld)
  clone.matrixAutoUpdate = false
  clone.matrixWorldNeedsUpdate = true

  return clone
}

const createHighlightMeshes = (viewer: Viewer3D, highlight: Highlight) => {
  const material = highlightMaterial(viewer, highlight)

  return Array.from(highlight.selections).reduce((result: THREE.Mesh[], { modelId, dbIds }) => {
    const model = tryFindModel(viewer, modelId)
    if (!model) return result

    const instances = getInstances(model)
    if (!instances) return result

    const overlays = dbIds.flatMap((dbId) => {
      const fragments = getFragments(instances, dbId)
      return fragments.map((fragId) => cloneFragmentMesh(viewer, model, fragId, material))
    })

    return result.concat(overlays)
  }, [])
}

/** Adds a new highlight. */
export const applyHighlight = (viewer: Viewer3D, highlight: Highlight): THREE.Mesh[] => {
  const meshes = createHighlightMeshes(viewer, highlight)
  addOverlayMeshes(viewer, meshes)

  return meshes
}

/** Remove existing scene and meshes. */
export const resetHighlights = (viewer: Viewer3D): void => viewer.impl.removeOverlayScene(scene)

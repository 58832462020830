// This module provides the admin/project page.
// - list DT projects, show project, add project, delete project
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import { Add, Error as ErrorIcon, Delete } from "@mui/icons-material"
import { MouseEvent, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { setNotification } from "../features/notification/notificationSlice"
import { useAppDispatch } from "../app/hooks"
import { useProjects } from "./useProjects"
import { useFetchWithToken } from "../Auth/Auth0"
import { TextFieldBooted } from "../utils/bootstrap"
import SignInButton from "../utils/SignInButton"

export interface Project {
  // dt
  id: string
  name: string
  // acc
  accProjectId: string
  accUserId: string
  accFolderIds: string[]
  // aze
  azeProjectId: string
}

// #region  AddProjectDialog
interface AddProjectDialogProps {
  project: Project | undefined
  open: boolean
  onCancel: () => void
  onSubmit: (project: Project | undefined) => void
}

// try to parse autodesk Url looking for search parameter 'folderUrn', otherwise take input
const tryParseProjectId = (urlOrProjectId: string) => {
  if (!urlOrProjectId.startsWith("http")) return urlOrProjectId
  try {
    const folderUrn = new URL(urlOrProjectId).searchParams.get("folderUrn")
    return folderUrn ? folderUrn : urlOrProjectId
  } catch (reason) {
    return urlOrProjectId
  }
}

const AddProjectDialog = (props: AddProjectDialogProps) => {
  const theme = useTheme()

  const inputRefName = useRef<HTMLInputElement>()
  const inputRefAccProjectId = useRef<HTMLInputElement>()
  const inputRefAccUserId = useRef<HTMLInputElement>()
  const inputRefAzeProjectId = useRef<HTMLInputElement>()

  const [accFolderIds, setAccFolderIds] = useState<string[]>(props.project?.accFolderIds ?? [])
  const parseFolderIds = (values: readonly string[]) => values.map((value) => tryParseProjectId(value))
  const handleChangeFolderIds = (values: string[]) => setAccFolderIds(parseFolderIds(values))

  const handleCancel = () => props.onCancel()

  const id = props.project?.id ?? uuidv4()

  const handleSubmit = () => {
    const name = inputRefName.current?.value
    const accProjectIdM = inputRefAccProjectId.current?.value
    const accProjectId = accProjectIdM && !accProjectIdM.startsWith("b.") ? "b." + accProjectIdM : accProjectIdM
    const accUserId = inputRefAccUserId.current?.value
    const azeProjectId = inputRefAzeProjectId.current?.value

    const project: Project | undefined =
      name?.length &&
      id?.length &&
      accProjectId?.length &&
      accUserId?.length &&
      accFolderIds?.length &&
      azeProjectId?.length
        ? {
            id,
            name,
            accProjectId,
            accUserId,
            accFolderIds,
            azeProjectId
          }
        : undefined
    props.onSubmit(project)
  }

  const project = props.project
  return (
    <Dialog open={props.open}>
      <DialogTitle>Digital Twin Project Details</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <TextFieldBooted
            id={"name"}
            inputRef={inputRefName}
            label={"Name"}
            autoFocus={true}
            required={true}
            defaultValue={project?.name}
            disabled={project !== undefined}
          />
          <TextFieldBooted id={"id"} label={"Project Id"} defaultValue={id} disabled={true} />
          <TextFieldBooted
            id={"accProjectId"}
            inputRef={inputRefAccProjectId}
            label={"Autodesk Construction Cloud Project Id"}
            required={true}
            defaultValue={project?.accProjectId}
            disabled={project !== undefined}
          />
          <Stack direction={"row"}>
            <Box style={{ width: "70%" }}>
              <TextFieldBooted
                id={"accUserId"}
                inputRef={inputRefAccUserId}
                label={"Autodesk Construction Cloud User Id"}
                required={true}
                defaultValue={project?.accUserId}
              />
            </Box>
            <Box style={{ alignItems: "center", paddingTop: 25 }}>
              <SignInButton sx={{ color: "text.secondary" }} />
            </Box>
          </Stack>
          {/* // style is not uniform */}
          <FormControl variant="standard">
            <InputLabel className="label" shrink htmlFor="bootstrap-input" required>
              {"Autodesk Construction Cloud Folder Ids"}
            </InputLabel>
            <Autocomplete
              sx={{
                marginTop: theme.spacing(3),
                size: "small",
                backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b"
              }}
              multiple
              id="accFolderIds"
              options={[]}
              freeSolo
              defaultValue={accFolderIds}
              // @ts-ignore
              onChange={(_, value) => handleChangeFolderIds(value)}
              // @ts-ignore
              renderTags={(values: readonly string[], getTagProps) =>
                parseFolderIds(values).map((option: string, index: number) => (
                  <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => <TextField {...params} size={"small"} />}
            />
          </FormControl>
          <TextFieldBooted
            id={"azeProjectId"}
            inputRef={inputRefAzeProjectId}
            label={"Azure Active Directory Office 365 Group Id"}
            required={true}
            defaultValue={project?.azeProjectId ?? ""}
            disabled={project !== undefined}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
// #endregion

// #region Admin page
const Admin = () => {
  const theme = useTheme()
  const spacing = theme.spacing(1)
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  const { data: projects, loading, error, mutate } = useProjects()

  const [openUpdateProjectDialog, setOpenUpdateProjectDialog] = useState<Project | undefined>()
  const handleOpenUpdateProjectDialog = (project: Project) => {
    setOpenAddProjectDialog(true)
    setOpenUpdateProjectDialog(project)
  }

  const [openAddProjectDialog, setOpenAddProjectDialog] = useState<boolean>(false)
  const handleOpenAddProjectDialog = () => {
    setOpenAddProjectDialog(true)
  }
  const handleCloseAddProjectDialog = () => {
    setOpenUpdateProjectDialog(undefined)
    setOpenAddProjectDialog(false)
  }

  const handleSubmitAddProjectDialog = (project?: Project) => {
    if (!project) {
      const msg = "Failed to add project: Project is undefined."
      console.error(msg)
      dispatch(setNotification({ status: "error", message: msg }))
      handleCloseAddProjectDialog()
      return
    }

    fetchWithToken(`/api/twin/admin/projects`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(project)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate && mutate()
          dispatch(setNotification({ status: "success", message: "Project added." }))
        } else {
          dispatch(setNotification({ status: "error", message: "Failed to add Project." }))
        }
      })
      .catch((_reason) => {
        dispatch(setNotification({ status: "error", message: "Failed to add Project." }))
      })
    handleCloseAddProjectDialog()
  }

  const handleClickDeleteProject = (evt: MouseEvent<HTMLButtonElement>, project: Project) => {
    evt.stopPropagation()

    fetchWithToken(`/api/twin/admin/projects`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(project)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate && mutate()
          dispatch(setNotification({ status: "success", message: "Project deleted." }))
        } else {
          dispatch(setNotification({ status: "error", message: "Failed to delete Project." }))
        }
      })
      .catch((reason) => {
        dispatch(setNotification({ status: "error", message: "Failed to delete Project." }))
      })
  }

  const ProjectList = () => {
    if (loading) return <CircularProgress />
    if (error) return <ErrorIcon />

    const toRow = (project: Project) => (
      <TableRow
        key={project.id}
        hover={true}
        onClick={() => {
          handleOpenUpdateProjectDialog(project)
        }}
      >
        <TableCell>{project.name}</TableCell>
        <TableCell>{project.id}</TableCell>
        <TableCell>
          <IconButton onClick={(evt) => handleClickDeleteProject(evt, project)}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    )

    const rows = projects.map(toRow)

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {["Name", "Id", "More"].map((text) => (
                <TableCell key={text}>
                  <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>
      <Paper style={{ margin: spacing, padding: spacing }}>
        <Typography style={{ margin: spacing }} variant="h5">
          Projects
        </Typography>
        <Button size="small" variant="contained" startIcon={<Add />} onClick={handleOpenAddProjectDialog}>
          Create Project
        </Button>
        <ProjectList />
        {openAddProjectDialog ? (
          <AddProjectDialog
            project={openUpdateProjectDialog}
            open={openAddProjectDialog}
            onCancel={handleCloseAddProjectDialog}
            onSubmit={handleSubmitAddProjectDialog}
          />
        ) : null}
      </Paper>
    </>
  )
}
// #endregtion

export default Admin

/** Centered Info element. */

import { CircularProgress } from "@mui/material"
import { Info as InfoIcon } from "@mui/icons-material"
import ErrorIcon from "@mui/icons-material/Error"

export const LoadingInfo = (props: { message: string; children?: JSX.Element; before?: JSX.Element }) => (
  <>
    {props.before}
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress />
      <p>{props.message}</p>
      {props.children}
    </div>
  </>
)

export const ErrorInfo = (props: {
  message: string
  children?: JSX.Element
  before?: JSX.Element
  compact?: boolean
}) => {
  const fontSize = props.compact ? "medium" : "large"
  const direction = props.compact ? "row" : "column"
  const _message = props.message?.length ? props.message : "An unexpected error occured."
  return (
    <>
      {props.before}
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: direction,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ErrorIcon fontSize={fontSize} color="error" />
        <p>{_message}</p>
        {props.children}
      </div>
    </>
  )
}

export const Information = (props: {
  message: string
  children?: JSX.Element
  before?: JSX.Element
  compact?: boolean
}) => {
  const fontSize = props.compact ? "medium" : "large"
  const direction = props.compact ? "row" : "column"
  const _message = props.message?.length ? props.message : ""
  return (
    <>
      {props.before}
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: direction,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <InfoIcon fontSize={fontSize} color="primary" />
        <p>{_message}</p>
        {props.children}
      </div>
    </>
  )
}

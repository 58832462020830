import { useEffect, useState } from "react"
import { useSelection } from "../features/selection/Selection"
import { toParameterSelection } from "../Viewer/utils"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

/** Use component for selection in format for parameter DB. */
// NOTE: Can take a while, returns undefined while not finished.
const useParameterSelection = (viewer: Viewer3D): string[] | undefined => {
  const selections = useSelection()
  const [parameterSelection, setParameterSelection] = useState<string[] | undefined>()

  useEffect(() => {
    setParameterSelection(undefined)
    const updateParameterSelection = async () => {
      const newParameterSelection = await toParameterSelection(viewer, selections)
      setParameterSelection(newParameterSelection)
    }
    updateParameterSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections])

  return parameterSelection
}

export default useParameterSelection

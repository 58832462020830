import {
  Add as AddIcon,
  Download as DownloadIcon,
  Edit as EditIcon,
  Upload as UploadIcon,
  History as HistoryIcon
} from "@mui/icons-material"
import { Paper } from "@mui/material"
import { useState } from "react"
import { mutate } from "swr"
import { useAppDispatch } from "../../app/hooks"
import { useFetchWithToken } from "../../Auth/Msal"
import { setNotification } from "../../features/notification/notificationSlice"
import useUser from "../../useHooks/useUser"
import { AggregatedView, Viewables, Viewer3D } from "../../Viewer/Viewer"
import ActionButton, { ActionLoadingButton } from "../ActionButton"
import { BCFIssue, getBCFCamera, getBCFSelection, Markup } from "./BCF"
import ExportDialog from "./ExportDialog"
import ImportDialog from "./ImportDialog"
import IssuesList, { IssueData, useIssues } from "./IssuesList"
import NewDialog from "./NewDialog"
import { v4 as uuidv4 } from "uuid"
import { useSelection } from "../../features/selection/Selection"
import { getViewState } from "../../Navigation/Views"
import UpdateDialog from "./UpdateDialog"
import { LoadingInfo, ErrorInfo } from "../../Info"
import { HistoryDialog } from "../HistoryDialog"

// get viewer screenshot as base64 string
const getScreenShot64 = (viewer: Viewer3D): Promise<string> =>
  new Promise((resolve) => {
    // set longer dimension to 640 but keep aspect ratio
    let vwidth = viewer.canvas.width
    let vheight = viewer.canvas.height
    let dim = 640
    let [w, h] =
      vwidth > vheight ? [dim, Math.round(dim * (vheight / vwidth))] : [dim * Math.round(vwidth / vheight), dim]
    viewer.getScreenShot(w, h, (blob: string) => {
      let image = new Image()
      image.crossOrigin = "Anonymous"
      image.src = blob
      image.onload = function () {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")
        canvas.height = image.naturalHeight
        canvas.width = image.naturalWidth
        //@ts-ignore
        ctx.drawImage(image, 0, 0)
        const dataURL = canvas.toDataURL()
        const base64 = dataURL.replace("data:", "").replace(/^.+,/, "")
        resolve(base64)
      }
    })
  })

interface IssuesTabProps {
  aggregatedView: AggregatedView
  projectId: string
  viewables: Viewables
}

interface DTIssue extends BCFIssue {
  viewstate: string | null
}

const IssuesTab = (props: IssuesTabProps) => {
  const viewer = props.aggregatedView.viewer as Viewer3D
  const { isUser, isRead } = useUser(props.projectId)
  const selection = useSelection()
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  const { data: issues, loading, error } = useIssues(props.projectId)

  const [selected, setSelected] = useState<IssueData>()
  const handleSelected = (issue: IssueData) => setSelected(issue)

  // new
  const [openNewDialog, setOpenNewDialog] = useState<boolean>(false)
  const handleCancelNewDialog = () => setOpenNewDialog(false)
  const handleSubmitNewDialog = async (markup: Markup) => {
    const camera = getBCFCamera(props.aggregatedView)

    const selections = await getBCFSelection(viewer, selection)

    let viewpoint = {
      guid: uuidv4(),
      camera,
      selections
    }
    const snapshot = await getScreenShot64(viewer)

    const viewstate = getViewState(props.aggregatedView, props.viewables)

    let issue: DTIssue = {
      markup,
      snapshot,
      viewpoint,
      viewstate: JSON.stringify(viewstate)
    }

    const url = `/api/twin/viewer/projects/${props.projectId}/issues`
    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(issue)
    })
      .then((response) => {
        if (response.ok) {
          dispatch(setNotification({ status: "success", message: `Successfully created new issue` }))
          mutate(`/api/twin/viewer/projects/${props.projectId}/issues`)
        } else {
          dispatch(setNotification({ status: "error", message: `Failed to create new issue` }))
          console.error("Failed to create new issue.", response)
        }
      })
      .catch((error) => {
        dispatch(setNotification({ status: "error", message: `Failed to create new issue` }))
        console.error("Failed to create new issue.", error)
      })
      .finally(() => {
        setOpenNewDialog(false)
        setSelected(undefined)
      })
  }

  // update
  const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false)
  const handleCancelUpdateDialog = () => setOpenUpdateDialog(false)
  const handleSubmitUpdateDialog = async (issue: IssueData) => {
    const url = `/api/twin/viewer/projects/${props.projectId}/issues`
    fetchWithToken(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(issue)
    })
      .then((response) => {
        if (response.ok) {
          dispatch(setNotification({ status: "success", message: `Successfully updated issue` }))
          mutate(`/api/twin/viewer/projects/${props.projectId}/issues`)
        } else {
          dispatch(setNotification({ status: "error", message: `Failed to update issue` }))
          console.error("Failed to create new issue.", response)
        }
      })
      .catch((error) => {
        dispatch(setNotification({ status: "error", message: `Failed to update issue` }))
        console.error("Failed to create new issue.", error)
      })
      .finally(() => {
        setOpenUpdateDialog(false)
        setSelected(undefined)
      })
  }

  // import
  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false)
  const [loadingImport, setLoadingImport] = useState<boolean>(false)
  const handleCancelImportDialog = () => setOpenImportDialog(false)
  const handleSubmitImportDialog = (file: File) => {
    handleUpload(file)
    setOpenImportDialog(false)
  }

  const handleUpload = (file: File) => {
    setLoadingImport(true)
    const formData = new FormData()
    formData.append("files", file)

    const url = `/api/twin/viewer/projects/${props.projectId}/issues/upload`
    fetchWithToken(url, {
      method: "POST",
      body: formData
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((msg) => {
            const { newIssues, ignoredIssues, failedIssues } = msg
            const status = failedIssues ? "error" : ignoredIssues ? "info" : "success"
            const msg1 = newIssues ? `Add ${newIssues} new issues. ` : ""
            const msg2 = ignoredIssues ? `Ignored ${ignoredIssues} existing issues. ` : ""
            const msg3 = failedIssues ? `Failed to import ${failedIssues} issues. ` : ""
            const message = msg1 + msg2 + msg3
            dispatch(setNotification({ status, message }))
          })

          mutate(`/api/twin/viewer/projects/${props.projectId}/issues`)
        } else {
          dispatch(setNotification({ status: "error", message: `Failed to upload bcf file` }))
          console.error("Failed to upload bcf file.", response)
        }
      })
      .catch((error) => {
        dispatch(setNotification({ status: "error", message: `Failed to upload bcf file` }))
        console.error("Failed to upload bcf file.", error)
      })
      .finally(() => {
        setLoadingImport(false)
        setSelected(undefined)
      })
  }

  // export
  const [openExportDialog, setOpenExportDialog] = useState<boolean>(false)
  const handleCancelExportDialog = () => setOpenExportDialog(false)
  const handleSubmitExportDialog = () => {
    setOpenExportDialog(false)
  }

  // history
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false)

  if (loading) return <LoadingInfo message={"Loading Issues"} />
  if (!issues || error) return <ErrorInfo message={"Failed to load Issues"} />

  return (
    <>
      <Paper>
        <ActionButton startIcon={<AddIcon />} onClick={() => setOpenNewDialog(true)} disabled={!isUser}>
          New
        </ActionButton>
        <ActionButton
          startIcon={<EditIcon />}
          onClick={() => setOpenUpdateDialog(true)}
          disabled={!selected || !isUser}
        >
          Edit
        </ActionButton>
        <ActionLoadingButton
          startIcon={<UploadIcon />}
          onClick={() => setOpenImportDialog(true)}
          disabled={!isUser}
          loading={loadingImport}
        >
          Import
        </ActionLoadingButton>
        <ActionButton startIcon={<DownloadIcon />} onClick={() => setOpenExportDialog(true)} disabled={!isUser}>
          Export
        </ActionButton>
        <ActionButton startIcon={<HistoryIcon />} onClick={() => setOpenHistoryDialog(true)} disabled={!isRead}>
          History
        </ActionButton>
        <IssuesList
          issues={issues}
          aggregatedView={props.aggregatedView}
          projectId={props.projectId}
          viewables={props.viewables}
          onSelect={handleSelected}
        />
      </Paper>
      {openImportDialog && (
        <ImportDialog open={openImportDialog} onCancel={handleCancelImportDialog} onSubmit={handleSubmitImportDialog} />
      )}
      {openExportDialog && (
        <ExportDialog
          open={openExportDialog}
          projectId={props.projectId}
          onCancel={handleCancelExportDialog}
          onSubmit={handleSubmitExportDialog}
        />
      )}
      {openNewDialog && (
        <NewDialog
          issues={issues}
          open={openNewDialog}
          projectId={props.projectId}
          onCancel={handleCancelNewDialog}
          onSubmit={handleSubmitNewDialog}
        />
      )}
      {openUpdateDialog && selected && (
        <UpdateDialog
          issues={issues}
          open={openUpdateDialog}
          issueData={selected}
          projectId={props.projectId}
          onCancel={handleCancelUpdateDialog}
          onSubmit={handleSubmitUpdateDialog}
        />
      )}
      {openHistoryDialog && (
        <HistoryDialog
          open={openHistoryDialog}
          projectId={props.projectId}
          elementId={selected ? selected.markup.guid : "issues"}
          onClose={() => setOpenHistoryDialog(false)}
        />
      )}
    </>
  )
}

export default IssuesTab

//** Wrapper for Microsoft Authentication Layer Msal. */
import { MsalProvider, useMsal } from "@azure/msal-react"
import { PublicClientApplication, LogLevel, AuthenticationResult } from "@azure/msal-browser"
import { useEffect, useState } from "react"
import { Button, Paper, Typography, useTheme } from "@mui/material"
import { SWRError } from "../utils/swr"
import User from "../User"
import { Login as LoginIcon } from "@mui/icons-material"

const clientId = process.env.REACT_APP_AZUREAD_CLIENTID
const authority = process.env.REACT_APP_AZUREAD_AUTHORITY
const api = process.env.REACT_APP_AZUREAD_API

if (!clientId) throw new Error("Missing AZUREAD_CLIENTID")
if (!authority) throw new Error("Missing AZUREAD_AUTHORITY")
if (!api) throw new Error("Missing AZUREAD_API")

const msalConfig = {
  auth: {
    clientId,
    authority
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      }
    }
  }
}

const msalInstance = new PublicClientApplication(msalConfig)

export const MsalP = (props: { children: JSX.Element }) => (
  <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>
)

const scopes = [api]
// redirectUri has to coincide with Azure app
const redirectUri = window.location.origin + "/blank.html"

//** Login Popup. */
export const useHandleLogin = () => {
  const { instance } = useMsal()

  const request = {
    scopes,
    redirectUri: redirectUri
  }

  const handleLogin = () => {
    instance.loginPopup(request).catch((e) => console.error("Msal: Failed to logout: ", e))
  }

  return handleLogin
}

//** Logout and redirect to "/". */
export const useHandleLogout = () => {
  const { instance } = useMsal()

  const handleLogout = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: "/" }).catch((e) => console.error("Msal: Failed to logout: ", e))
  }

  return handleLogout
}

// example usage fetch Access Token and make an API call
export const MsalProfile = () => {
  const { instance, accounts } = useMsal()
  const [accessToken, setAccessToken] = useState<string>()

  const theme = useTheme()
  const spacing = theme.spacing(1)

  const loginRequest = {
    scopes
  }
  const request = {
    ...loginRequest,
    redirectUri: window.location.origin + "/blank.html",
    account: accounts[0]
  }

  function RequestAccessToken() {
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken)
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setAccessToken(response.accessToken)
        })
      })
  }

  // try get token
  if (!accessToken) {
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken)
      })
      .catch((_) => console.log("User not logged in."))
  }

  return (
    <>
      {accessToken ? (
        <User />
      ) : (
        <Paper sx={{ margin: spacing, padding: spacing }}>
          <Typography style={{ margin: spacing }} variant="h5">
            User Profile
          </Typography>
          <Button variant="contained" startIcon={<LoginIcon />} onClick={RequestAccessToken}>
            User Sign In
          </Button>
        </Paper>
      )}
    </>
  )
}

// not sure if refreshed when used in requests
export const useAccessToken = () => {
  const { instance, accounts } = useMsal()
  const [accessToken, setAccessToken] = useState<string>()

  const loginRequest = {
    scopes
  }

  const requestAccessToken = () => {
    const request = {
      ...loginRequest,
      redirectUri: window.location.origin + "/blank.html",
      account: accounts[0]
    }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken)
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setAccessToken(response.accessToken)
        })
      })
  }

  useEffect(() => {
    requestAccessToken()
  })

  return accessToken
}

export const useGetAccessToken = () => {
  const { instance, accounts } = useMsal()

  const loginRequest = {
    scopes
  }

  const getAccessToken = async () => {
    const request = {
      ...loginRequest,
      redirectUri: window.location.origin + "/blank.html",
      account: accounts[0]
    }

    const headers = (token: string) => ({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    try {
      const response = await instance.acquireTokenSilent(request)
      return headers(response.accessToken)
    } catch (e) {
      const response = await instance.acquireTokenPopup(request)
      return headers(response.accessToken)
    }
  }

  return getAccessToken
}

export const useFetchWithToken = () => {
  const getToken = useGetAccessToken()
  const fetcher = async (input: RequestInfo, init?: RequestInit) => {
    const token = await getToken()
    const headersWithToken = { ...init?.headers, ...token.headers }
    const initWithToken = { ...init, headers: headersWithToken }
    return fetch(input, initWithToken)
  }

  return fetcher
}

/** SWR fetcher with Auth0 authorization header. */
export const useFetchWithTokenSWR = () => {
  const fetchWithToken = useFetchWithToken()
  const fetcher = async (input: RequestInfo, init?: RequestInit) => {
    const response = await fetchWithToken(input, init)
    if (!response.ok) {
      const text = await response.text()
      throw new SWRError("An error occured while fetching the data!", response.status, text, response)
    } else {
      const json = await response.json()
      return json
    }
  }
  return fetcher
}

/** SWR fetcher with Auth0 authorization header for multiple urls. */
export const useFetchWithTokenSWRMulti = () => {
  const fetchWithToken = useFetchWithToken()
  return (...urls: string[]) =>
    Promise.all(
      urls.map(async (url) => {
        const response = await fetchWithToken(url, undefined)
        if (!response.ok) {
          const text = await response.text()
          throw new SWRError("An error occured while fetching the data!", response.status, text, response)
        } else {
          const json = await response.json()
          return json
        }
      })
    )
}

// Non-React Msal client.
// Used in viewer elements like propertypanel.
class Client {
  msal: PublicClientApplication

  constructor() {
    this.msal = new PublicClientApplication(msalConfig)
  }

  private async getTokenSilent(): Promise<string | undefined> {
    const loginRequest = {
      scopes
    }
    const account = this.msal.getAllAccounts()[0]

    const request = {
      ...loginRequest,
      redirectUri: window.location.origin + "/blank.html",
      account
    }
    try {
      const response: AuthenticationResult = await this.msal.acquireTokenSilent(request)
      return response.accessToken
    } catch (e) {
      console.error("Faile to aquire msal token.")
      return undefined
    }
  }

  async fetch(input: RequestInfo, init?: RequestInit) {
    const token = await this.getTokenSilent()

    const headers = {
      Authorization: `Bearer ${token}`
    }

    const headersWithToken = { ...init?.headers, ...headers }
    const initWithToken = { ...init, headers: headersWithToken }
    return fetch(input, initWithToken)
  }
}

export const MsalClient = new Client()

import "./Viewer.scss"
import { useEffect, useRef, useState } from "react"
import { Paper } from "@mui/material"

import { useSelectionEvent } from "../features/selection/Selection"
import PropertyPanel from "./PropertyPanel"
import { getExternalIdMappingAsync, getModelName, LevelsExtension } from "./utils"
import { ErrorInfo } from "../Info"
import { ModelData } from "../useHooks/useModels"

export type Document = Autodesk.Viewing.Document
export type BubbleNode = Autodesk.Viewing.BubbleNode
export type AggregatedView = Autodesk.Viewing.AggregatedView
export type Viewer3D = Autodesk.Viewing.Viewer3D
export type Model = Autodesk.Viewing.Model

type InitializerOptions = Autodesk.Viewing.InitializerOptions
type AggregatedViewInitOptions = Autodesk.Viewing.AggregatedViewInitOptions

interface ViewerProps {
  env: string
  api: string
  models: ModelData[]
  getToken: () => Promise<{ accessToken: string; expiresIn: number }>
  onSetAggregatedView: (view: AggregatedView) => void
  onSetViewables: (viewables: Viewables) => void
}

const initializer = (options: InitializerOptions) =>
  new Promise<void>((resolve) => Autodesk.Viewing.Initializer(options, resolve))

const loadDocument = (urn: string) =>
  new Promise<Document>((resolve, reject) => Autodesk.Viewing.Document.load(urn, resolve, reject))

const loadDocuments = async (urns: string[]): Promise<Document[]> => {
  const docs = []
  for (const urn of urns) {
    const doc = await loadDocument(urn)
    docs.push(doc)
  }
  return docs
}

// #region viewable
// Viewables are used to load 2D/3D views.
// The viewables of loaded models can be obtained by the model, though we need access to all.

// Collect viewables from loaded documents.
// - aggreated view of master views
// - individual 2d views
// - individual 3d views
// 2d (3d) views are grouped wrt to its model
//
// BubbleNode Objects - no redux

export interface Viewable {
  /** Displayname  */
  name: string
  /** Collection of bubblenodes that can be loaded using aggregateView.switchView(nodes, null). */
  nodes: Autodesk.Viewing.BubbleNode[]
}

export interface ViewableGroup {
  name: string
  lastModified?: string
  viewables: Viewable[]
}

export interface Viewables {
  main: [ViewableGroup]
  /** Collection of 2d geometry views. */
  twod: ViewableGroup[]
  /** Collection of 3d geometry views. */
  threed: ViewableGroup[]
}

const getMasterView = (doc: Document): BubbleNode | undefined => {
  //@ts-ignore
  const node = doc.getRoot().getDefaultGeometry(true)
  if (!node) console.warn("Failed to find view: ", doc)
  return node
}

const getMasterViews = (docs: Document[]): BubbleNode[] =>
  docs.map((doc) => getMasterView(doc)).filter((node) => node) as BubbleNode[]

const getRoleViews = (models: ModelData[], docs: Document[], role: string): ViewableGroup[] =>
  docs.map((doc) => {
    const name = doc.getRoot().children[0].name()
    const urn = doc.myData.urn
    const lastModified = models.find((model) => model.urn === urn)?.lastModified
    const viewables = doc
      .getRoot()
      .search({ type: "geometry", role: role })
      .map((viewable) => ({ name: viewable.name(), nodes: [viewable] }))
    return { name, lastModified, viewables }
  })

const getViewables = (models: ModelData[], documents: Document[]): Viewables => {
  const master = getMasterViews(documents)
  const lastModifieds = models.map((model) => model.lastModified).sort((a, b) => (a < b ? 1 : a > b ? -1 : 0))
  const lastModified = lastModifieds.length > 0 ? lastModifieds[0] : undefined
  const main: [ViewableGroup] = [
    { name: "Aggregated Model", lastModified, viewables: [{ name: "Aggregated Model", nodes: master }] }
  ]

  const twod = getRoleViews(models, documents, "2d")
  const threed = getRoleViews(models, documents, "3d")
  return {
    main,
    twod,
    threed
  }
}

// #endregion viewable

// https://stackoverflow.com/questions/66895484/revit-shared-coordinates-to-forge-viewer
const getOffset = async (nodes: BubbleNode[]) => {
  if (!nodes.length) return new THREE.Vector3(0, 0, 0)
  const aecModelData = await Autodesk.Viewing.Document.getAecModelData(nodes[0])
  const tf = aecModelData && aecModelData.refPointTransformation
  const refPoint: { x: number; y: number; z: number } = tf ? { x: tf[9], y: tf[10], z: 0.0 } : { x: 0, y: 0, z: 0 }
  return new THREE.Vector3(refPoint.x, refPoint.y, refPoint.z)
}

// Manually set AEC data for LevelsExtension (see LevelsExtions.js).
//
// Try setting '*Raster Ebenen.rvt'. Otherwise set data of first (loaded) model.
const setAecModelDataForLevelsExtension = async (viewer: Viewer3D) => {
  viewer.getExtension("Autodesk.AEC.LevelsExtension", async (extensionM) => {
    const extension = extensionM as LevelsExtension
    const rasterModel = viewer.getAllModels().find((model) => getModelName(model).endsWith("Raster Ebenen.rvt"))
    const model = rasterModel ? rasterModel : viewer.getAllModels()[0]
    const bubbleNode = model && model.getDocumentNode()
    const aecModelData = bubbleNode && (await Autodesk.Viewing.Document.getAecModelData(bubbleNode))
    let isDataInWorldCoords = false
    // @ts-ignore
    extension.setAecModelData(aecModelData, model, isDataInWorldCoords)
  })
}

declare global {
  // eslint-disable-next-line no-var
  var externalIds: Map<number, { [key: string]: number }>
}

export default function Viewer(props: ViewerProps) {
  const [aggregatedView, setAggregatedView] = useState<AggregatedView | undefined>()
  const viewerDomRef = useRef<HTMLDivElement | null>(null)
  const [error, setError] = useState<{ name: string; message: string }>()

  const initializerOptions: InitializerOptions = {
    env: props.env,
    api: props.api || "derivativeV2", // for models uploaded to EMEA change this option to 'derivativeV2_EU'
    getAccessToken: async (onSuccess) => {
      if (onSuccess) {
        let token = await props.getToken()
        onSuccess(token.accessToken, token.expiresIn)
      }
    }
  }

  interface ExensionLoadedEvent {
    extensionId: string
  }

  const attachExtensionLoaded = (viewer: Viewer3D) => {
    const extensionLoaded = (event: ExensionLoadedEvent) => {
      if (event.extensionId === "Autodesk.AEC.LevelsExtension") {
        setAecModelDataForLevelsExtension(viewer)
      }

      if (event.extensionId === "Autodesk.PropertiesManager") {
        const propManager = viewer.getExtension("Autodesk.PropertiesManager")

        console.debug("Set custom PropertyPanel.")
        //@ts-ignore
        propManager.setPanel(new PropertyPanel(viewer))
      }
    }

    viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, extensionLoaded)
  }

  const initializeViewer = async () => {
    const htmlDiv = viewerDomRef.current
    if (htmlDiv === null) throw new Error("Viewer Div ref is null")
    await initializer(initializerOptions)
    const view = new Autodesk.Viewing.AggregatedView()

    // load views
    const urns = props.models.map((model) => `urn:${model.urn}`)
    const docs = await loadDocuments(urns)
    if (!docs.length) throw new Error("Failed to initialize viewer. No documents to load.")
    const viewables = getViewables(props.models, docs)

    // use master view
    const nodes = viewables.main[0].viewables[0].nodes

    // use shared coordinate system
    const offset = await getOffset(nodes)
    const viewInitOptions: AggregatedViewInitOptions = {
      // @ts-ignore
      extensionOptions: {
        "Autodesk.AEC.LevelsExtension": {
          autoDetectAecModelData: false
        }
      },
      getCustomLoadOptions: () => ({
        applyRefPoint: true,
        globalOffset: offset
      })
    }

    // init
    view.init(htmlDiv, viewInitOptions)
    attachExtensionLoaded(view.viewer)
    view.setNodes(nodes, null)
    await view.waitForLoadDone()

    globalThis.externalIds = new Map()
    for (const model of view.viewer.getAllModels()) {
      const mapping = await getExternalIdMappingAsync(model)
      globalThis.externalIds.set(model.id, mapping)
    }

    // extensions
    await loadBoxSelectionExtension(view.viewer)

    return { view, viewables }
  }

  const loadBoxSelectionExtension = async (viewer: Viewer3D) => {
    // box selection extension is loaded per default
    // we have to explicitly add the toolbar button
    // @ts-ignore
    viewer.getExtension("Autodesk.BoxSelection", (ext) => ext.addToolbarButton(true))
  }

  useEffect(() => {
    initializeViewer()
      .then(({ view, viewables }) => {
        props.onSetAggregatedView(view)
        props.onSetViewables(viewables)
        setAggregatedView(view)
      })
      .catch((error) => {
        console.error(error)
        setError({ name: error.name, message: error.message })
      })

    return function cleanUp() {
      if (aggregatedView) {
        aggregatedView.viewer.finish()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // register selection event
  useSelectionEvent(aggregatedView?.viewer)

  return (
    <Paper className="viewer-app">
      {error ? (
        <ErrorInfo message={error.name + ": " + error.message} />
      ) : (
        <div className="viewer" ref={viewerDomRef} />
      )}
    </Paper>
  )
}

Viewer.displayName = "Viewer"

import useSWR from "swr"
import { useFetchWithTokenSWR } from "../Auth/Msal"
import { ParameterDeclaration } from "../Dashboard/ManageParametersTab/ParameterList"
import { SWRData } from "../utils/swr"

/** Returns list of parameter declarations. */
const useParameterDeclarations = (projectId: string): SWRData<ParameterDeclaration[]> => {
  const fetcher = useFetchWithTokenSWR()
  const { data, error } = useSWR<ParameterDeclaration[]>(
    `/api/twin/viewer/projects/${projectId}/parameterdeclarations`,
    fetcher
  )
  return {
    data: data ?? [],
    loading: !error && !data,
    error
  }
}

export default useParameterDeclarations

import { Markup } from "./BCF"
import { IssueData } from "./IssuesList"
import IssueDialog, { IssueDTO } from "./IssueDialog"

interface UpdateDialogProps {
  open: boolean
  projectId: string
  issues: IssueData[]
  onCancel: () => void
  onSubmit: (issue: IssueData) => void
  issueData: IssueData
}

const UpdateDialog = (props: UpdateDialogProps) => {
  const markup = props.issueData.markup
  const initial: IssueDTO = {
    title: markup.title,
    description: markup.description,
    status: markup.status,
    kind: markup.kind,
    priority: markup.priority,
    assignedTo: markup.assignedTo,
    dueDate: markup.dueDate
  }

  const onSubmit = (issueData: IssueDTO) => {
    let newMarkup: Markup = { ...props.issueData.markup, ...issueData }

    let newIssueData = { ...props.issueData, markup: newMarkup }

    props.onSubmit(newIssueData)
  }

  return (
    <IssueDialog
      initial={initial}
      issues={props.issues}
      onCancel={props.onCancel}
      onSubmit={onSubmit}
      open={props.open}
      projectId={props.projectId}
    />
  )
}

export default UpdateDialog

// Dump Attachments for Revit Import
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from "@mui/material"
import { useState } from "react"
import { Check as CheckIcon, Download as DownloadIcon } from "@mui/icons-material"
import { ErrorInfo, LoadingInfo } from "../../Info"
import { useFetchWithToken } from "../../Auth/Msal"
import { Viewer3D } from "../../Viewer/Viewer"
import { fetchParametersWith } from "../ManageParametersTab/ParameterList"
import { QueryOptions, runFiltersWithAzureDocument } from "../FilterTab/query"
import {
  AzureDocument,
  fetchAzureDocumentsExpandedWith,
  fetchAzureDocumentsForProject
} from "../../useHooks/useDocuments"
import { getDocumentId, getLinkedModels, getModelName } from "../../Viewer/utils"
import { Filter, useFilters } from "../../useHooks/useFilters"

interface DumpDialogProps {
  open: boolean
  viewer: Viewer3D
  projectId: string
  onCancel: () => void
  onSubmit: () => void
}

export const fetchAttachments = async (
  viewer: Viewer3D,
  projectId: string,
  filters: Filter[],
  fetchWithToken: (input: RequestInfo) => Promise<Response>,
  options?: QueryOptions
) => {
  const fetchParameters = (name: string) => fetchParametersWith(fetchWithToken, projectId, name)

  const fetchAzureDocuments = (filterId: string) => fetchAzureDocumentsExpandedWith(fetchWithToken, projectId, filterId)

  const fromDoc = (document: AzureDocument) => ({
    id: document.id,
    name: document.name,
    url: document.url
  })

  const results = await runFiltersWithAzureDocument(fetchParameters, fetchAzureDocuments, viewer, filters, options)
  const filterAttachments = results.map((result) => {
    let elements = result.selections.map((selection) => ({
      documentId: selection.documentId,
      uniqueIds: selection.externalIds
    }))
    let documents = result.documents.map(fromDoc)
    return { elements, documents }
  })

  const elementAttachments = (await fetchAzureDocumentsForProject(fetchWithToken, projectId)).map((entry) => {
    const [documentId, uniqueId] = entry.elementId.split("+", 2)
    return {
      elements: [{ documentId: documentId, uniqueIds: [uniqueId] }],
      documents: entry.documents.map(fromDoc)
    }
  })
  const attachments = [...filterAttachments, ...elementAttachments]
  return attachments
}

const DumpRevitAttachmentsDialog = (props: DumpDialogProps) => {
  const { data: filters, loading, error } = useFilters(props.projectId)
  const fetchWithToken = useFetchWithToken()

  const [data, setData] = useState<undefined | "preparing" | "error" | Blob>(undefined)

  const handleCancel = () => props.onCancel()

  const makeDialog = (diagProps: { content: JSX.Element; customActions?: JSX.Element }) => {
    return (
      <Dialog fullWidth open={props.open} keepMounted={false}>
        <DialogTitle>Export Attachments for Revit</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>{diagProps.content}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          {diagProps.customActions}
        </DialogActions>
      </Dialog>
    )
  }

  if (data === "preparing" || loading)
    return makeDialog({
      content: <LoadingInfo message="Preparing data for download. This may take some time." />
    })

  if (data === "error" || error)
    return makeDialog({
      content: <ErrorInfo message="Failed to prepare data." />
    })

  if (data) {
    const handleDownload = () => {
      const dataURL = window.URL.createObjectURL(data)

      const tempLink = document.createElement("a")
      tempLink.href = dataURL
      tempLink.setAttribute("data", "attachments.json")
      tempLink.download = "attachments.json"
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)

      props.onSubmit()
    }
    return makeDialog({
      content: (
        <>
          <CheckIcon />
          <Typography variant="body1">Data ready.</Typography>
        </>
      ),
      customActions: (
        <Button startIcon={<DownloadIcon />} onClick={handleDownload}>
          Download
        </Button>
      )
    })
  }

  const handleSubmit = async () => {
    setData("preparing")
    try {
      const models = await Promise.all(
        props.viewer.getAllModels().map(async (model) => {
          let documentId = getDocumentId(model)
          let name = getModelName(model)
          let linkedModels = await getLinkedModels(model)
          return { ...documentId, name, linkedModels }
        })
      )
      const attachments = await fetchAttachments(props.viewer, props.projectId, filters, fetchWithToken)

      const data = { models, attachments }

      const blob = new Blob([JSON.stringify(data)], { type: "application/json;charset=utf-8;" })
      setData(blob)
    } catch (err) {
      console.error("Failed to preapare attachment data for download", err)
      setData("error")
    }
  }

  return makeDialog({
    content: (
      <>
        Download the attachments table for Revit import. The export depends on the loaded models. To export all data,
        select the 'main' model.
      </>
    ),
    customActions: <Button onClick={handleSubmit}>Submit</Button>
  })
}

export default DumpRevitAttachmentsDialog

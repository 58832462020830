import { ColumnDefinition, ContentTypeInfo } from "@microsoft/microsoft-graph-types"
import { SharepointData } from "../../useHooks/useDocuments"
import { ContentTypes } from "./ContentTypes"

interface ContentTypesFilterProps {
  azureDocuments: SharepointData[]
  activeContentTypes: string[]
  contentTypes: ContentTypeInfo[]
  allColumns: ColumnDefinition[]
  onContentTypeChanged: (filteredColumns: ColumnDefinition[], newContentType: string[]) => void
}

export const ContentTypesFilter = (props: ContentTypesFilterProps) => {
  const activeContentTypes = [...props.activeContentTypes]

  const onContentTypesClickHandler = (index: string | undefined) => {
    const tmpColumns: ColumnDefinition[] = []
    if (index)
      activeContentTypes.indexOf(index) === -1
        ? activeContentTypes.push(index)
        : activeContentTypes.splice(activeContentTypes.indexOf(index), 1)

    activeContentTypes.forEach((cType) =>
      props.allColumns.forEach((column) => {
        if (column.sourceContentType?.name === cType) tmpColumns.push(column)
      })
    )

    var filteredColumns: ColumnDefinition[] = []
    //if no content type is selected we show again all sorted columns
    if (activeContentTypes.length === 0)
      filteredColumns = props.allColumns.sort((a, b) => a.displayName!.localeCompare(b.displayName!))
    else filteredColumns = tmpColumns.sort((a, b) => a.displayName!.localeCompare(b.displayName!))

    props.onContentTypeChanged(filteredColumns, activeContentTypes)
  }

  return (
    <>
      <ContentTypes
        contentTypes={props.contentTypes}
        activeChipsIndex={props.activeContentTypes}
        onContentTypesClickedHandler={onContentTypesClickHandler}
      />
    </>
  )
}

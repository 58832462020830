/** Dashboard 'Filter' component.
 * Run filter and select elements.
 */
import { Paper, Typography, useTheme } from "@mui/material"
import { useState } from "react"
import "react-querybuilder/dist/query-builder.scss"
import "./query-builder-overrides.css"

// MUI component imports
import FilterList from "./FilterList"
import DocumentList from "./DocumentList"
import FilterSelected from "./FilterSelected"
import { ErrorInfo, LoadingInfo } from "../../Info"
import { Filter, useFilters } from "../../useHooks/useFilters"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

interface FilterTabProps {
  viewer: Viewer3D | undefined
  projectId: string
}

const FilterTab = (props: FilterTabProps) => {
  const projectId = props.projectId
  const theme = useTheme()
  const spacing = theme.spacing(1)

  const [filter, setFilter] = useState<Filter | undefined>()
  const handleSelectFilter = (filter: Filter | undefined) => setFilter(filter)

  const { data: filters, loading, error } = useFilters(props.projectId)

  if (loading) return <LoadingInfo message="Loading filters." />

  if (error) return <ErrorInfo message="Failed to load filter." />

  return (
    <>
      <Paper style={{ margin: spacing, padding: spacing }}>
        <Typography variant="h5">Filter List</Typography>
        <FilterList projectId={projectId} filters={filters} onClick={handleSelectFilter} />
      </Paper>
      <Paper style={{ margin: spacing, padding: spacing }}>
        <Typography variant="h5">Selected Filter</Typography>
        <FilterSelected projectId={projectId} viewer={props.viewer} filters={filters} filter={filter} />
      </Paper>
      <Paper style={{ margin: spacing, padding: spacing }}>
        <Typography variant="h5">Filter Documents</Typography>
        {filter && <DocumentList projectId={projectId} filter={filter} />}
      </Paper>
    </>
  )
}

export default FilterTab

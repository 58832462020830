/**
 */
import { Paper, Typography, useTheme } from "@mui/material"
import { useState } from "react"
import {
  Add as AddIcon,
  Download as DownloadIcon,
  Upload as UploadIcon,
  History as HistoryIcon,
  Business as BusinessIcon
} from "@mui/icons-material"

import useUser from "../../useHooks/useUser"

import ActionButton from "../ActionButton"
import ExportDialog from "./ExportDialog"
import ImportDialog from "./ImportDialog"
import AddTemplateDialog from "./AddTemplateDialog"
import Templates, { TemplateDeclaration } from "./Templates"
import { HistoryDialog } from "../HistoryDialog"
import DumpRevitParametersDialog from "./DumpRevitParametersDialog"
import DumpRevitAttachmentsDialog from "./DumpRevitAttachmentsDialog"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

interface ImportExportProps {
  projectId: string
  viewer: Viewer3D
}

const ImportExportTab = (props: ImportExportProps) => {
  const theme = useTheme()
  const spacing = theme.spacing(1)
  const projectId = props.projectId

  const [exportDialog, setExportDialog] = useState<boolean>(false)
  const [importDialog, setImportDialog] = useState<boolean>(false)
  const [dumpRevitParametersDialog, setDumpRevitParametersDialog] = useState<boolean>(false)
  const [dumpRevitAttachmentsDialog, setDumpRevitAttachmentsDialog] = useState<boolean>(false)
  const [addTemplateDialog, setAddTemplateDialog] = useState<boolean>(false)

  const [selectedTemplate, setSelectedTemplate] = useState<TemplateDeclaration | undefined>()

  const handleExportButton = () => {
    setExportDialog(true)
  }

  const handleImportButton = () => {
    setImportDialog(true)
  }

  const handleDumpRevitParametersButton = () => {
    setDumpRevitParametersDialog(true)
  }

  const handleDumpRevitAttachmentsButton = () => {
    setDumpRevitAttachmentsDialog(true)
  }

  const handleAddTemplateButton = () => {
    setAddTemplateDialog(true)
  }

  // history
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false)

  // security
  const { isUser, isRead } = useUser(projectId)

  return (
    <>
      <ActionButton startIcon={<DownloadIcon />} onClick={handleExportButton} disabled={!isUser}>
        Export
      </ActionButton>
      <ActionButton startIcon={<UploadIcon />} onClick={handleImportButton} disabled={!isUser}>
        Import
      </ActionButton>
      <ActionButton startIcon={<BusinessIcon />} onClick={handleDumpRevitParametersButton} disabled={!isRead}>
        Revit Parameters
      </ActionButton>
      <ActionButton startIcon={<BusinessIcon />} onClick={handleDumpRevitAttachmentsButton} disabled={!isRead}>
        Revit Attachments
      </ActionButton>
      <ActionButton startIcon={<HistoryIcon />} onClick={() => setOpenHistoryDialog(true)} disabled={!isRead}>
        History
      </ActionButton>
      <Paper style={{ margin: spacing }}>
        <Typography variant="h5" sx={{ color: "primary.text" }}>
          Templates
        </Typography>
        <ActionButton startIcon={<AddIcon />} onClick={handleAddTemplateButton} disabled={!isUser}>
          New
        </ActionButton>
        <Templates projectId={props.projectId} onSelect={setSelectedTemplate} />
      </Paper>
      {isUser && exportDialog && (
        <ExportDialog
          open={exportDialog}
          viewer={props.viewer}
          projectId={projectId}
          template={selectedTemplate}
          onCancel={() => setExportDialog(false)}
          onSubmit={() => setExportDialog(false)}
        />
      )}
      {isUser && dumpRevitParametersDialog && (
        <DumpRevitParametersDialog
          open={dumpRevitParametersDialog}
          viewer={props.viewer}
          projectId={projectId}
          onCancel={() => setDumpRevitParametersDialog(false)}
          onSubmit={() => setDumpRevitParametersDialog(false)}
        />
      )}
      {isUser && dumpRevitAttachmentsDialog && (
        <DumpRevitAttachmentsDialog
          open={dumpRevitAttachmentsDialog}
          viewer={props.viewer}
          projectId={projectId}
          onCancel={() => setDumpRevitAttachmentsDialog(false)}
          onSubmit={() => setDumpRevitAttachmentsDialog(false)}
        />
      )}
      {isUser && importDialog && (
        <ImportDialog
          open={importDialog}
          projectId={projectId}
          onCancel={() => setImportDialog(false)}
          onSubmit={() => setImportDialog(false)}
        />
      )}
      {isUser && addTemplateDialog && (
        <AddTemplateDialog
          open={addTemplateDialog}
          projectId={projectId}
          viewer={props.viewer}
          onCancel={() => setAddTemplateDialog(false)}
          onSubmit={() => setAddTemplateDialog(false)}
        />
      )}
      {isRead && openHistoryDialog && (
        <HistoryDialog
          open={openHistoryDialog}
          projectId={projectId}
          elementId={"import_parameters"}
          onClose={() => setOpenHistoryDialog(false)}
        />
      )}
    </>
  )
}

export default ImportExportTab

import { KeyedMutator } from "swr"

export interface SWRData<T> {
  data: T
  error: SWRError | null
  loading: boolean
  mutate?: KeyedMutator<T>
}

// SWR wrapper
export class SWRError extends Error {
  status: number
  info: string
  response: unknown

  constructor(message: string, status: number, info?: string, response?: unknown) {
    super(message)
    this.name = "SWRError"
    this.status = status
    this.info = info ?? ""
    this.response = response
  }

  public toString = (): string => `${this.message} (${this.status})`
}

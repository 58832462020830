import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Stack,
  TextareaAutosize,
  TextField,
  useTheme
} from "@mui/material"
import { useState } from "react"
import { TextFieldBootedControlled } from "../../utils/bootstrap"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import dayjs, { Dayjs } from "dayjs"
import { distinctOptions, IssueData } from "./IssuesList"

// @ts-ignore
const DatePicker = (props: { date; onSetDate }) => {
  // @ts-ignore
  const textField = (params) => (
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input">
        Date
      </InputLabel>
      <TextField id="date-picker" {...params} label="" style={{ marginTop: 24 }} size="small" />
    </FormControl>
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label="Date desktop"
        inputFormat="YYYY-MM-DD"
        value={props.date}
        onChange={props.onSetDate}
        renderInput={textField}
      />
    </LocalizationProvider>
  )
}

const AutocompleteComponent = (props: {
  idSuffix: string
  label: string
  options: string[]
  value: string | null
  setValue: (newValue: string | null) => void
}) => {
  const theme = useTheme()
  return (
    <FormControl variant="standard">
      <InputLabel className="label" shrink htmlFor="bootstrap-input">
        {props.label}
      </InputLabel>
      <Autocomplete
        id={`issue-dialog-${props.idSuffix}`}
        options={props.options}
        sx={{
          marginTop: theme.spacing(3),
          size: "small",
          backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b"
        }}
        freeSolo
        value={props.value}
        onInputChange={(_, newValues) => {
          props.setValue(newValues)
        }}
        disablePortal
        renderInput={(params) => <TextField {...params} size="small" />}
      />
    </FormControl>
  )
}

export interface IssueDTO {
  title: string
  description?: string | null
  status?: string | null
  kind?: string | null
  priority?: string | null
  assignedTo?: string | null
  dueDate?: string | null
}

interface IssueDialogProps {
  open: boolean
  projectId: string
  issues: IssueData[]
  onCancel: () => void
  onSubmit: (issue: IssueDTO) => void
  initial: IssueDTO
}

const IssueDialog = (props: IssueDialogProps) => {
  const theme = useTheme()

  const initial = props.initial

  const [title, setTitle] = useState<string>(initial.title)
  const [description, setDescription] = useState<string>(initial.description ?? "")
  const [status, setStatus] = useState<string | null>(initial.status ?? null)
  const [kind, setKind] = useState<string | null>(initial.kind ?? null)
  const [priority, setPriority] = useState<string | null>(initial.priority ?? null)
  const [date, setDate] = useState<Dayjs | null>(initial.dueDate ? dayjs(initial.dueDate) : null)
  const handleSetDate = (newDate: Dayjs | null) => setDate(newDate)
  const [assignedTo, setAssignedTo] = useState<string | null>(initial.assignedTo ?? null)

  const handleClose = () => {
    props.onCancel()
  }

  const handleSubmit = () => {
    if (!title) return undefined
    let dueDate = date?.format()
    props.onSubmit({ title, description, status, kind, priority, assignedTo, dueDate })
  }

  const assignedOptions = distinctOptions(props.issues.map((issue) => issue.markup.assignedTo))

  return (
    <Dialog open={props.open} keepMounted={false}>
      <DialogTitle>New Issue</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <TextFieldBootedControlled
            id={"issue-title"}
            value={title}
            setValue={setTitle}
            label={"Title"}
            required={true}
          />
          {/* type */}
          <AutocompleteComponent
            idSuffix="kind"
            label="Type"
            options={["Clash", "Fault", "Issue", "Request", "Remark"]}
            setValue={setKind}
            value={kind}
          />
          {/* priority */}
          <AutocompleteComponent
            idSuffix="priority"
            label="Priority"
            options={["Critical", "Major", "Normal", "Minor", "On Hold"]}
            setValue={setPriority}
            value={priority}
          />
          {/* description */}
          <FormControl variant="standard">
            <InputLabel shrink>Description</InputLabel>
            <TextareaAutosize
              id={"issue-description"}
              minRows={8}
              onChange={(ev) => setDescription(ev.target.value)}
              style={{ marginTop: theme.spacing(3) }}
              value={description}
            />
          </FormControl>
          {/* due-date */}
          <DatePicker date={date} onSetDate={handleSetDate} />
          {/* assigned to */}
          <AutocompleteComponent
            idSuffix="assigned-to"
            label="Assigned To"
            options={assignedOptions}
            setValue={setAssignedTo}
            value={assignedTo}
          />
          {/* // status */}
          <AutocompleteComponent
            idSuffix="status"
            label="Status"
            options={["Open", "Pending", "In Review", "Closed"]}
            setValue={setStatus}
            value={status}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!title}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default IssueDialog

/** Fetch properties / parameter instances. */
import { useEffect, useState } from "react"
import useSWR from "swr"
import { useFetchWithTokenSWR } from "../Auth/Msal"
import { ParameterInstance } from "../Dashboard/AssignParametersTab/AssignParameters"
import { SWRData } from "../utils/swr"
import { Selections, toParameterSelection } from "../Viewer/utils"

/// Loading is false if nothing is selected, otherwise leads to permanent loading icon.
export const useExternalProperties = (
  viewer: Autodesk.Viewing.Viewer3D,
  projectId: string,
  selections: Selections
): SWRData<ParameterInstance[]> => {
  const fetcher = useFetchWithTokenSWR()

  const [elementId, setElementId] = useState<string | undefined>()

  useEffect(() => {
    toParameterSelection(viewer, selections).then((parameterSelection) => {
      const elementId = parameterSelection?.length === 1 ? parameterSelection[0] : undefined
      setElementId(elementId)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections])

  const { data, error } = useSWR<ParameterInstance[]>(
    elementId
      ? `/api/twin/viewer/projects/${projectId}/parametersWithElementId/${encodeURIComponent(elementId)}`
      : null,
    fetcher
  )

  return {
    data: data ?? [],
    loading: !error && data === undefined && elementId !== undefined,
    error
  }
}

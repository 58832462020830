import { Typography, Grid, Chip, styled, Box, Autocomplete, TextField, AutocompleteChangeDetails } from "@mui/material"
import { Field, FieldValue } from "../../useHooks/useDocuments"
import { ColumnDefinition } from "@microsoft/microsoft-graph-types"
import { useState } from "react"

export enum SearchOrChipSelect {
  Search,
  Chip
}

interface MetaDataColumnsProps {
  column: ColumnDefinition | undefined
  title: string
  fields: (Field | undefined)[]
  activeChipsIndex: string[]
  onMetaDataChangedHandler: (
    fields: (Field | undefined)[],
    searchOrChipSelect: SearchOrChipSelect,
    chipIndex: string | undefined,
    removeOption: AutocompleteChangeDetails<Field | undefined> | undefined
  ) => void
}

//generate spacing between chips
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.25)
}))

const getFieldLabel = (field: Field | undefined): string | undefined => {
  return getFieldValue(field)?.Label
}

const getFieldValue = (field: Field | undefined): FieldValue | undefined => {
  if (!field) return undefined
  try {
    const obj: FieldValue = JSON.parse(field.value.toString())
    return obj
  } catch (error) {
    return {
      Label: field.value.toString(),
      TermGuid: field.value.toString() + "5e536ba6-4a69-4018-9466-12940582ff8e",
      WssId: 0
    }
  }
}

const getLabelNumbers = (fields: (Field | undefined)[], label: string | undefined) => {
  const filteredFields = fields.filter((field) => getFieldLabel(field) === label)
  return filteredFields.length
}

const getLabelWithNumber = (field: Field | undefined, fields: (Field | undefined)[]) => {
  return getFieldLabel(field) + "(" + getLabelNumbers(fields, getFieldLabel(field)) + ")"
}

export const MetaDataColumns = (props: MetaDataColumnsProps) => {
  const [autoCompleteValue, setAutoCompleteValue] = useState<(Field | undefined)[]>([])
  const fieldsWithoutDuplicates = props.fields.filter((v, i, a) => a.findIndex((v2) => v2?.value === v?.value) === i)

  //options for search-field
  const options = {
    options: fieldsWithoutDuplicates,
    getOptionLabel: (option: Field | undefined) => getFieldLabel(option)!
  }
  return (
    <>
      {props.column?.text === null ? (
        <Grid container spacing={2} height="Auto" alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2">{props.title}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.25,
                m: 0
              }}
              component="ul"
            >
              {fieldsWithoutDuplicates!.map((field, index) => {
                const label = getLabelWithNumber(field, props.fields)
                const fieldValue = getFieldValue(field)
                return (
                  <ListItem key={index}>
                    <Chip
                      size={"small"}
                      color={props.activeChipsIndex.indexOf(fieldValue?.TermGuid!) === -1 ? "primary" : "secondary"}
                      clickable
                      onClick={() => {
                        props.onMetaDataChangedHandler(
                          [field!],
                          SearchOrChipSelect.Chip,
                          fieldValue?.TermGuid,
                          undefined
                        )
                      }}
                      label={label}
                    />
                  </ListItem>
                )
              })}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} height="Auto" alignItems="center">
          <Grid item xs={3}>
            <Typography variant="body2">{props.title}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Autocomplete
              limitTags={5}
              id="free textfields"
              multiple
              size={"small"}
              ChipProps={{
                color: "secondary"
              }}
              {...options}
              autoSelect
              value={autoCompleteValue || null}
              onChange={(event, values, situation, option) => {
                setAutoCompleteValue(values)
                if (situation === "removeOption") {
                  props.onMetaDataChangedHandler(values, SearchOrChipSelect.Search, undefined, option)
                } else {
                  props.onMetaDataChangedHandler(values, SearchOrChipSelect.Search, undefined, undefined)
                }
              }}
              renderOption={(renderProps, option) => {
                return <li {...renderProps}>{getLabelWithNumber(option, props.fields)}</li>
              }}
              renderInput={(params) => (
                <TextField {...params} label="Search..." variant="standard" placeholder="MetaData" />
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

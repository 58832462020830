/** Dashboard side panel with tabs. */
import { Box, Tabs, Tab } from "@mui/material"
import { useState } from "react"
import { DocumentsTab } from "./Documents/DocumentsTab"
import FilterTab from "./FilterTab/FilterTab"
import useUser from "../useHooks/useUser"
import ManageParametersTab from "./ManageParametersTab/ManageParametersTab"
import AssignParametersTab from "./AssignParametersTab/AssignParameters"
import ImportExportTab from "./ImportExportTab/ImportExport"
import React from "react"
import IssuesTab from "./IssuesTab/IssuesTab"
import { AggregatedView, Viewables } from "../Viewer/Viewer"
import { LoadingInfo } from "../Info"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  height: string
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, height, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "90%", maxHeight: "90%" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, height: { height }, overflow: "auto" }}>{children}</Box>}
    </div>
  )
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}

interface DashboardProps {
  aggregatedView: AggregatedView | undefined
  projectId: string
  viewables: Viewables | undefined
}

// Controlling the overflow is a bit of hassle.
// Sometimes a div with heigh:100%  takes too much horizontal space. Maybe related to the appbar (and tabpar).
// Then just hardcode height with eg calc(100vh - 100).
const Dashboard = (props: DashboardProps) => {
  // tab index
  const [tab, setTab] = useState(0)
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => setTab(newValue)

  // user permission
  const { isRead, isSuperUser, isUser } = useUser(props.projectId)

  if (!props.aggregatedView?.viewer || !props.viewables) {
    return (
      <Box sx={{ width: "100%", padding: 0, margin: 0, maxHeight: "100%", height: "100%" }}>
        <LoadingInfo message={"Wait for Forge Viewer"} />
      </Box>
    )
  }
  return (
    <Box sx={{ width: "100%", padding: 0, margin: 0, maxHeight: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0, margin: 0 }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="dashboard-navigation"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Filter" {...a11yProps(0)} disabled={!isRead} />
          <Tab label="Documents" {...a11yProps(1)} disabled={!isRead} />
          <Tab label="Manage Parameters" {...a11yProps(2)} disabled={!isSuperUser} />
          <Tab label="Assign Parameter&Documents" {...a11yProps(3)} disabled={!isUser} />
          <Tab label="Import/Export" {...a11yProps(4)} disabled={!isUser} />
          <Tab label="Issues" {...a11yProps(5)} disabled={!isUser} />
        </Tabs>
      </Box>
      {props.aggregatedView?.viewer ? (
        <>
          <TabPanel value={tab} index={0} height={"calc(100vh - 100px)"}>
            <FilterTab viewer={props.aggregatedView.viewer} projectId={props.projectId} />
          </TabPanel>
          <TabPanel value={tab} index={1} height={"calc(100vh - 100px)"}>
            <DocumentsTab projectId={props.projectId} />
          </TabPanel>
          <TabPanel value={tab} index={2} height={"calc(100vh - 100px)"}>
            <ManageParametersTab viewer={props.aggregatedView.viewer} projectId={props.projectId} />
          </TabPanel>
          <TabPanel value={tab} index={3} height={"calc(100vh - 100px)"}>
            <AssignParametersTab viewer={props.aggregatedView.viewer} projectId={props.projectId} />
          </TabPanel>
          <TabPanel value={tab} index={4} height={"calc(100vh - 100px)"}>
            <ImportExportTab viewer={props.aggregatedView.viewer} projectId={props.projectId} />
          </TabPanel>
          <TabPanel value={tab} index={5} height={"calc(100vh - 100px)"}>
            <IssuesTab aggregatedView={props.aggregatedView} projectId={props.projectId} viewables={props.viewables} />
          </TabPanel>
        </>
      ) : (
        <LoadingInfo message={"Waiting for Viewer..."} />
      )}
    </Box>
  )
}

export default Dashboard

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableRow,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableBody,
  Typography,
  CircularProgress
} from "@mui/material"
import useSWR from "swr"
import { useFetchWithTokenSWR } from "../Auth/Msal"
import { SWRData } from "../utils/swr"
import { Error as ErrorIcon } from "@mui/icons-material"

export interface AddLog {
  projectId: string
  elementId: string
  message: string
  logType: string
}

/** Send a POST request to add a log entry. */
export const addLog = (
  fetchWithToken: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>,
  addLog: AddLog
) => {
  const url = `/api/twin/viewer/projects/${addLog.projectId}/logs/${encodeURIComponent(addLog.elementId)}`

  fetchWithToken(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(addLog)
  })
}

export interface HistoryDialogProps<T> {
  projectId: string
  elementId: string
  open: T
  onClose: () => void
}

interface LogEntry {
  projectIdAndElementId: string
  timestamp: string
  userId: string
  userName: string
  logMessage: string
  logType: string
}

const useLogs = (projectId: string, elementId: string): SWRData<LogEntry[]> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error } = useSWR<LogEntry[]>(
    `/api/twin/viewer/projects/${projectId}/logs/${encodeURIComponent(elementId)}`,
    fetcher
  )

  return {
    data: data!,
    loading: !error && !data,
    error
  }
}

export const HistoryDialog = <T,>(props: HistoryDialogProps<T>) => {
  const { data: logs, loading, error } = useLogs(props.projectId, props.elementId)
  if (loading) return <CircularProgress />
  if (error) return <ErrorIcon />

  return (
    <Dialog open={props.open !== undefined} maxWidth={false}>
      <DialogTitle>History</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {["Time", "UserId", "User", "Event"].map((text) => (
                  <TableCell key={text}>
                    <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((entry) => (
                <TableRow key={entry.timestamp}>
                  <TableCell>
                    <Typography>{entry.timestamp}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{entry.userId}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{entry.userName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{entry.logMessage}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

import dayjs from "dayjs"
import { Markup } from "./BCF"
import { v4 as uuidv4 } from "uuid"
import { IssueData } from "./IssuesList"
import IssueDialog, { IssueDTO } from "./IssueDialog"

interface NewDialogProps {
  open: boolean
  projectId: string
  issues: IssueData[]
  onCancel: () => void
  onSubmit: (markup: Markup) => void
}

const NewDialog = (props: NewDialogProps) => {
  const initial: IssueDTO = {
    title: "",
    description: "",
    status: "Open",
    kind: "Issue",
    priority: "Normal",
    assignedTo: null,
    dueDate: null
  }

  const onSubmit = (issueData: IssueDTO) => {
    let markup: Markup = {
      guid: uuidv4(),
      creationDate: dayjs().format(),
      creationAuthor: "<author>", // overwrite in backend
      ...issueData
    }

    props.onSubmit(markup)
  }

  return (
    <IssueDialog
      initial={initial}
      issues={props.issues}
      onCancel={props.onCancel}
      onSubmit={onSubmit}
      open={props.open}
      projectId={props.projectId}
    />
  )
}

export default NewDialog

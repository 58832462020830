import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface Notification {
  status: "success" | "info" | "warning" | "error" | "closed"
  message?: string
}

const initialState: Notification = { status: "closed" }

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (_state, action: PayloadAction<Notification>) => action.payload
  }
})

export const { setNotification } = notificationSlice.actions

export const selectNotification = (state: RootState): Notification => state.notification

export default notificationSlice.reducer

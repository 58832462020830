/** List/Create document attachments for a selection of one or multiple elements.  */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableCell,
  Typography,
  TableRow
} from "@mui/material"
import { History as HistoryIcon, Tune } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../../app/hooks"
import { useFetchWithToken } from "../../Auth/Msal"
import { setNotification } from "../../features/notification/notificationSlice"
import Tabular from "../FilterTab/Tabular"
import { AttachFile, Remove } from "@mui/icons-material"
import { AzureDocument } from "../../useHooks/useDocuments"
import ActionButton from "../ActionButton"
import { AddDocumentDTO, AddDocumentDialog } from "../Documents/DocumentDialog"
import useUser from "../../useHooks/useUser"
import { Viewer3D } from "../../Viewer/Viewer"
import { ErrorInfo, Information, LoadingInfo } from "../../Info"
import useParameterSelection from "../../useHooks/useParameterSelection"
import { toLauncher } from "../FilterTab/DocumentList"
import { HistoryDialog } from "../HistoryDialog"

interface DeleteDocument {
  projectId: string
  id: string
}

interface DeleteDocumentDialogProps {
  open: DeleteDocument | undefined
  onCancel: () => void
  onSubmit: (document: DeleteDocument | undefined) => void
}

const DeleteDocumentDialog = (props: DeleteDocumentDialogProps) => {
  const document = props.open
  const handleCancel = () => props.onCancel()

  const handleSubmit = () => props.onSubmit(document)

  return (
    <Dialog open={props.open !== undefined}>
      <DialogTitle>Remove Document</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>Remove document.</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

interface ElementDocumentsTableProps {
  projectId: string
  viewer: Viewer3D
  selected: AzureDocument | undefined
  onSelect: (doc: AzureDocument | undefined) => void
}

const ElementDocumentsTable = (props: ElementDocumentsTableProps) => {
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  const elementIds = useParameterSelection(props.viewer)

  const [documents, setDocuments] = useState<AzureDocument[] | undefined | "loading" | "invalid">(undefined)

  const fetchDocs = () => {
    if (elementIds === undefined) {
      setDocuments(undefined)
    } else if (elementIds.length === 0) {
      setDocuments([])
    } else {
      const url = `/api/twin/viewer/projects/${props.projectId}/attachments/bulkAzureDocuments`

      fetchWithToken(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ elementIds: elementIds })
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setDocuments(json)
            })
          } else {
            setDocuments("invalid")
            dispatch(setNotification({ status: "error", message: "Failed to fetch parameters." }))
            console.error(response.statusText)
          }
        })
        .catch((_) => {
          dispatch(setNotification({ status: "error", message: "Failed to fetch parameters." }))
        })
    }
  }

  useEffect(() => {
    fetchDocs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementIds])

  useEffect(() => {
    if (props.selected !== selected) {
      setSelected(props.selected)
      fetchDocs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected])

  // selection
  const [selected, setSelected] = useState<AzureDocument | undefined>(props.selected)
  const handleSelect = (document: AzureDocument | undefined) => () => {
    setSelected(document)
    props.onSelect(document)
  }

  if (!elementIds || elementIds.length === 0)
    return <Information compact message="Please select one or multiple elements." />

  if (documents === "loading" || documents === undefined) return <LoadingInfo message={"Loading documents"} />
  if (documents === "invalid") return <ErrorInfo compact message="Failed to fetch documents from Sharepoint." />

  const toHeader = () =>
    ["Name"].map((text) => (
      <TableCell key={text} onClick={handleSelect(undefined)}>
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </TableCell>
    ))

  const toRow = (document: AzureDocument) => (
    <TableRow key={document.id} hover={true} selected={document.id === selected?.id} onClick={handleSelect(document)}>
      <TableCell>
        {toLauncher(document.url)}
        {document.name}
      </TableCell>
    </TableRow>
  )

  const toRows = () => documents.map(toRow)

  return (
    <>
      <Tabular toHeader={toHeader} toRows={toRows} />
    </>
  )
}

interface ElementDocumentsProps {
  viewer: Viewer3D
  projectId: string
}

const ElementDocuments = (props: ElementDocumentsProps) => {
  const projectId = props.projectId

  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  const elementIds = useParameterSelection(props.viewer)
  const [activated, setActivated] = useState<boolean>()

  // security
  const { isUser, isRead } = useUser(projectId)

  // history
  const [openSingleElementHistoryDialog, setOpenSingleElementHistoryDialog] = useState<string | undefined>()

  // This use-effect is only dependant on elementIds, so when we add or delete
  // documents we manually call fetchDocs().
  useEffect(() => {
    if (activated) setActivated(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementIds])

  // selection
  const [selected, setSelected] = useState<AzureDocument | undefined>()

  // add
  const [openAddDocumentDialog, setOpenAddDocumentDialog] = useState<boolean>(false)
  const handleOpenAddDocumentDialog = () => setOpenAddDocumentDialog(true)
  const handleCancelAddDocumentDialog = () => setOpenAddDocumentDialog(false)
  const handleSubmitAddDocumentDialog = (documentDTO: AddDocumentDTO | undefined) => {
    setOpenAddDocumentDialog(false)

    if (!documentDTO) {
      dispatch(setNotification({ status: "error", message: "Failed to add document." }))
      console.error("Trying to submit empty document")
      return
    }

    const document = { ...documentDTO, elementIds: elementIds }

    const url = `/api/twin/viewer/projects/${projectId}/attachments/bulkWriteDocuments`

    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(document)
    })
      .then((response) => {
        if (response.ok) {
          setSelected(undefined)
          dispatch(setNotification({ status: "success", message: "Document(s) added." }))
        } else {
          dispatch(setNotification({ status: "error", message: "Failed to add document." }))
        }
      })
      .catch((reason) => {
        dispatch(setNotification({ status: "error", message: "Failed to add document." }))
      })
  }

  // delete
  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] = useState<DeleteDocument | undefined>()
  const handleOpenDeleteDocumentDialog = () =>
    setOpenDeleteDocumentDialog(selected ? { projectId: projectId, id: selected.id } : undefined)
  const handleCloseDeleteDocumentDialog = () => setOpenDeleteDocumentDialog(undefined)

  const handleSubmitDeleteDocument = (document: DeleteDocument | undefined) => {
    setOpenDeleteDocumentDialog(undefined)
    if (!document || !elementIds) return

    const url = `/api/twin/viewer/projects/${projectId}/attachments/bulkDeleteDocuments`

    fetchWithToken(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ ...document, elementIds: elementIds })
    })
      .then((response) => {
        if (response.ok) {
          setSelected(undefined)
          dispatch(setNotification({ status: "success", message: "Document removed." }))
        } else {
          console.error("Failed to delete document.")
          dispatch(setNotification({ status: "error", message: "Failed to remove document." }))
        }
      })
      .catch((reason) => {
        console.error("Failed to delete document: ", reason)
        dispatch(setNotification({ status: "error", message: "Failed to remove document." }))
      })
  }

  if (!activated) {
    return (
      <>
        <Typography variant="h5" sx={{ color: "primary.text" }}>
          Attach Documents to Selection
        </Typography>
        <ActionButton startIcon={<Tune />} onClick={() => setActivated(true)}>
          Load Documents
        </ActionButton>
      </>
    )
  }

  const titleComponents = (
    <>
      <Typography variant="h5" sx={{ color: "primary.text" }}>
        Attach Documents to Selection
      </Typography>
      <ActionButton
        startIcon={<AttachFile />}
        onClick={handleOpenAddDocumentDialog}
        disabled={!isUser || !elementIds || elementIds.length === 0}
      >
        Attach
      </ActionButton>
      <ActionButton startIcon={<Remove />} onClick={handleOpenDeleteDocumentDialog} disabled={!selected || !isUser}>
        Remove
      </ActionButton>

      <ActionButton
        startIcon={<HistoryIcon />}
        onClick={() => setOpenSingleElementHistoryDialog(elementIds?.at(0))}
        disabled={elementIds === undefined || elementIds.length !== 1 || !isRead}
      >
        History
      </ActionButton>
    </>
  )

  if (!elementIds || elementIds.length === 0)
    return <Information before={titleComponents} compact message="Please select one or multiple elements." />

  return (
    <>
      {titleComponents}
      <ElementDocumentsTable projectId={projectId} viewer={props.viewer} onSelect={setSelected} selected={selected} />
      {openAddDocumentDialog && (
        <AddDocumentDialog
          projectId={projectId}
          open={openAddDocumentDialog}
          onCancel={handleCancelAddDocumentDialog}
          onSubmit={handleSubmitAddDocumentDialog}
        />
      )}
      {openDeleteDocumentDialog && (
        <DeleteDocumentDialog
          open={openDeleteDocumentDialog}
          onCancel={handleCloseDeleteDocumentDialog}
          onSubmit={handleSubmitDeleteDocument}
        />
      )}
      {openSingleElementHistoryDialog && (
        <HistoryDialog
          open={openSingleElementHistoryDialog}
          projectId={projectId}
          elementId={openSingleElementHistoryDialog}
          onClose={() => setOpenSingleElementHistoryDialog(undefined)}
        />
      )}
    </>
  )
}

export default ElementDocuments

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography, useTheme } from "@mui/material"
import { MuiFileInput } from "mui-file-input"
import { useState } from "react"

interface ImportDialogProps {
  open: boolean
  onCancel: () => void
  onSubmit: (file: File) => void
}

const ImportDialog = (props: ImportDialogProps) => {
  const spacing = useTheme().spacing(1)
  const [file, setFile] = useState<File | null>()

  const handleFileSelected = (newFile: File | null) => setFile(newFile)
  const handleSubmit = () => {
    if (!file) return
    props.onSubmit(file)
  }

  return (
    <Dialog open={props.open} keepMounted={false}>
      <DialogTitle>Import BCFZip</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Paper elevation={0} style={{ margin: spacing }}>
          <Typography variant="h5">Select BCFZip File</Typography>
          <MuiFileInput value={file} onChange={handleFileSelected} />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!file}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportDialog

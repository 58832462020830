import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import { mutate } from "swr"
import { useAppDispatch } from "../app/hooks"
import { useFetchWithToken } from "../Auth/Msal"
import { setNotification } from "../features/notification/notificationSlice"

export interface DeleteDialogProps<T> {
  open: T | undefined
  message: (t: T) => string
  url: (t: T) => string
  mutate?: boolean
  onCancel: () => void
  onSubmit: (t: T | undefined) => void
}

export const DeleteDialog = <T,>(props: DeleteDialogProps<T>) => {
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  const handleCancel = () => props.onCancel()

  const handleSubmit = () => {
    props.onSubmit(props.open)
    if (!props.open) return
    const elem = props.open
    console.debug("Delete element", elem)

    const url = props.url(elem)

    fetchWithToken(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(elem)
    })
      .then((response) => {
        if (response.status === 200) {
          props.mutate && mutate(url)
          dispatch(setNotification({ status: "success", message: "Element removed." }))
        } else {
          console.error("Failed to delete document:", response.statusText)
          dispatch(setNotification({ status: "error", message: "Failed to remove element. " + response.statusText }))
        }
      })
      .catch((reason) => {
        console.error("Failed to delete document: ", reason)
        dispatch(setNotification({ status: "error", message: "Failed to remove element." }))
      })
  }

  return (
    <Dialog open={props.open !== undefined}>
      <DialogTitle>Delete Element</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>{props.open ? props.message(props.open) : "undefined"}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

//Documents Tab is the same as Dialog, except that no multiselect is supported for files --> open file per Click in Browser

import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { ColumnDefinition, ContentTypeInfo, DriveItem } from "@microsoft/microsoft-graph-types"
import { CircularProgress, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Field, useAzureSharepointData } from "../../useHooks/useDocuments"
import useUser from "../../useHooks/useUser"
import { getTree, TreeNode } from "../../utils/pathListToTree"
import { ContentTypesFilter } from "./ContentTypesFilter"
import { DocumentsTabTree } from "./DocumentsTree"
import { MetaDataFilter } from "./MetaDataFilter"
interface DocumentsTabProps {
  projectId: string
}

export const DocumentsTab = (props: DocumentsTabProps) => {
  const { data: user } = useUser(props.projectId)
  const { data: azureDocuments, loading } = useAzureSharepointData(user?.azeProjectId)
  const { inProgress } = useMsal()

  const [filteredTree, setFilteredTree] = useState<TreeNode[]>()
  const [filteredColumns, setFilteredColumns] = useState<ColumnDefinition[]>([])
  const [allColumns, setAllColumns] = useState<ColumnDefinition[]>([])

  const [selectedMetaDataFields, setSelectedMetaDataFields] = useState<Field[]>([])
  const [activeChipsIndex, setActiveChipsIndex] = useState<string[]>([])

  const [activeContentTypes, setActiveContentTypes] = useState<string[]>([])

  const [contentTypes, setContentTypes] = useState<ContentTypeInfo[]>([])

  useEffect(() => {
    setFilteredTree(filteredTree)
  }, [filteredTree])

  //we generate the initialTree first, afterwards it will be generated new by selected metadata in the filter
  useEffect(() => {
    const initialTree = getTree(azureDocuments)
    setFilteredTree(initialTree)
  }, [azureDocuments])

  //we generate an initial contentTypes state; afterwards the state gets upadated by click
  useEffect(() => {
    if (azureDocuments) {
      const columns = azureDocuments.flatMap((document) => document.allColumns).flatMap((column) => column)
      //we don't support columns from default? contenttypes 'Element', 'System', 'Ordner', 'Dokument' and no hidden columns and filter duplicates
      const allColumns: ColumnDefinition[] = columns
        .filter(
          (column) =>
            column.sourceContentType!.name !== "Element" &&
            column.sourceContentType!.name !== "System" &&
            column.sourceContentType!.name !== "Ordner" &&
            column.sourceContentType!.name !== "Dokument" &&
            column.columnGroup !== "_Hidden"
        )
        .filter((value, index, self) => index === self.findIndex((column) => column.displayName === value.displayName))
        .sort((a, b) => a.displayName!.localeCompare(b.displayName!))

      //get distinct contenttypes from columndefinitions
      const contentTypes = allColumns
        .map((column) => column.sourceContentType!)
        .filter((value, index, self) => index === self.findIndex((cType) => cType.id === value.id))

      setAllColumns(allColumns)
      setFilteredColumns(allColumns)
      setContentTypes(contentTypes)
    }
  }, [azureDocuments])

  //get the (filtered) columns from the ContentTypeFilter
  //to not lose the states of selected content types when changing tabs, we save the states in the parent and change it in the childs
  const onContenTypeChanged = (columns: ColumnDefinition[], newContentTypes: string[]) => {
    setFilteredColumns(columns)
    setActiveContentTypes(newContentTypes)
  }

  //get the filtered tree from the meta data filter
  //to not lose the states of selected fields when changing tabs, we save the states in the parent and change it in the childs
  const onMetaDataChanged = (filteredTree: TreeNode[], newChipsIndex: string[], newSelectedMetaDataFields: Field[]) => {
    setFilteredTree(filteredTree)
    setSelectedMetaDataFields(newSelectedMetaDataFields)
    setActiveChipsIndex(newChipsIndex)
  }

  const onSelectedHandler = (nodeId: string, allItems: DriveItem[]) => {
    if (allItems) {
      const itemToOpen = allItems.find((item) => item.id === nodeId)
      if (itemToOpen?.folder === null) window.open(itemToOpen!.webUrl!)
    }
  }

  if (loading || inProgress === InteractionStatus.Login) {
    return (
      <>
        Loading Documents
        <CircularProgress size={12} />
      </>
    )
  } else if (azureDocuments) {
    const allItems = azureDocuments.flatMap((document) => document.allItems)

    return (
      <>
        <Paper style={{ margin: 10 }}>
          <Typography variant="h5">Open Sharepoint Documents in Browser</Typography>
          <Typography variant="body1">Filter Documents by Metadata</Typography>
          <br />
          <ContentTypesFilter
            azureDocuments={azureDocuments}
            activeContentTypes={activeContentTypes}
            contentTypes={contentTypes}
            allColumns={allColumns}
            onContentTypeChanged={onContenTypeChanged}
          />
          <br />
          <MetaDataFilter
            azureDocuments={azureDocuments}
            filteredColumns={filteredColumns}
            activeChipsIndex={activeChipsIndex}
            selectedMetaDataFields={selectedMetaDataFields}
            onMetaDataChanged={onMetaDataChanged}
          />
          <br />
          <DocumentsTabTree tree={filteredTree} items={allItems} onSelectedHandler={onSelectedHandler} />
        </Paper>
      </>
    )
  } else {
    return (
      <>
        Loading Documents
        <CircularProgress size={12} />
      </>
    )
  }
}

// Support for BCFCamera
import { getBulkProperties, getExternalIdMapping, select, Selections, tryFindModel } from "../../Viewer/utils"
import { AggregatedView, Model, Viewer3D } from "../../Viewer/Viewer"

export interface Markup {
  guid: string
  title: string
  creationDate: string
  creationAuthor: string
  kind?: string | null
  status?: string | null
  priority?: string | null
  modifiedDate?: string | null
  modifiedAuthor?: string | null
  dueDate?: string | null
  assignedTo?: string | null
  description?: string | null
}

// base64 encoded
export type Snapshot = string

export interface Viewpoint {
  guid: string
  camera: BCFCamera | null
  selections: string[]
}

export interface BCFIssue {
  markup: Markup
  snapshot: Snapshot
  viewpoint: Viewpoint
}

// #region camera
interface Point {
  x: number
  y: number
  z: number
}

export interface BCFCamera {
  viewPoint: Point
  direction: Point
  upVector: Point
  fov?: number // perspective
  scale?: number // orthogonal
}

// unit of BCF issues
const bcfUnit = "m"

// set BCF camera
// see also https://stackoverflow.com/questions/54869671/how-to-initialize-autodesk-forge-3d-viewer-correctly-from-bim-bcf-topic-viewpoin

/** Apply a BCFCamera. */
export const setBCFCamera = (aggregatedView: AggregatedView, camera: BCFCamera) => {
  const viewer = aggregatedView.viewer
  let p = camera.viewPoint
  let d = camera.direction
  let u = camera.upVector

  // unit transformation

  //@ts-ignore
  var lengthScale = Autodesk.Viewing.Private.convertUnits(bcfUnit, viewer.model.getUnitString(), 1, 1)
  var eye = new THREE.Vector3(p.x * lengthScale, p.y * lengthScale, p.z * lengthScale)

  // viewer.target = viewPoint + (direction * viewer.focallength)

  //@ts-ignore
  var sightVec = new THREE.Vector3(d.x, d.y, d.z).multiplyScalar(viewer.navigation.getFocalLength())
  var target = eye.clone().add(sightVec)

  var up = new THREE.Vector3(u.x, u.y, u.z)

  // Note: The transformation depdends on the viewer setup and on the bcf coordinate system.
  // Here, the bcf points use shared coordinate system. Further, we setup 'refPoint' during initialisation.
  // Then we only apply the offset. Use globalOffset from 'aggregatedView' to mitigate some potential with model.offset.

  // var offsetMatrix = viewer.model.getModelToViewerTransform()
  // var offsetEye = eye.applyMatrix4(offsetMatrix)
  // var offsetTarget = target.applyMatrix4(offsetMatrix)

  // @ts-ignore
  const offset = aggregatedView.globalOffset.clone()
  var offsetEye = eye.clone().sub(offset)
  var offsetTarget = target.clone().sub(offset)
  var fov = camera.fov ?? 60

  var cameraView = {
    //@ts-ignore
    aspect: viewer.getCamera().aspect,
    isPerspective: camera.fov ? true : false,
    fov: fov,
    position: offsetEye,
    target: offsetTarget,
    up: up,
    orthoScale: camera.scale ?? 1
  }

  //@ts-ignore
  viewer.impl.setViewFromCamera(cameraView)
}

/**  Returns a BCFCamera from the viewer camera.
`* setCamera(viewer, getCamera(viewer))` should be the same view. */
export const getBCFCamera = (aggregatedView: AggregatedView): BCFCamera => {
  const viewer = aggregatedView.viewer
  const position = viewer.navigation.getPosition().clone()
  const direction = viewer.navigation.getEyeVector().clone().normalize()
  const upVector = viewer.navigation.getCameraUpVector().clone()

  //@ts-ignore
  const lengthScale = Autodesk.Viewing.Private.convertUnits(viewer.model.getUnitString(), bcfUnit, 1, 1)
  // @ts-ignore
  const offset = aggregatedView.globalOffset.clone()
  const viewPoint = position.clone().add(offset).multiplyScalar(lengthScale)
  const camera = viewer.navigation.getCamera()
  const [fov, scale] = camera.isPerspective ? [camera.fov, undefined] : [undefined, camera.orthoScale]

  return { viewPoint, direction, upVector, fov, scale }
}

// #endregion

// #region selection

const ifcGuidProperty = "IfcGUID"

const getDbIds = async (model: Model): Promise<number[]> => {
  const exIds = getExternalIdMapping(model)
  const dbIds = Object.values(exIds)
  return dbIds
}

/** Returns a 'Selections' from a list of ifcGuids. */
const ifcGuidSelections = async (viewer: Viewer3D, ifcGuids: string[]): Promise<Selections> => {
  let fromModel = async (model: Model) => {
    let dbIds = await getDbIds(model)
    let properties = await getBulkProperties(model, dbIds, { propFilter: [ifcGuidProperty] })

    const ids = [] as number[]

    for (const property of properties) {
      let guid = property.properties[0].displayValue as string
      if (ifcGuids.includes(guid)) ids.push(property.dbId)
    }
    return { modelId: model.id, dbIds: ids }
  }
  let selections = await Promise.all(viewer.getAllModels().map(fromModel))
  return selections
}

/** Set viewer selection for elements with corresponding ifcGuids. */
export const setBCFCSelection = (viewer: Viewer3D, ifcguids: string[]) => {
  ifcGuidSelections(viewer, ifcguids)
    .then((selections) => select(viewer, selections))
    .catch((e) => console.error("Failed to set IfcGuid selection", e))
}

/** Returns a list of ifcGuids from a given 'Selections'. */
const ifcGuids = async (viewer: Viewer3D, selections: Selections): Promise<string[]> => {
  const fromSelection = async ({ modelId, dbIds }: { modelId: number; dbIds: number[] }) => {
    const model = tryFindModel(viewer, modelId)
    if (!model) return []
    const properties = await getBulkProperties(model, dbIds, { propFilter: [ifcGuidProperty] })
    const ifcGuids = properties.map((property) => property.properties[0].displayValue as string)
    return ifcGuids
  }
  const guids = await Promise.all(selections.map(fromSelection))
  return guids.flat()
}

/** Returns a list of IfcGuids, from a given selection. Not all elements have   */
export const getBCFSelection = async (viewer: Viewer3D, selections: Selections): Promise<string[]> => {
  try {
    const guids = await ifcGuids(viewer, selections)
    return guids
  } catch (e) {
    console.error("Failed to get IfcGuid selection", e)
    return []
  }
}

// #endregion

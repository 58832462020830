import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Autocomplete,
  TextField,
  Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { Error as ErrorIcon } from "@mui/icons-material"
import { ColumnDefinition, ContentTypeInfo, DriveItem } from "@microsoft/microsoft-graph-types"
import { a11yProps, TabPanel } from "../Dashboard"
import { DocumentsDialogTree } from "./DocumentsTree"
import { Field, useAzureSharepointData } from "../../useHooks/useDocuments"
import { getTree, TreeNode } from "../../utils/pathListToTree"
import { ContentTypesFilter } from "./ContentTypesFilter"
import { MetaDataFilter } from "./MetaDataFilter"
import useUser from "../../useHooks/useUser"

export interface AddDocumentDTO {
  projectId: string
  driveId: string
  id: string
  name: string
}

interface AddDocumentDialogProps {
  projectId: string
  open: boolean
  onCancel: () => void
  onSubmit: (document: AddDocumentDTO | undefined) => void
}

export const AddDocumentDialog = (props: AddDocumentDialogProps) => {
  const { data: user } = useUser(props.projectId)
  const { data: azureDocuments, loading, error } = useAzureSharepointData(user?.azeProjectId)
  const [documents, setDocuments] = useState<DriveItem[]>([])
  const [trimmedPaths, setTrimmedPaths] = useState<string[]>([])
  const [value, setValue] = useState(0)

  const [selected, setSelected] = useState<string[]>([])
  const [expanded, setExpanded] = useState<string[]>([])

  const [filteredTree, setFilteredTree] = useState<TreeNode[]>()

  const [selectedMetaDataFields, setSelectedMetaDataFields] = useState<Field[]>([])
  const [activeChipsIndex, setActiveChipsIndex] = useState<string[]>([])

  const [activeContentTypes, setActiveContentTypes] = useState<string[]>([])
  const [filteredColumns, setFilteredColumns] = useState<ColumnDefinition[]>([])
  const [allColumns, setAllColumns] = useState<ColumnDefinition[]>([])
  const [contentTypes, setContentTypes] = useState<ContentTypeInfo[]>([])

  useEffect(() => {
    setFilteredTree(filteredTree)
  }, [filteredTree])

  //we generate the initialTree first, afterwards it will be generated new by selected metadata
  useEffect(() => {
    const initialTree = getTree(azureDocuments)
    setFilteredTree(initialTree)
  }, [azureDocuments])

  //we generate an initial contentTypes state; afterwards the state gets upadated by click
  useEffect(() => {
    if (azureDocuments) {
      const columns = azureDocuments.flatMap((document) => document.allColumns).flatMap((column) => column)
      //we don't support columns from default? contenttypes 'Element', 'System', 'Ordner', 'Dokument' and no hidden columns and filter duplicates
      const allColumns: ColumnDefinition[] = columns
        .filter(
          (column) =>
            column.sourceContentType!.name !== "Element" &&
            column.sourceContentType!.name !== "System" &&
            column.sourceContentType!.name !== "Ordner" &&
            column.sourceContentType!.name !== "Dokument" &&
            column.columnGroup !== "_Hidden"
        )
        .filter((value, index, self) => index === self.findIndex((column) => column.displayName === value.displayName))
        .sort((a, b) => a.displayName!.localeCompare(b.displayName!))

      //get distinct contenttypes from columndefinitions
      const contentTypes = allColumns
        .map((column) => column.sourceContentType!)
        .filter((value, index, self) => index === self.findIndex((cType) => cType.id === value.id))

      setAllColumns(allColumns)
      setFilteredColumns(allColumns)
      setContentTypes(contentTypes)
    }
  }, [azureDocuments])

  //closing the dialog will not reset the states!
  const resetStates = () => {
    const initialTree = getTree(azureDocuments)
    setDocuments([])
    setTrimmedPaths([])
    setValue(0)
    setSelected([])
    setFilteredTree(initialTree)
    setExpanded([])
    setFilteredColumns([])
    setSelectedMetaDataFields([])
    setActiveChipsIndex([])
  }

  const handleCancel = () => {
    props.onCancel()
    resetStates()
  }

  const handleSubmit = () => {
    if (documents && documents.length > 0) {
      documents.forEach((document) => {
        const addDocument = document.id?.length
          ? {
              projectId: props.projectId,
              id: document.id!,
              name: document.name!,
              driveId: document.parentReference?.driveId!
            }
          : undefined
        props.onSubmit(addDocument)
      })
    }
    resetStates()
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  //trimms everything before root item and adds the filename to show in dialog
  const getTrimmedPath = (trimPath: string | undefined, item: DriveItem) => {
    const trimmedPath = trimPath!.substring(trimPath!.indexOf("root:"))
    return trimmedPath + "/" + item.name
  }

  //nodeId of treeitems = DriveItemId
  //get the selected driveitems by id by filtering all items and generate the path to show selected path
  const onTreeSelectedHandler = (nodeIds: string[], allItems: DriveItem[]) => {
    var tempPaths = [""]
    var tempItems: DriveItem[] = []
    nodeIds.forEach((nodeId) => {
      const tempItem = allItems.filter((item) => nodeId === item.id!)
      if (tempItem) {
        tempItem.forEach((item) => {
          if (item.folder === null) {
            tempItems.push(item)
            const trimPath = item.parentReference?.path
            tempPaths.push(getTrimmedPath(trimPath!, item))
          }
        })
      }
    })
    setDocuments(tempItems)
    setTrimmedPaths(tempPaths)
  }

  //handler for search-tab
  const onSearchChangedHandler = (items: DriveItem[]) => {
    setDocuments(items)
    setSelected(items.map((item) => item.id!))
    var tempPaths = [""]
    items?.forEach((item) => {
      const trimPath = item?.parentReference?.path
      tempPaths.push(getTrimmedPath(trimPath!, item))
    })
    setTrimmedPaths(tempPaths)
  }

  //get the (filtered) columns from the ContentTypeFilter
  //to not lose the states of selected content types when changing tabs, we save the states in the parent and change it in the childs
  const onContenTypeChanged = (columns: ColumnDefinition[], newContentTypes: string[]) => {
    setFilteredColumns(columns)
    setActiveContentTypes(newContentTypes)
  }

  //get the filtered tree from the meta data filter
  //to not lose the states of selected fields when changing tabs, we save the states in the parent and change it in the childs
  const onMetaDataChanged = (filteredTree: TreeNode[], newChipsIndex: string[], newSelectedMetaDataFields: Field[]) => {
    setFilteredTree(filteredTree)
    setSelectedMetaDataFields(newSelectedMetaDataFields)
    setActiveChipsIndex(newChipsIndex)
  }

  //Handler for TreeView Checkboxes
  //Source: https://codesandbox.io/s/typescript-data-driven-treeview-dhkx2?file=/src/App.tsx
  function getChildById(node: TreeNode, id: string) {
    let nodeIds: string[] = []

    const getAllChild = (nodes: TreeNode | null) => {
      if (nodes === null) return []
      nodeIds.push(nodes.itemId)
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          nodeIds = [...nodeIds, ...getAllChild(node)]
          nodeIds = nodeIds.filter((v, i) => nodeIds.indexOf(v) === i)
        })
      }
      return nodeIds
    }

    const getNodeById = (nodes: TreeNode, id: string) => {
      if (nodes.itemId === id) {
        return nodes
      } else if (Array.isArray(nodes.children)) {
        let result = null
        nodes.children.forEach((node) => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id)
          }
        })
        return result
      }

      return null
    }

    return getAllChild(getNodeById(node, id))
  }

  const getOnChange = (checked: boolean, nodes: TreeNode, allItems: DriveItem[]) => {
    const allNode: string[] = getChildById(nodes, nodes.itemId)
    let nodeIds = checked ? [...selected, ...allNode] : selected.filter((value) => !allNode.includes(value))

    nodeIds = nodeIds.filter((v, i) => nodeIds.indexOf(v) === i)

    setSelected(nodeIds)
    onTreeSelectedHandler(nodeIds, allItems)
  }

  if (azureDocuments) {
    const files = azureDocuments.flatMap((document) => document.allItems.filter((item) => item.file! != null))
    const allItems = azureDocuments.flatMap((document) => document.allItems)

    //options for search-field
    const options = {
      options: files,
      getOptionLabel: (option: DriveItem) => option.name!
    }
    return (
      <Dialog open={props.open} fullWidth maxWidth={"lg"}>
        <Box sx={{ width: "100%", padding: 0, margin: 0, height: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0, margin: 0 }}>
            <Tabs value={value} onChange={handleChangeTab} aria-label="Document Tabs">
              <Tab label="Select" {...a11yProps(0)} />
              <Tab label="Search" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} height={"auto"}>
            <DialogTitle>Attach Documents</DialogTitle>
            <DialogContent sx={{ minWidth: "auto" }}>
              {loading && <CircularProgress />}
              {error && <ErrorIcon />}
              <Typography variant="body1">Filter Documents by Metadata</Typography>
              <br />
              <ContentTypesFilter
                azureDocuments={azureDocuments}
                activeContentTypes={activeContentTypes}
                contentTypes={contentTypes}
                allColumns={allColumns}
                onContentTypeChanged={onContenTypeChanged}
              />
              <br />
              <MetaDataFilter
                azureDocuments={azureDocuments}
                filteredColumns={filteredColumns}
                activeChipsIndex={activeChipsIndex}
                selectedMetaDataFields={selectedMetaDataFields}
                onMetaDataChanged={onMetaDataChanged}
              />
              <br />
              <DocumentsDialogTree
                items={allItems}
                selected={selected}
                expanded={expanded}
                setExpanded={setExpanded}
                getOnChange={getOnChange}
                tree={filteredTree}
              />
              <hr />
              <Typography variant="body1">Selected Item(s):</Typography>
              {trimmedPaths?.map((path, index) => (
                <Typography key={index} variant="body2">
                  {path}
                </Typography>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleSubmit} disabled={!documents.length}>
                Submit
              </Button>
            </DialogActions>
          </TabPanel>
          <TabPanel value={value} index={1} height={"auto"}>
            <DialogTitle>Attach Documents</DialogTitle>
            <DialogContent sx={{ minWidth: "auto" }}>
              {loading && <CircularProgress />}
              {error && <ErrorIcon />}
              <Autocomplete
                limitTags={5}
                id="multiple_documents"
                multiple
                {...options}
                autoSelect
                value={documents || null}
                size={"small"}
                ChipProps={{ color: "secondary" }}
                onChange={(event, values) => onSearchChangedHandler(values)}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Sharepoint Files" variant="standard" placeholder="Documents" />
                )}
              />
              <br />
              <Typography variant="body1">Selected Item(s):</Typography>
              {trimmedPaths?.map((path, index) => (
                <Typography key={index} variant="body2">
                  {path}
                </Typography>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleSubmit} disabled={!documents.length}>
                Submit
              </Button>
            </DialogActions>
          </TabPanel>
        </Box>
      </Dialog>
    )
  } else {
    return (
      <>
        Loading Documents
        <CircularProgress size={12} />
      </>
    )
  }
}

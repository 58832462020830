import {
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Paper,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
  DialogActions,
  Button,
  useTheme,
  FormControlLabel
} from "@mui/material"
import Papa from "papaparse"
import React, { useState, useEffect, ChangeEvent } from "react"
import { useFetchWithToken } from "../../Auth/Msal"
import { useSelection } from "../../features/selection/Selection"
import useParameterSelection from "../../useHooks/useParameterSelection"
import {
  toPropertySet,
  afterCharacter,
  GetBulkPropertiesOptions,
  tryFindModel,
  getBulkProperties,
  getDocumentId,
  externalIdWithDoc
} from "../../Viewer/utils"
import { ParameterDeclaration } from "../ManageParametersTab/ParameterList"
import { Error as ErrorIcon, Check as CheckIcon, Download } from "@mui/icons-material"
import { TemplateDeclaration, useTemplate } from "./Templates"
import useParameterDeclarations from "../../useHooks/useParameterDeclarations"
import { ParameterInstance } from "../AssignParametersTab/AssignParameters"
import { fetchAttachments } from "./DumpRevitAttachmentsDialog"
import { useFilters } from "../../useHooks/useFilters"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

const ExportDialogListItem = <T,>(props: {
  index: number
  item: T
  stringifyItem: (item: T) => string
  isChecked: boolean
  handleToggle: (_: T) => void
}) => {
  const value = props.stringifyItem(props.item)
  return React.useMemo(
    () => (
      <ListItem dense disablePadding key={value} role="listitem" onClick={() => props.handleToggle(props.item)}>
        <ListItemIcon>
          <Checkbox size="small" checked={props.isChecked} tabIndex={-1} disableRipple />
        </ListItemIcon>
        <ListItemText id={value} primary={value} />
      </ListItem>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.item, props.index, props.isChecked]
  )
}

interface CheckedListBoxProps<T> {
  items: T[]
  initialSelection?: T[]
  stringifyItem: (item: T) => string
  setCheckedItems: (checkedItems: T[]) => void
}

export const CheckedListBox = <T extends unknown>(props: CheckedListBoxProps<T>) => {
  const [checked, setChecked] = useState<T[]>([])
  const handleToggle = (index: T) => {
    setChecked((old) => {
      var newer = old.includes(index) ? old.filter((x) => x !== index) : [...old, index]
      props.setCheckedItems(newer)
      return newer
    })
  }

  useEffect(() => {
    if (props.initialSelection) {
      setChecked(props.initialSelection)
      props.setCheckedItems(props.initialSelection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Paper elevation={0} variant="outlined">
      <List
        dense
        disablePadding
        role="list"
        sx={{
          maxHeight: 250,
          overflowY: "auto"
        }}
      >
        {props.items.map((item: T, i: number) => {
          return (
            <ExportDialogListItem
              key={i}
              index={i}
              item={item}
              isChecked={checked.includes(item)}
              handleToggle={handleToggle}
              stringifyItem={props.stringifyItem}
            />
          )
        })}
      </List>
    </Paper>
  )
}

interface ExportDialogProps {
  open: boolean
  projectId: string
  viewer: Viewer3D
  template: TemplateDeclaration | undefined
  onCancel: () => void
  onSubmit: () => void
}

const ExportDialog = (props: ExportDialogProps) => {
  const { data: _parameters, loading, error } = useParameterDeclarations(props.projectId)
  const parameters = _parameters.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))

  const { data: template, loading: templateLoading, error: templateError } = useTemplate(props.template)

  const selection = useSelection()
  const parameterSelection = useParameterSelection(props.viewer)
  const { data: filters, loading: filterLoading, error: filterError } = useFilters(props.projectId)

  const [properties, setProperties] = useState<string[] | undefined>()

  // internal model properties
  const [checkedProperties, setCheckedProperties] = useState<string[]>([])
  const [initialCheckedProperties, setInitialCheckedProperties] = useState<string[] | undefined>()
  // external custom properties/parameters
  const [checkedParameters, setCheckedParameters] = useState<ParameterDeclaration[]>([])
  const [initialCheckedParameters, setInitialCheckedParameters] = useState<ParameterDeclaration[] | undefined>()
  const [csv, setCsv] = useState<string | "preparing" | undefined>(undefined)
  // attachements
  const [checkedAttachments, setCheckedAttachments] = useState<boolean>(false)
  const handleChangeCheckedAttachments = (ev: ChangeEvent<HTMLInputElement>) => setCheckedAttachments(ev.target.checked)

  const theme = useTheme()
  const spacing = theme.spacing(1)
  const fetchWithToken = useFetchWithToken()

  useEffect(() => {
    const dropCategory = (keyWithCategory: string) => afterCharacter(keyWithCategory, "/")
    toPropertySet(props.viewer, selection).then((ps) => {
      // propertyset uses keys in form of 'category/name' in queries we only use
      // name, so drop the category
      //
      // ps is a class with a property "map" and a bunch of byzantine methods
      // and we simply want their keys (but this is not typed sufficiently)
      // @ts-expect-error
      const keys = Object.keys(ps.map)
      const properties = keys.map(dropCategory)
      const uniqueProperties = Array.from(new Set(properties)).sort()
      setProperties(uniqueProperties)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection])

  useEffect(() => {
    if (parameters && template && !templateLoading && !templateError) {
      setInitialCheckedProperties(template.propertyNames)
      setInitialCheckedParameters(parameters.filter((p) => template.parameterIds.includes(p.parameterId)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, parameters])

  const handleCancel = () => props.onCancel()

  const makeDialog = (diagProps: { content: JSX.Element; customActions?: JSX.Element }) => {
    return (
      <Dialog fullWidth open={props.open} keepMounted={false}>
        <DialogTitle>Export CSV</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>{diagProps.content}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          {diagProps.customActions}
        </DialogActions>
      </Dialog>
    )
  }

  if (csv === "preparing")
    return makeDialog({
      content: (
        <>
          <CircularProgress />
          <Typography variant="body1">Preparing CSV ...</Typography>
        </>
      )
    })

  if (csv) {
    const handleDownload = () => {
      // Opens/downloads the CSV file in the browser.
      const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" })

      const csvURL = window.URL.createObjectURL(csvData)

      const tempLink = document.createElement("a")
      tempLink.href = csvURL
      tempLink.setAttribute("test", "test.csv")
      tempLink.download = "parameters"
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)

      props.onSubmit()
    }
    return makeDialog({
      content: (
        <>
          <CheckIcon />
          <Typography variant="body1">CSV ready!</Typography>
        </>
      ),
      customActions: (
        <Button startIcon={<Download />} onClick={handleDownload}>
          Download
        </Button>
      )
    })
  }

  if (!properties || loading)
    return makeDialog({
      content: (
        <>
          Loading data for selection...
          <CircularProgress />
        </>
      )
    })

  if (error)
    return makeDialog({
      content: (
        <>
          <ErrorIcon />
          <Typography variant="subtitle1" color={theme.palette.error.light}>
            Failed to load data
          </Typography>
        </>
      )
    })

  if (
    props.template &&
    (templateLoading || initialCheckedParameters === undefined || initialCheckedProperties === undefined)
  )
    return makeDialog({
      content: (
        <>
          Loading data from template...
          <CircularProgress />
        </>
      )
    })

  if (templateError)
    return makeDialog({
      content: (
        <>
          <ErrorIcon />
          <Typography variant="subtitle1" color={theme.palette.error.light}>
            Failed to load template!
          </Typography>
        </>
      )
    })

  const handleSubmit = async () => {
    if (!parameterSelection) {
      console.warn("Export parameters. No selection.")
      return
    }
    if (filterLoading || filterError) {
      console.warn("Export parameters. No filter.")
      return
    }

    setCsv("preparing")

    const options: GetBulkPropertiesOptions = {
      propFilter: ["externalId", "name", ...checkedProperties],
      needsExternalId: true
    }

    const parameterIds = checkedParameters.map((x) => x.parameterId)

    const unflattenedProperties = await Promise.all(
      selection.map(async (s) => {
        if (!s.dbIds) return []
        const model = tryFindModel(props.viewer, s.modelId)
        if (!model) return []

        const bulkProps = await getBulkProperties(model, s.dbIds, options)
        const { documentId } = getDocumentId(model)

        return bulkProps.map((p) => [
          `${props.projectId}+${externalIdWithDoc(p.externalId ?? "<NO-ID>", documentId)}`,
          ...checkedProperties.map((key) => {
            if (key.toLowerCase() === "name") {
              return p.name
            }
            const propDV = p.properties.find((prop) => prop.displayName === key)?.displayValue
            return propDV
          })
        ])
      })
    )
    const propertyRows = unflattenedProperties.flat()

    // toElementId('projectId+documentId+externalId') = 'documentId+externalId
    const toElementId = (key: string | number | undefined) => afterCharacter(key?.toString() ?? "+<NOID>", "+")

    const elmentIds = propertyRows.map((row) => toElementId(row[0]))
    const url = `/api/twin/viewer/projects/${props.projectId}/batchParameters`

    const response = await fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ parameterIds: parameterIds, elementIds: elmentIds })
    })
    if (response.status !== 200) {
      console.error("Failed to fetch parameter.", response.statusText)
      return
    }
    const parameterInstances: ParameterInstance[] = await response.json()

    const rows = propertyRows.map((row) => {
      const elementId = toElementId(row[0])
      const relevantValues = parameterInstances.filter((p) => p.elementId === elementId)

      const values = parameterIds.map((id) => relevantValues.find((p) => p.parameterId === id)).map((p) => p?.value)
      // relevantValues.length > 0 ? relevantValues.map((p) => p.value) : new Array<string>(parameterIds.length)

      return [...row, ...values]
    })

    if (checkedAttachments) {
      const headerRow = [
        "elementId",
        ...checkedProperties,
        ...checkedParameters.map((p) => `${p.name}#${p.parameterId}`),
        "Attachments"
      ]

      const attachments = await fetchAttachments(props.viewer, props.projectId, filters, fetchWithToken, {
        selectionFilter: selection
      })

      const rowsWithAttachments = rows.map((row) => {
        const id = row[0] as string
        const [, documentId, externalId] = id.split("+", 3)
        const documents = attachments
          .filter((mapping) =>
            mapping.elements.find((elem) => elem.documentId === documentId && elem.uniqueIds.includes(externalId))
          )
          .flatMap((mapping) => mapping.documents)
          .map((document) => document.name + ": " + document.url)
          .join(" - ")
        return [...row, documents]
      })

      const csvContent = Papa.unparse([headerRow, ...rowsWithAttachments])

      setCsv(csvContent)
    } else {
      const headerRow = [
        "elementId",
        ...checkedProperties,
        ...checkedParameters.map((p) => `${p.name}#${p.parameterId}`)
      ]

      const csvContent = Papa.unparse([headerRow, ...rows])

      setCsv(csvContent)
    }
  }

  return makeDialog({
    content: (
      <>
        <Paper elevation={0} style={{ margin: spacing }}>
          <Typography variant="h5">Revit Properties</Typography>
          <CheckedListBox
            items={properties}
            stringifyItem={(x) => x}
            setCheckedItems={setCheckedProperties}
            initialSelection={initialCheckedProperties}
          />
        </Paper>
        <Paper elevation={0} style={{ margin: spacing }}>
          <Typography variant="h5">Digital Twin Parameters</Typography>
          <CheckedListBox
            items={parameters}
            stringifyItem={(x) => x.name}
            setCheckedItems={setCheckedParameters}
            initialSelection={initialCheckedParameters}
          />
        </Paper>
        <Paper elevation={0} style={{ margin: spacing }}>
          <Typography variant="h5">Digital Twin Attachments</Typography>
          <FormControlLabel
            label="Export Digital Twin Attachments"
            control={<Checkbox checked={checkedAttachments} onChange={handleChangeCheckedAttachments} />}
            disabled={filterLoading || Boolean(filterError)}
          />
        </Paper>
      </>
    ),
    customActions: <Button onClick={handleSubmit}>Submit</Button>
  })
}

export default ExportDialog

import { AccountCircle } from "@mui/icons-material"
import { Button } from "@mui/material"

const SignInButton = (props?: any) => {
  const { ...other } = props

  return (
    <Button href={"/api/forge/signin"} target={"_blank"} startIcon={<AccountCircle />} {...other}>
      Sign In
    </Button>
  )
}

export default SignInButton

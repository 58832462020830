type Model = Autodesk.Viewing.Model
type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

export const tryFindModel = (viewer: Viewer3D, modelId: number): Model | undefined =>
  viewer.getAllModels().find((model) => model.id === modelId)

export const getModelName = (model: Model) => model.getDocumentNode().getRootNode().children[0].name()

// urn are encoded as base64URL
// javascript window.atob expects (standard) base64 encoding
const tidy = (base64: string) => base64.replace(/[^A-Za-z0-9+/]/g, "")

const removePadding = (base64: string) => base64.replace(/=/g, "")

const toUri = (base64: string): string => removePadding(base64).replace(/[+/]/g, (match) => (match === "+" ? "-" : "_"))

const fromUri = (base64: string): string => tidy(base64.replace(/[-_]/g, (match) => (match === "-" ? "+" : "/")))

export const decodeUrn = (base64: string) => window.atob(fromUri(base64))

export const encodeUrn = (urn: string) => toUri(window.btoa(urn))

/** Returns docuemntId and version of a model.
 * Obtained from bas64 encoded model urn.
 */
export const getDocumentId = (model: Model): { documentId: string; version: string } => {
  // @ts-ignore
  const urn = model.myData.urn
  const decodedUrn = decodeUrn(urn)
  const [documentId, version] = decodedUrn.split("?version=")
  return { documentId, version }
}

/** Looks for a model using its documentId. */
export const tryFindModelWithDocumentId = (viewer: Viewer3D, documentId: string): Model | undefined =>
  viewer.getAllModels().find((model) => {
    const { documentId: modelId } = getDocumentId(model)
    return documentId === modelId
  })

export const getExternalIdMappingAsync = async (model: Model): Promise<{ [key: string]: number }> =>
  await new Promise((res, rej) => model.getExternalIdMapping(res, rej))

/** Returns a mapping from externalIds to dbIds. */
export const getExternalIdMapping = (model: Model): { [key: string]: number } =>
  globalThis.externalIds.get(model.id) ?? {}

interface LinkedModel {
  instanceId: string
  name: string
}

// Info of linked models can be obtaind from AEC data or from the model tree.
export const getLinkedModels = async (model: Model) => {
  const dbIds = await new Promise<number[]>((resolve) =>
    // -2001352 is "Revit Linked Model" category
    model.search("-2001352", resolve, console.error, ["CategoryId"], { searchHidden: true })
  )
  const props = await getBulkProperties(model, dbIds, { propFilter: ["Type Name", "externalId"] })
  const linkedModels = props.reduce((acc, prop) => {
    if (prop.externalId && !prop.externalId.includes("/")) {
      const properties = prop.properties
      if (properties[0]?.attributeName === "Type Name") {
        acc.push({
          instanceId: prop.externalId,
          name: prop.properties[0].displayValue as string
        })
      } else {
        console.warn("Linked Model: Data Missing. Expected attribute 'Type Name'")
      }
    }
    return acc
  }, [] as LinkedModel[])
  return linkedModels
}

// #region Properties

type PropertyResult = Autodesk.Viewing.PropertyResult

export interface GetBulkPropertiesOptions {
  propFilter?: string[]
  categoryFilter?: string[]
  ignoreHidden?: boolean
  needsExternalId?: boolean
}

/** Promise wrapper for model.getBulkProperties2 */
export const getBulkProperties = (
  model: Model,
  dbIds: number[],
  options?: GetBulkPropertiesOptions,
  getAll?: boolean
): Promise<PropertyResult[]> =>
  new Promise((resolve) =>
    dbIds.length || getAll
      ? model.getBulkProperties2(dbIds, options, resolve, (s, m, d) => {
          console.warn(`Get bulk properties for model ${model.id} failed with ${s} ${m} ${d}`)
          resolve([])
        })
      : resolve([])
  )

export const getBulkPropertyMap = (
  models: Model[],
  options?: GetBulkPropertiesOptions,
  selections?: Selections,
  getAll?: boolean
) =>
  Promise.all(
    models.map(async (m) => {
      const ps = await getBulkProperties(m, selections?.find((s) => s.modelId === m.id)?.dbIds ?? [], options, getAll)
      return [m.id, ps] as const
    })
  ).then((xs) => new Map(xs))

/** Returns externalIds for dbIds. */
export const getExternalIds = async (model: Model, dbIds: number[]): Promise<string[]> => {
  const options: GetBulkPropertiesOptions = { propFilter: ["externalId"], needsExternalId: true }
  const properties = await getBulkProperties(model, dbIds, options)

  const ids = []
  for (const property of properties) if (property.externalId) ids.push(property.externalId)
  return ids
}

// #endregion

// #region selection

/** Storable selection type. */
export type Selections = { modelId: number; dbIds: number[] }[]
/** Autodesk aggregate selection type. */
type SetAggregateSelection = { model: Model; ids: number[] }[]

const toAggregateSelection = (viewer: Viewer3D, selections: Selections): SetAggregateSelection =>
  selections.reduce((result: SetAggregateSelection, { modelId, dbIds }) => {
    const model = tryFindModel(viewer, modelId)
    model && result.push({ model: model, ids: dbIds })
    return result
  }, [])

const arrdiff = <T>(a: T[], b: T[]) => a.filter((x) => !b.includes(x))

export const selectionsDifference = (lhs: Selections, rhs: Selections) => {
  const filterIds = (modelId: number, dbIds: number[]) => {
    const selection = rhs.find(({ modelId: modelIdRhs }) => modelIdRhs === modelId)
    return selection?.dbIds?.length ? arrdiff(dbIds, selection?.dbIds) : dbIds
  }
  return lhs.map(({ modelId, dbIds }) => ({ modelId, dbIds: filterIds(modelId, dbIds) }))
}

export const selectionsDifferences = (lhs: Selections, rhss: Selections[]) =>
  rhss.reduce((acc, rhs) => selectionsDifference(acc, rhs), lhs)

/** Clear selection and select elements using 'setAggregateSelection'. */
export const select = (viewer: Viewer3D, selections: Selections): void => {
  const aggregateSelection = toAggregateSelection(viewer, selections)

  viewer.impl.selector.clearSelection(true)
  viewer.impl.selector.setAggregateSelection(aggregateSelection)
}

/** Represents an external selection.
 * Uses (decoded) urn of a model and externalIds.
 */
export type ExternalSelection = { documentId: string; version: string; externalIds: string[] }[]

/** Returns an external selection that can be serialized. */
export const toExternalSelection = async (viewer: Viewer3D, selections: Selections): Promise<ExternalSelection> => {
  const sel = []
  for (const { modelId, dbIds } of selections) {
    const model = tryFindModel(viewer, modelId)
    if (model) {
      const { documentId, version } = getDocumentId(model)
      const externalIds = await getExternalIds(model, dbIds)
      sel.push({ documentId, version, externalIds: externalIds })
    }
  }
  return sel
}

export const externalIdWithDoc = (externalId: string, documentId: string) => `${documentId}+${externalId}`

/** Returns 'documentId+externalId' of selection. Ignores document version. */
export const toParameterSelection = async (viewer: Viewer3D, selections: Selections): Promise<string[]> => {
  const externalSelection = await toExternalSelection(viewer, selections)
  return externalSelection.flatMap((sel) => sel.externalIds.map((eid) => externalIdWithDoc(eid, sel.documentId)))
}

/** Returns selection from list of 'documentId+externalId'. */
export const fromParameterSelection = async (viewer: Viewer3D, selections: string[]): Promise<Selections> =>
  fromParameterSelectionFromModels(viewer.getAllModels(), selections)

/** Returns selection from list of 'documentId+externalId'. */
export const fromParameterSelectionFromModels = async (models: Model[], selections: string[]): Promise<Selections> => {
  const keys = selections.map((selection) => {
    const [documentId, externalId] = selection.split("+")
    return { documentId, externalId }
  })
  const selectionsM = models.map(async (model) => {
    const mapping = getExternalIdMapping(model)
    const dbIds = keys
      .filter(({ documentId }) => documentId === getDocumentId(model).documentId)
      .map(({ externalId }) => mapping[externalId])
      .filter((dbId) => dbId)
    return { modelId: model.id, dbIds }
  })
  return Promise.all(selectionsM)
}

// #endregion

// #region properties

export const toPropertySet = async (viewer: Viewer3D, selection: Selections) => {
  const aggregateSelection = toAggregateSelection(viewer, selection)
  const sets = await Promise.all(aggregateSelection.map((x) => x.model.getPropertySetAsync(x.ids, {})))
  const mergedSet = sets.reduce((previous, current) => previous.merge(current), new Autodesk.Viewing.PropertySet({}))
  return mergedSet
}

// #endregion

// #region levels

// NOTE: uses Forge LevelsExtension
// Copied from LevelsExtension.js
//   Options:
//     @param {bool} [autoDetectAecModelData = true]
//           Level selection requires data about existing floors. By default (true), these are extracted automatically:
//            - For a single model, we get them by calling Document.getAecModelData(bubbleNode).
//            - If multiple models with aecModelData are visible, we choose the largest one to define the levels.
//
//           If set to false, an application can (and has to) call setAecModelData() explicitly instead.
//    @param {bool} [ifcLevelsEnabled = false] - If enabled will try to extract levels for IFC models using heuristics.

export type LevelsExtension = Autodesk.AEC.LevelsExtension
export type FloorSelector = Autodesk.AEC.FloorSelector

export interface Level {
  index: number
  name: string
  zMin: number
  zMax: number
  guid?: string
}

export const getLevelsExtension = (viewer: Viewer3D): LevelsExtension => {
  const extension = viewer.getExtension("Autodesk.AEC.LevelsExtension") as LevelsExtension
  if (!extension) throw Error("Failed to get levels extension")

  return extension
}

/** Returns 'currentLevel' and list of 'levels'. */
export const getLevelData = (viewer: Viewer3D): { currentLevel: Level | undefined; levels: Level[] } => {
  const floorSelector = getLevelsExtension(viewer).floorSelector
  const levels = floorSelector.floorData as Level[]
  const currentLevel = floorSelector.currentFloor ? levels[floorSelector.currentFloor] : undefined
  return { currentLevel, levels }
}

/** Apply a level. Reset when levelId === undefined. */
export const applyLevel = (viewer: Viewer3D, levelId: string | undefined) => {
  const floorSelector = getLevelsExtension(viewer).floorSelector
  const level = floorSelector.floorData.find((level: Level) => level?.guid === levelId)
  floorSelector.selectFloor(level?.index, false)
}

export const getLevels = (viewer: Viewer3D): Level[] => getLevelsExtension(viewer).floorSelector.floorData

export const isOnLevel = (model: Model, level: Level | undefined, dbId: number): boolean => {
  if (!level) return false

  const levelMinZ = level.zMin
  const levelMaxZ = level.zMax

  if (levelMinZ === undefined || levelMaxZ === undefined) {
    console.warn("isOnLevel: zMin/zMax is undefined", levelMinZ, levelMaxZ)
    return false
  }

  // https://www.javaer101.com/en/article/42182080.html
  const nodeBox = new Float32Array(6)
  model.getInstanceTree().getNodeBox(dbId, nodeBox)

  const nodeMinZ = nodeBox[2]
  const nodeMaxZ = nodeBox[5]

  // NOTE: some elements have strange nodeboxes
  // In the Gruenblick development example, when filtering for level 25, there are some 'ZZ_Pflanztrog' with large nodeboxes.
  // Note sure what the best option is.

  // Check for center.
  // const nodeHeight = nodeMaxZ - nodeMinZ
  // const nodeCenter = nodeMinZ + nodeHeight / 2
  // const centerIsInside = nodeCenter >= levelMin && nodeCenter <= levelMax

  // Check used in FloorSelector.js
  // const outSideCutPlane = nodeMinZ > levelMax || nodeMaxZ < levelMin

  // Check if nodebox is inside cut plane
  const relax = (levelMaxZ - levelMinZ) * 0.1
  const inSideCutPlane = levelMinZ - relax < nodeMinZ && nodeMaxZ < levelMaxZ + relax

  return inSideCutPlane
}

// #endregion

// #region string ops

/** Returns the contents of string s after the first occurrence of c. I.E. afterCharacter("hello", "l") = "lo" */
export const afterCharacter = (s: string, c: string) => s.substring(s.indexOf(c) + c.length)

/** Returns the contents of string s before the first occurrence of c. I.E. beforeCharacter("hello", "l") = "he" */
export const beforeCharacter = (s: string, c: string) => s.substring(0, s.indexOf(c))

// #endregion

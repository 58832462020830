import { TreeItem, TreeView } from "@mui/lab"
import { TreeNode } from "../../utils/pathListToTree"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined"
import { DriveItem } from "@microsoft/microsoft-graph-types"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

interface DocumentsTabTreeProps {
  items: DriveItem[]
  tree: TreeNode[] | undefined
  onSelectedHandler: (nodeId: string, allItems: DriveItem[]) => void
}

interface DocumentsDialogTreeProps {
  items: DriveItem[]
  tree: TreeNode[] | undefined
  expanded: string[]
  selected: string[]
  setExpanded: (nodeIds: string[]) => void
  getOnChange: (checked: boolean, nodes: TreeNode, allItems: DriveItem[]) => void
}

const getIcon = (nodes: TreeNode) => {
  if (!nodes.isFolder) return <InsertDriveFileOutlinedIcon />
}

export const DocumentsTabTree = (props: DocumentsTabTreeProps) => {
  const renderTree = (nodes: TreeNode) => {
    if (!nodes) {
      return null
    }

    const icon = getIcon(nodes)

    return (
      <TreeItem key={nodes.itemId} nodeId={nodes.itemId} label={nodes.name} endIcon={icon}>
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    )
  }
  return (
    <TreeView
      aria-label="tabTree"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={(e: React.SyntheticEvent<Element, Event>, nodeId: string) =>
        props.onSelectedHandler(nodeId, props.items)
      }
    >
      {props.tree ? props.tree.map((data) => renderTree(data)) : null}
    </TreeView>
  )
}

export const DocumentsDialogTree = (props: DocumentsDialogTreeProps) => {
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    props.setExpanded(nodeIds)
  }

  const renderTree = (nodes: TreeNode) => {
    if (!nodes) {
      return null
    }

    const icon = getIcon(nodes)

    return (
      <TreeItem
        key={nodes.itemId}
        nodeId={nodes.itemId}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={props.selected.some((item) => item === nodes.itemId)}
                onChange={(event) => props.getOnChange(event.currentTarget.checked, nodes, props.items)}
                onClick={(e) => e.stopPropagation()}
              />
            }
            label={<>{nodes.name}</>}
            key={nodes.itemId}
          />
        }
        endIcon={icon}
      >
        {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    )
  }
  return (
    <TreeView
      aria-label="dialogTree"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={props.expanded}
      onNodeToggle={handleToggle}
    >
      {props.tree ? props.tree.map((data) => renderTree(data)) : null}
    </TreeView>
  )
}

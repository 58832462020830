import { useAuth0 } from "@auth0/auth0-react"
import { useHandleLogout as useHandleLogoutAuth0 } from "./Auth/Auth0"
import { useHandleLogout as useHandleLogoutMsal } from "./Auth/Msal"
import {
  AccountCircle,
  NavigateNext as NavigateNextIcon,
  Home as HomeIcon,
  Build as BuildIcon,
  Person as PersonIcon,
  Tv as TvIcon,
  Help
} from "@mui/icons-material"
import { AppBar as MuiAppBar, Breadcrumbs, IconButton, Menu, MenuItem, Theme, Toolbar, useTheme } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { useState } from "react"
import Link from "@mui/material/Link"
import { Link as RouterLink, matchPath, Params, useLocation } from "react-router-dom"
import { useIsAuthenticated } from "@azure/msal-react"
import "./AppBar.scss"
import Typography from "@mui/material/Typography"
import { environment, version } from "."

const Account = () => {
  // menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  // auth0
  const { isAuthenticated: isAuthenticatedAuth0 } = useAuth0()
  const isAuthenticatedMsal = useIsAuthenticated()

  const handleLogoutAuth0 = useHandleLogoutAuth0()
  const handleLogoutMsal = useHandleLogoutMsal()

  const isAuth = isAuthenticatedAuth0 || isAuthenticatedMsal

  const LogoutItem = () => {
    return (
      <>
        {!isAuth && <MenuItem disabled={true}>Logout</MenuItem>}
        {isAuthenticatedAuth0 && <MenuItem onClick={handleLogoutAuth0}>Logout Admin</MenuItem>}
        {isAuthenticatedMsal && <MenuItem onClick={handleLogoutMsal}>Logout User</MenuItem>}
      </>
    )
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <AccountCircle sx={{ color: "primary.contrastText" }} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <LogoutItem />
      </Menu>
    </div>
  )
}

const Breadcrumb = (theme: Theme, comp: JSX.Element, path: string) => {
  return (
    <Link
      key={path}
      component={RouterLink as any}
      underline="hover"
      sx={{ display: "flex", alignItems: "center", color: theme.palette.primary.contrastText }}
      to={path}
    >
      {comp}
    </Link>
  )
}

interface Route {
  key: string
  comp: (_: Params<string>) => JSX.Element
}

const RouteMap: Route[] = [
  {
    key: "/",
    comp: (_) => (
      <>
        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Digital Twin
      </>
    )
  },
  {
    key: "/admin",
    comp: (_) => (
      <>
        <BuildIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Admin
      </>
    )
  },
  {
    key: "/user",
    comp: (_) => (
      <>
        <PersonIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        User
      </>
    )
  },
  {
    key: "/viewer/:projectId",
    comp: (ps) => (
      <>
        <TvIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        {ps.projectId}
      </>
    )
  }
]

const BreadcrumbsComponent = () => {
  const location = useLocation()
  const theme = useTheme()
  const crumbs = RouteMap.map((x) => ({
    key: x.key,
    route: x,
    match: matchPath({ path: x.key, end: false }, location.pathname)
  }))
    .filter((x) => x.match)
    .map((x) => Breadcrumb(theme, x.route.comp(x.match!.params), x.match!.pathname))

  return (
    <Breadcrumbs aria-label="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />}>
      {crumbs}
    </Breadcrumbs>
  )
}

const Release = () => (
  <Typography variant="caption">
    {environment} {version}
  </Typography>
)

interface AppBarProps {
  onDrawerToggle: (open: boolean) => void
}

const Documentation = () => (
  <IconButton sx={{ color: "primary.contrastText" }} href={"/documentation/index.html"} target={"_blank"}>
    <Help />
  </IconButton>
)

const AppBar = (props: AppBarProps): JSX.Element => {
  return (
    <div className="appbar">
      <MuiAppBar position="static" elevation={2}>
        {/* use dense, then the toolbar has always height=48px */}
        <Toolbar variant="dense">
          <IconButton color="inherit" onClick={() => props.onDrawerToggle(true)}>
            <MenuIcon />
          </IconButton>
          <div className="location">
            <BreadcrumbsComponent />
          </div>
          <Release />
          <Account />
          <Documentation />
        </Toolbar>
      </MuiAppBar>
    </div>
  )
}

export default AppBar

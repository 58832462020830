/** List parameter filters of a project. */
import { TableCell, Typography, TableRow } from "@mui/material"
import Tabular from "./Tabular"
import { useState } from "react"
import { Filter } from "../../useHooks/useFilters"

interface FilterListProps {
  projectId: string
  filters: Filter[]
  onClick: (_: Filter | undefined) => void
}

const FilterList = (props: FilterListProps) => {
  const filters = props.filters
  const [selected, setSelected] = useState<string | undefined>()

  const toHeader = () =>
    ["Name", "User"].map((text) => (
      <TableCell key={text} onClick={handleSelect(undefined)}>
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </TableCell>
    ))

  const handleSelect = (filter: Filter | undefined) => () => {
    props.onClick(filter)
    setSelected(filter?.id)
  }

  const toRow = (filter: Filter) => (
    <TableRow key={filter.id} hover={true} onClick={handleSelect(filter)} selected={selected === filter.id}>
      <TableCell>{filter.name}</TableCell>
      <TableCell>{filter.azeUserName}</TableCell>
    </TableRow>
  )

  const toRows = () => filters.map(toRow)

  return <Tabular toHeader={toHeader} toRows={toRows} />
}

export default FilterList

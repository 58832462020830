/** List Modell 'Viewables' and apply on select. */
import { List, ListItemButton, ListItemText, Typography, useTheme } from "@mui/material"
import { applyLevel } from "../Viewer/utils"
import { AggregatedView, Viewable, ViewableGroup } from "../Viewer/Viewer"

interface ViewableListProps {
  aggregatedView: AggregatedView
  viewables: ViewableGroup[]
  onSelect: () => void
}

export const applyViewable = async (aggregatedView: AggregatedView, viewable: Viewable) => {
  const nodes = viewable.nodes
  aggregatedView.unloadAll((item) => {
    const urn = item.model.myData.urn
    return !nodes.find((node) => node.getRootNode().urn === urn)
  })
  await aggregatedView.switchView(nodes, null)
  await aggregatedView.waitForLoadDone()
  applyLevel(aggregatedView.viewer, undefined)
}

const ViewableList = (props: ViewableListProps) => {
  const theme = useTheme()
  const spacing = theme.spacing(4)

  const viewer = props.aggregatedView
  const viewables = props.viewables

  const handleSelect = (viewable: Viewable) => () => applyViewable(viewer, viewable).then(() => props.onSelect())

  const group = (group: ViewableGroup) => {
    const items = group.viewables.map((viewable, idx) => (
      <ListItemButton divider key={idx} onClick={handleSelect(viewable)}>
        <ListItemText primary={viewable.name} />
      </ListItemButton>
    ))
    return (
      <div style={{ paddingTop: spacing, maxWidth: "300px", wordBreak: "break-all" }}>
        <Typography variant="caption" color={theme.palette.text.disabled}>
          {group.name}
          {group.lastModified ? " (" + group.lastModified + ")" : ""}
        </Typography>
        <List>{items}</List>
      </div>
    )
  }

  const groups = viewables.map(group)
  return <List>{groups}</List>
}

export default ViewableList

import Color from "color"

export const Grey = Color.rgb(160, 170, 170)

export const Free = Color.rgb(50, 205, 50)
//export const Occupied: Color = [220, 20, 60]
export const Occupied = Color.rgb(255, 133, 0)

export const BackgroundLight = Color.rgb(227, 229, 228)
//export const BackgroundDark = Color.rgb(51, 51, 51)
export const BackgroundDark = Color.rgb(175, 175, 175)

export const ViewerHeaderDark = Color.rgb(45, 45, 46)

export const DraggerLight = Color.rgb(244, 247, 249)
export const DraggerDark = Color.rgb(80, 80, 80)

export const LoadingLight = Color.rgb(0, 0, 0)
export const LoadingDark = Color.rgb(0, 0, 0)

export const ChartGridLight = Color.rgb(0, 0, 0, 0.1)
export const ChartGridDark = Color.rgb(255, 255, 255, 0.1)

export const colors: Color[] = [
  Color.rgb(228, 0, 70),
  Color.rgb(160, 94, 181),
  Color.rgb(0, 150, 94),
  Color.rgb(255, 199, 44),
  Color.rgb(237, 139, 0),
  Color.rgb(0, 229, 239),
  Color.rgb(177, 179, 179)
]

export const colorForIndex = (index: number): Color => {
  // Safari doesn't support array.at
  const color = colors[index % colors.length]
  if (!color) throw Error(`Color index out-of-range (${index})`)
  return color
}

const normalize = (color: Color): number[] => [
  color.red() / 255,
  color.green() / 255,
  color.blue() / 255,
  color.alpha() / 255
]

// see https://github.com/mrdoob/three.js/blob/master/src/math/Color.js#L42
const SRGBToLinear = (c: number): number =>
  c < 0.04045 ? c * 0.0773993808 : Math.pow(c * 0.9478672986 + 0.0521327014, 2.4)

const convertSRGBToLinear = (color: Color): number[] => normalize(color).map(SRGBToLinear)

export const colorToVector4 = (color: Color): THREE.Vector4 => {
  const [r, g, b, a] = convertSRGBToLinear(color)
  return new THREE.Vector4(r, g, b, a)
}

export const colorToThree = (color: Color): THREE.Color => {
  const [r, g, b] = convertSRGBToLinear(color)
  return new THREE.Color(r, g, b)
}

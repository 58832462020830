import { useRef, useState } from "react"
import Split from "react-split"
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom"

import "./App.scss"
import { Notification } from "./features/notification/Notification"
import Viewer, { AggregatedView, Viewables } from "./Viewer/Viewer"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Admin from "./Admin/Admin"
import AppBar from "./AppBar"
import { AuthProvider } from "./Auth/Auth"
import Dashboard from "./Dashboard/Dashboard"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { Block } from "@mui/icons-material"
import User from "./User"
import { MsalProfile, useAccessToken } from "./Auth/Msal"

import { Auth0Profile } from "./Auth/Auth0"
import Navigation from "./Navigation/Navigation"
import { ErrorInfo, LoadingInfo } from "./Info"
import useModels from "./useHooks/useModels"
import SignInButton from "./utils/SignInButton"

// lazy did not work properly with withAuthenticated
// const AppBar = React.lazy(() => import("./AppBar"))
// const Admin = React.lazy(() => import("./Admin/Admin"))

// const getToken = async (): Promise<{ accessToken: string; expiresIn: number }> => {
//   const resp = await fetch("/api/forge/token")
//   if (resp.status !== 200) throw new Error("Failed to fetch token.")
//   return resp.json()
// }

const getTokenForProject = (accessToken: string, projectId: string) => async () => {
  const resp = await fetch("/api/twin/viewer/token/" + projectId, {
    headers: { Authorization: `Bearer ${accessToken}` }
  })
  if (resp.status !== 200) throw new Error("Failed to fetch token. " + resp.status)
  return resp.json()
}

interface ViewerWithDashboardProps {
  onDragEnd: (sizes: number[]) => void | undefined
  onSetAggregatedView: (view: Autodesk.Viewing.AggregatedView) => void
  onSetViewables: (viewables: Viewables) => void
  aggregatedView: AggregatedView | undefined
  viewables: Viewables | undefined
}

const ViewerWithDashboard = (props: ViewerWithDashboardProps) => {
  const params = useParams()
  const projectId = params.projectId
  if (props.aggregatedView?.viewer) {
    // add value one projectId to viewer
    // sometimes needed in forge components (eg PropertyPanel)
    // @ts-ignore
    props.aggregatedView.viewer.projectId = projectId
  }

  const accessToken = useAccessToken()
  const { data: models, loading, error } = useModels(projectId)

  console.debug("Project models", projectId, models)

  if (!accessToken?.length || loading) {
    return <LoadingInfo message="Setup Digital Twin ..." />
  }
  if (!projectId?.length) {
    console.error("Setup Twin. Invalid project id. ")
    return <ErrorInfo message="Invalid Project Id." />
  }
  if (error) {
    console.error("Failed to fetch urns:", error, error.info)
    const message = error.info.toString().includes("expired")
      ? "Failed to fetch Forge documents. Invalid or expired token."
      : "Failed to fetch Forge documents."
    return (
      <ErrorInfo message={message}>
        <SignInButton />
      </ErrorInfo>
    )
  }

  console.debug("Render ViewerWithDashboard")
  return (
    <>
      <Split
        className="split-horizontal"
        sizes={[62, 38]}
        expandToMin={false}
        gutterSize={10}
        gutterAlign="start"
        snapOffset={30}
        dragInterval={1}
        direction="horizontal"
        onDragEnd={props.onDragEnd}
      >
        <Viewer
          env="AutodeskProduction2"
          api="streamingV2_EU"
          models={models}
          getToken={getTokenForProject(accessToken, projectId)}
          onSetAggregatedView={props.onSetAggregatedView}
          onSetViewables={props.onSetViewables}
        />
        <Dashboard aggregatedView={props.aggregatedView} projectId={projectId} viewables={props.viewables} />
      </Split>
    </>
  )
}

function App() {
  const viewRef = useRef<Autodesk.Viewing.AggregatedView>()
  const [aggregatedView, setAggregatedView] = useState<Autodesk.Viewing.AggregatedView | undefined>()
  const handleSetAggregatedView = (view: Autodesk.Viewing.AggregatedView) => {
    viewRef.current = view
    setAggregatedView(view)
  }

  const [drawer, setDrawer] = useState<boolean>(false)
  const handleToggleDrawer = (open: boolean) => setDrawer(open)

  const [viewables, setViewables] = useState<Viewables | undefined>()
  const handleSetViewables = (viewables: Viewables) => setViewables(viewables)

  const handleDragEnd = (_sizes: number[]) => {
    // use a ref not a state
    // layout is rendered before initialized
    const viewer = viewRef.current?.viewer
    if (!viewer) return
    viewer.resize()
  }

  const Home = () => (
    <>
      <MsalProfile />
      <Auth0Profile />
    </>
  )

  const AuthAdmin = () => {
    const E = withAuthenticationRequired(Admin)
    return <E />
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="app">
          <AppBar onDrawerToggle={handleToggleDrawer} />

          <Routes>
            {/* <Route path="/" element={<>Home</>} /> */}
            <Route path="/" element={<Home />} />

            <Route path="/admin" element={<AuthAdmin />} />

            <Route path="/user" element={<User />} />

            <Route
              path="/viewer/:projectId"
              element={
                <>
                  <Navigation
                    aggregatedView={aggregatedView}
                    viewables={viewables}
                    open={drawer}
                    toggle={handleToggleDrawer}
                  />
                  <AuthenticatedTemplate>
                    <ViewerWithDashboard
                      onDragEnd={handleDragEnd}
                      onSetAggregatedView={handleSetAggregatedView}
                      onSetViewables={handleSetViewables}
                      aggregatedView={aggregatedView}
                      viewables={viewables}
                    />
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <div style={{ margin: "auto" }}>
                      <Block sx={{ fontSize: "96px" }} />
                    </div>
                  </UnauthenticatedTemplate>
                </>
              }
            />

            <Route path="*" element={<ErrorInfo message="Page Not Found." />} />
          </Routes>

          <Notification />
        </div>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App

import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
  DialogActions,
  Button,
  useTheme
} from "@mui/material"
import { useState, useEffect, useRef } from "react"
import { useFetchWithToken } from "../../Auth/Msal"
import { useSelection } from "../../features/selection/Selection"
import { toPropertySet, afterCharacter } from "../../Viewer/utils"
import { ParameterDeclaration } from "../ManageParametersTab/ParameterList"
import { Error as ErrorIcon } from "@mui/icons-material"
import { TextFieldBooted } from "../../utils/bootstrap"
import { useAppDispatch } from "../../app/hooks"
import { setNotification } from "../../features/notification/notificationSlice"
import { mutate } from "swr"
import { CheckedListBox } from "./ExportDialog"
import useParameterDeclarations from "../../useHooks/useParameterDeclarations"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

interface AddTemplate {
  projectId: string
  name: string
  propertyNames: string[]
  parameterIds: string[]
}

interface AddTemplateDialogProps {
  open: boolean
  projectId: string
  viewer: Viewer3D
  onCancel: () => void
  onSubmit: () => void
}

const AddTemplateDialog = (props: AddTemplateDialogProps) => {
  const { data: _parameters, loading, error } = useParameterDeclarations(props.projectId)
  const parameters = _parameters.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))

  const selection = useSelection()
  const [properties, setProperties] = useState<string[] | undefined>()

  // internal model properties
  const [checkedProperties, setCheckedProperties] = useState<string[]>([])
  // external custom properties/parameters
  const [checkedParameters, setCheckedParameters] = useState<ParameterDeclaration[]>([])

  const inputTemplateName = useRef<HTMLInputElement>()

  const theme = useTheme()
  const spacing = theme.spacing(1)
  const fetchWithToken = useFetchWithToken()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const dropCategory = (keyWithCategory: string) => afterCharacter(keyWithCategory, "/")
    toPropertySet(props.viewer, selection).then((ps) => {
      // propertyset uses keys in form of 'category/name'
      // in queries we only use name, so drop the category
      const properties = ps.getKeysWithCategories().map(dropCategory)
      const uniqueProperties = Array.from(new Set(properties)).sort()
      setProperties(uniqueProperties)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection])

  const handleCancel = () => props.onCancel()

  const makeDialog = (diagProps: { content: JSX.Element; customActions?: JSX.Element }) => {
    return (
      <Dialog fullWidth open={props.open} keepMounted={false}>
        <DialogTitle>New Template</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>{diagProps.content}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          {diagProps.customActions}
        </DialogActions>
      </Dialog>
    )
  }

  if (!properties || loading)
    return makeDialog({
      content: (
        <>
          Loading data for selection...
          <CircularProgress />
        </>
      )
    })

  if (error)
    return makeDialog({
      content: (
        <>
          <ErrorIcon />
          <Typography variant="subtitle1" color={theme.palette.error.light}>
            Failed to load data
          </Typography>
        </>
      )
    })

  const handleSubmit = async () => {
    const name = inputTemplateName.current?.value

    if (!name) {
      dispatch(setNotification({ status: "error", message: "Invalid template name." }))
      return
    }

    const template: AddTemplate = {
      projectId: props.projectId,
      name: name,
      propertyNames: checkedProperties,
      parameterIds: checkedParameters.map((p) => p.parameterId)
    }

    const url = `/api/twin/viewer/projects/${props.projectId}/templates`

    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(template)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate(url)
          dispatch(setNotification({ status: "success", message: "Template added." }))
          props.onSubmit()
        } else {
          console.error(response.statusText)
          dispatch(setNotification({ status: "error", message: "Failed to add template." }))
          props.onCancel()
        }
      })
      .catch((reason) => {
        console.error(reason)
        dispatch(setNotification({ status: "error", message: "Failed to add template." }))
        props.onCancel()
      })
  }

  return makeDialog({
    content: (
      <>
        <Paper elevation={0} style={{ margin: spacing }}>
          <TextFieldBooted id={"id"} inputRef={inputTemplateName} label={"Template Name"} defaultValue="New Template" />
        </Paper>
        <Paper elevation={0} style={{ margin: spacing }}>
          <Typography variant="h5">Revit Properties</Typography>
          <CheckedListBox items={properties} stringifyItem={(x: string) => x} setCheckedItems={setCheckedProperties} />
        </Paper>
        <Paper elevation={0} style={{ margin: spacing }}>
          <Typography variant="h5">Digital Twin Parameters</Typography>
          <CheckedListBox
            items={parameters}
            stringifyItem={(x: { name: string }) => x.name}
            setCheckedItems={setCheckedParameters}
          />
        </Paper>
      </>
    ),
    customActions: <Button onClick={handleSubmit}>Save</Button>
  })
}

export default AddTemplateDialog

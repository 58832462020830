/** Snackbar Notification */
import { Alert, Snackbar } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectNotification, setNotification } from "./notificationSlice"

export const Notification = () => {
  const notification = useAppSelector(selectNotification)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(setNotification({ status: "closed"}))
  }

  return (
    <>
      {notification.status !== "closed" && (
        <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
          <Alert severity={notification.status} onClose={handleClose}>{notification.message}</Alert>
        </Snackbar>
      )}
    </>
  )
}

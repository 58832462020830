/** Creat new parameter filter.
 * Run filter. Select elements.
 */
import { QueryBuilderMaterial } from "@react-querybuilder/material"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Stack,
  TextareaAutosize,
  TextField
} from "@mui/material"
import {
  Delete as DeleteIcon,
  PlayArrow as PlayArrowIcon,
  Save as SaveIcon,
  SaveAs as SaveAsIcon,
  SelectAll as SelectAllIcon,
  History as HistoryIcon
} from "@mui/icons-material"
import { useEffect, useRef, useState } from "react"
import { getBulkPropertyMap, select, Selections } from "../../Viewer/utils"
import { QueryBuilder, formatQuery, ValueSelectorProps, RuleGroupTypeAny } from "react-querybuilder"
import "react-querybuilder/dist/query-builder.scss"
import "./query-builder-overrides.css"

// MUI component imports
import DragIndicator from "@mui/icons-material/DragIndicator"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Input from "@mui/material/Input"
import ListSubheader from "@mui/material/ListSubheader"
import MenuItem from "@mui/material/MenuItem"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Select from "@mui/material/Select"
import Switch from "@mui/material/Switch"
import { mutate } from "swr"
import { useAppDispatch } from "../../app/hooks"
import { useFetchWithToken } from "../../Auth/Msal"
import { setNotification } from "../../features/notification/notificationSlice"
import {
  getExternalParametersMap,
  getParameterNamesFromQuery,
  Group,
  Query,
  runQuery,
  runQueryNoAttachments
} from "./query"
import ActionButton from "../ActionButton"
import { TextFieldBooted } from "../../utils/bootstrap"
import { DeleteDialog } from "../DeleteDialog"
import { fetchParametersWith } from "../ManageParametersTab/ParameterList"
import useUser from "../../useHooks/useUser"
import { HistoryDialog } from "../HistoryDialog"
import { resetHighlights, applyHighlight } from "../../Viewer/highlight"
import SplitButton, { NoColor } from "./ColorButton"
import Color from "color"
import { DtDocument, fetchElementsWithDocumentsWith, fetchFilterDocumentsWith } from "../../useHooks/useDocuments"
import { Filter } from "../../useHooks/useFilters"
import { uniq } from "lodash"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

// Assign the MUI components to an object
const muiComponents = {
  Button,
  Checkbox,
  DragIndicator,
  FormControl,
  FormControlLabel,
  Input,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextareaAutosize
}

const toBuilderQuery = (query: Query): RuleGroupTypeAny => query.data as RuleGroupTypeAny

const fromBuilderQuery = (builderQuery: RuleGroupTypeAny): Query => {
  const builderQueryString = formatQuery(builderQuery, { format: "json_without_ids", parseNumbers: false })
  const query: Group = JSON.parse(builderQueryString)
  return { version: "v1", data: query }
}

const operators = [
  { name: "is", label: "=" },
  { name: "includes", label: "includes" },
  { name: "starts with", label: "starts with" },
  { name: "matches", label: "matches" },
  { name: "lt", label: "<" },
  { name: "lte", label: "<=" },
  { name: "gte", label: ">=" },
  { name: "gt", label: ">" }
]

// array sum
export const sum = (arr: number[]): number => arr.reduce((x, y) => x + y, 0)

const emptyQuery: Query = { version: "v1", data: { combinator: "and", rules: [] } }

const FieldSelector = ({ className, handleOnChange, title, value }: ValueSelectorProps) => {
  return (
    <TextField
      variant="standard"
      className={className}
      title={title}
      value={value}
      onChange={(e) => handleOnChange(e.target.value)}
    />
  )
}

FieldSelector.displayName = "FieldSelector"

interface FilterSelectedProps {
  viewer: Viewer3D | undefined
  filter: Filter | undefined
  filters: Filter[]
  projectId: string
}

// Uses AddFilter for Add/Edit
// if an id is given then edited
interface AddFilter {
  projectId: string
  id: string | undefined
  name: string
  query: string
}

const FilterSelected = (props: FilterSelectedProps) => {
  const projectId = props.projectId
  const filters = props.filters

  const [filter, setFilter] = useState<Filter | undefined>()
  useEffect(() => setFilter(props.filter), [props.filter])

  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  // security
  const { data: user, isUser, isRead } = useUser(projectId)

  const [query, setQuery] = useState<Query>(emptyQuery)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<Selections | undefined>()
  const [openSaveFilterDialog, setOpenSaveFilterDialog] = useState<AddFilter | undefined>()
  const [openFilterHistoryDialog, setOpenFilterHistoryDialog] = useState<Filter | undefined>()

  // query flags
  const [noAttachmentFlag, setNoAttachmentFlag] = useState<boolean>(false)
  const toggleNoAttachmentFlag = (_event: any, checked: boolean) => setNoAttachmentFlag(checked)

  const fetchParameters = (name: string) => fetchParametersWith(fetchWithToken, projectId, name)

  const fetchFilterDocuments = (filterId: string): Promise<DtDocument[]> =>
    fetchFilterDocumentsWith(fetchWithToken, props.projectId, filterId)

  const fetchElementsWithDocuments = (elementIds: string[]): Promise<string[]> =>
    fetchElementsWithDocumentsWith(fetchWithToken, props.projectId, elementIds)

  const handleQueryRun = async () => {
    if (!props.viewer) return
    try {
      setLoading(true)

      if (noAttachmentFlag) {
        const result = await runQueryNoAttachments(
          fetchParameters,
          fetchFilterDocuments,
          fetchElementsWithDocuments,
          props.viewer,
          query,
          filters
        )
        setResult(result)
        return
      }
      const parameterNames = uniq(getParameterNamesFromQuery(query))

      const externalParameters = await getExternalParametersMap(parameterNames, fetchParameters)
      const internalParameters = await getBulkPropertyMap(
        props.viewer!.getAllModels(),
        {
          propFilter: parameterNames,
          needsExternalId: true,
          ignoreHidden: false
        },
        undefined,
        true
      )
      const result = await runQuery(externalParameters, internalParameters, props.viewer!, query)
      setResult(result)
    } catch (err) {
      console.error("Failed to run query", err)
      dispatch(setNotification({ status: "error", message: "Failed to run Query." }))
      setResult(undefined)
    } finally {
      setLoading(false)
    }
  }

  const handleApplyResult = () => {
    if (!props.viewer) return
    if (!result?.length) return
    console.debug("Filter Selection", result)
    select(props.viewer, result)
  }

  const handleApplyColor = (color: NoColor | Color) => {
    if (!props.viewer) return
    if (!result?.length) return
    resetHighlights(props.viewer)
    // @ts-ignore
    if (color.keyword() !== "nocolor") applyHighlight(props.viewer, { selections: result, color: color })
  }

  // save
  const handleOpenSaveFilterDialog = (filter: AddFilter) => setOpenSaveFilterDialog(filter)
  const handleCloseSaveFilterDialog = () => setOpenSaveFilterDialog(undefined)
  const handleSubmitSaveFilter = (filter: AddFilter | undefined) => {
    setOpenSaveFilterDialog(undefined)

    const url = `/api/twin/viewer/projects/${projectId}/filters`

    const method = filter?.id ? "PUT" : "POST"

    fetchWithToken(url, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(filter)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate(url)
          dispatch(setNotification({ status: "success", message: "Filter added." }))
        } else {
          dispatch(setNotification({ status: "error", message: "Failed to add Filter." }))
        }
      })
      .catch((reason) => {
        dispatch(setNotification({ status: "error", message: "Failed to add Filter." }))
      })
  }

  useEffect(() => {
    if (!filter) return
    const queryM = JSON.parse(filter?.query)
    if (queryM === null) {
      console.warn("Query is null", filter)
      return
    }

    setQuery(JSON.parse(filter?.query))
  }, [filter])

  // delete filter
  const [deleteFilter, setDeleteFilter] = useState<Filter | undefined>()
  const handleOpenDeleteFilterDialog = () => setDeleteFilter(filter)
  const handleCancelDeleteFilterDialog = () => setDeleteFilter(undefined)

  const handleSubmitDeleteFilterDialog = (_parameter: Filter | undefined) => {
    setDeleteFilter(undefined)
    setFilter(undefined)
  }

  return (
    <>
      <ActionButton startIcon={<PlayArrowIcon />} onClick={handleQueryRun} disabled={!query?.data.rules.length}>
        Run
      </ActionButton>
      <ActionButton startIcon={<SelectAllIcon />} onClick={handleApplyResult} disabled={!result?.length}>
        Select
      </ActionButton>
      <SplitButton onSelectColor={handleApplyColor} disabled={!result?.length} />
      <ActionButton
        startIcon={<SaveIcon />}
        onClick={() =>
          handleOpenSaveFilterDialog({
            projectId: props.projectId,
            id: filter?.id,
            //@ts-ignore
            name: filter?.name,
            query: JSON.stringify(query)
          })
        }
        disabled={!filter || !isUser || user?.userId !== filter?.azeUserId}
      >
        Save
      </ActionButton>
      <ActionButton
        startIcon={<SaveAsIcon />}
        onClick={() =>
          handleOpenSaveFilterDialog({
            projectId: props.projectId,
            id: undefined,
            name: filter?.name ?? "New Filter",
            query: JSON.stringify(query)
          })
        }
        disabled={!isUser}
      >
        Save As
      </ActionButton>
      <ActionButton
        startIcon={<DeleteIcon />}
        onClick={handleOpenDeleteFilterDialog}
        disabled={!filter || !isUser || user?.userId !== filter?.azeUserId}
      >
        Delete
      </ActionButton>

      <ActionButton
        startIcon={<HistoryIcon />}
        onClick={() => setOpenFilterHistoryDialog(filter)}
        disabled={!filter || !isRead}
      >
        History
      </ActionButton>

      {/* <Accordion TransitionProps={{ unmountOnExit: true }} style={{ margin: spacing }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
          <Typography variant="h6">Query Preview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextareaAutosize value={JSON.stringify(query)} minRows={10} style={{ width: "95%", margin: 10 }} />
        </AccordionDetails>
      </Accordion> */}

      {/* NOTE the MUI components in the react-query-builder use 'secondary' color from the muiTheme */}
      <QueryBuilderMaterial muiComponents={muiComponents}>
        <QueryBuilder
          query={toBuilderQuery(query)}
          operators={operators}
          controlElements={{ fieldSelector: FieldSelector }}
          showCombinatorsBetweenRules={true}
          resetOnFieldChange={true}
          onQueryChange={(q) => setQuery(fromBuilderQuery(q))}
        />
      </QueryBuilderMaterial>

      {/* query flags */}
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={noAttachmentFlag} onChange={toggleNoAttachmentFlag} />}
          label="Has No Attachments"
        />
      </FormGroup>

      {loading && <CircularProgress />}
      {/* <div style={{ maxWidth: "90%", overflowWrap: "break-word" }}>{JSON.stringify(result)}</div> */}
      {result?.length && (
        <div style={{ maxWidth: "90%", overflowWrap: "break-word" }}>{`Matched ${sum(
          result.map((r) => r.dbIds.length)
        )} elements in ${result.length} models`}</div>
      )}
      <SaveFilterDialog
        projectId={props.projectId}
        open={openSaveFilterDialog}
        onCancel={handleCloseSaveFilterDialog}
        onSubmit={handleSubmitSaveFilter}
      />
      <DeleteFilterDialog
        open={deleteFilter}
        onCancel={handleCancelDeleteFilterDialog}
        onSubmit={handleSubmitDeleteFilterDialog}
      />

      {openFilterHistoryDialog && (
        <HistoryDialog
          open={openFilterHistoryDialog}
          projectId={openFilterHistoryDialog.projectId}
          elementId={openFilterHistoryDialog.id}
          onClose={() => setOpenFilterHistoryDialog(undefined)}
        />
      )}
    </>
  )
}

interface SaveFilterDialogProps {
  projectId: string
  open: AddFilter | undefined
  onCancel: () => void
  onSubmit: (filter: AddFilter | undefined) => void
}

const SaveFilterDialog = (props: SaveFilterDialogProps) => {
  const query = props.open?.query
  const inputRefName = useRef<HTMLInputElement>()

  const handleCancel = () => props.onCancel()

  const handleSubmit = () => {
    const name = inputRefName.current?.value
    const filter: AddFilter | undefined =
      name?.length && query?.length && props.open?.projectId?.length
        ? {
            projectId: props.open.projectId,
            id: props.open.id,
            name: name,
            query: query
          }
        : undefined
    props.onSubmit(filter)
  }

  return (
    <Dialog open={props.open !== undefined}>
      <DialogTitle>Save Filter</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <TextFieldBooted id={"id"} label={"Project Id"} defaultValue={props.open?.projectId} disabled={true} />
          <TextFieldBooted
            id={"name"}
            inputRef={inputRefName}
            defaultValue={props.open?.name}
            label={"Name"}
            autoFocus={true}
            required={true}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

interface DeleteFilterDialogProps {
  open: Filter | undefined
  onCancel: () => void
  onSubmit: (parameter: Filter | undefined) => void
}

const DeleteFilterDialog = (props: DeleteFilterDialogProps) => {
  const message = (filter: Filter) => `Delete filter '${filter.name}' with id '${filter.id}'?`
  const url = (filter: Filter) => `/api/twin/viewer/projects/${filter.projectId}/filters`
  return (
    <DeleteDialog
      open={props.open}
      message={message}
      url={url}
      mutate={true}
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    />
  )
}

export default FilterSelected

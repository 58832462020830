import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"

import { Selection, setSelection, selectSelection } from "./selectionSlice"

//** Returns the Selection */
export const useSelection = (): Selection => {
  return useAppSelector(selectSelection)
}

//** Registers to AGGREGATE_SELECTION_CHANGED_EVENT. Updates 'Selection' in store. */
export const useSelectionEvent = (viewer: Autodesk.Viewing.Viewer3D | undefined) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!viewer) return
    let active = true

    const handleSelectionEvent = () => {
      const aggregateSelection = viewer.getAggregateSelection()
      const selection = aggregateSelection.map((sel) => ({ modelId: sel.model.id, dbIds: sel.selection }))
      dispatch(setSelection(selection))
    }

    if (active) {
      console.log("Add Selection_Changed_Event Listener")
      viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, handleSelectionEvent)
    }

    return () => {
      console.log("Remove Selection_Changed_Event Listener")
      viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, handleSelectionEvent)
      active = false
    }
  }, [dispatch, viewer])
}

import { useState } from "react"
import {
  Autocomplete,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  Stack,
  TextField,
  Typography,
  useTheme
} from "@mui/material"
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon
} from "@mui/icons-material"
import { AggregatedView, Viewables } from "../../Viewer/Viewer"
import { useFetchWithTokenSWR } from "../../Auth/Msal"
import useSWR from "swr"
import { SWRData } from "../../utils/swr"
import { ErrorInfo, LoadingInfo } from "../../Info"
import { Markup, setBCFCamera, setBCFCSelection, Snapshot, Viewpoint } from "./BCF"
import { applyViewState, fromViewStateString } from "../../Navigation/Views"
import { applyViewable } from "../../Navigation/Viewables"
import ActionButton from "../ActionButton"

export type IssueData = {
  markup: Markup
  viewpoint: Viewpoint
  viewstate: string | null
}

type IssueImage = {
  snapshot: Snapshot
}

export const useIssues = (projectId: string): SWRData<IssueData[]> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error, mutate } = useSWR<IssueData[]>(`/api/twin/viewer/projects/${projectId}/issues`, fetcher)
  return {
    data: data ?? [],
    loading: !error && !data,
    error,
    mutate
  }
}

export const useIssuesImage = (projectId: string, issueId: string): SWRData<IssueImage | undefined> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error } = useSWR<IssueImage>(`/api/twin/viewer/projects/${projectId}/issues/${issueId}/image`, fetcher)
  return {
    data: data ?? undefined,
    loading: !error && !data,
    error
  }
}

const clampString = (s: string, maxLen: number) => {
  return s.length <= maxLen ? s : `${s.substring(0, maxLen - 3)}...`
}

const Info = (props: { projectId: string; issueData: IssueData }) => {
  let markup = props.issueData.markup
  const { data: image, loading, error } = useIssuesImage(props.projectId, markup.guid)
  return (
    <>
      <Stack spacing={1} direction="row">
        {error && <ErrorInfo message="Failed to load Snapshot" />}
        {loading && <LoadingInfo message="Loading Snapshot" />}
        {image && <Image64 data={image?.snapshot} maxWidth="120px" />}
        <CardContent sx={{ flex: "1 1 auto" }}>
          <Typography>{markup.title}</Typography>
          <Typography color="text.secondary">{clampString(markup.description ?? "", 60)}</Typography>
          <div key={markup.guid} style={{ display: "flex", width: "100%" }}>
            <Typography color="text.secondary">{markup.kind}</Typography>
            <Typography color="text.secondary" sx={{ marginLeft: "auto" }}>
              {markup.status}
            </Typography>
          </div>
        </CardContent>
      </Stack>
    </>
  )
}

interface DetailsProps {
  projectId: string
  issueData: IssueData
}

const Details = (props: DetailsProps) => {
  const projectId = props.projectId
  const markup = props.issueData.markup
  const { data: image, loading, error } = useIssuesImage(projectId, markup.guid)

  let entries = Object.entries(markup).reduce((acc: JSX.Element[], [key, value]) => {
    if (typeof value === "string" && value?.length) {
      let entry = (
        <div key={key}>
          <Typography color="text.secondary" textTransform="capitalize">
            {key}
          </Typography>
          <Typography sx={{ marginLeft: 1 }}>{value}</Typography>
        </div>
      )
      acc.push(entry)
    }
    return acc
  }, [])
  return (
    <>
      {error && <ErrorInfo message="Failed to load Snapshot" />}
      {loading && <LoadingInfo message="Loading Snapshot" />}
      {image && <Image64 data={image?.snapshot} />}
      {entries}
    </>
  )
}

const Image64 = (props: { data: string; maxWidth?: string }) => (
  <CardMedia
    component="img"
    sx={{ maxWidth: props.maxWidth ?? "100%", height: "100%", margin: "auto 0 auto 0" }}
    image={`data:image/jpg;base64,${props.data}`}
    alt="snapshot"
  />
)

interface IssueFilterProps {
  label: string
  options: string[]
  values: string[]
  onChange: (values: string[]) => void
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const IssueFilter = (props: IssueFilterProps) => {
  return (
    <Autocomplete
      id={`issue-filter-autocomplete-${props.label}`}
      options={props.options}
      value={props.values}
      onChange={(_, newValues) => {
        props.onChange(newValues)
      }}
      multiple
      disablePortal
      disableCloseOnSelect
      sx={{ minWidth: 200 }}
      ChipProps={{ size: "small" }}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: 0, margin: 0 }}>
          <Checkbox size="small" icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={props.label} size="small" />}
    />
  )
}

export const distinctOptions = (xs: (string | null | undefined)[]) =>
  Array.from(new Set(xs))
    .filter((x) => x)
    .sort() as string[]

interface IssuesListProps {
  projectId: string
  aggregatedView: AggregatedView
  viewables: Viewables
  issues: IssueData[]
  onSelect: (issue: IssueData) => void
}

const IssuesList = (props: IssuesListProps) => {
  const theme = useTheme()
  const spacing = theme.spacing(1)

  let [selected, setSelected] = useState<string>()

  const [title, setTitle] = useState<string>("")
  const [kinds, setKinds] = useState<string[]>([])
  const [statuses, setStatuses] = useState<string[]>([])
  const [priorities, setPriorities] = useState<string[]>([])
  const [authors, setAuthors] = useState<string[]>([])
  const [assigned, setAssigned] = useState<string[]>([])

  const clearFilters = () => {
    setTitle("")
    setKinds([])
    setStatuses([])
    setPriorities([])
    setAuthors([])
    setAssigned([])
  }

  const issues = props.issues

  const handleClick = async (issue: IssueData) => {
    setSelected(issue.markup.guid)
    props.onSelect(issue)

    if (issue.viewstate?.length) {
      const viewstate = fromViewStateString(issue.viewstate)
      if (viewstate) {
        await applyViewState(props.aggregatedView, props.viewables, viewstate)
      } else {
        console.error("Failed to parse viewstate", viewstate)
        await applyViewable(props.aggregatedView, props.viewables.main[0].viewables[0])
        issue.viewpoint.camera && setBCFCamera(props.aggregatedView, issue.viewpoint.camera)
      }
    } else {
      await applyViewable(props.aggregatedView, props.viewables.main[0].viewables[0])
      issue.viewpoint.camera && setBCFCamera(props.aggregatedView, issue.viewpoint.camera)
    }
    issue.viewpoint.selections.length && setBCFCSelection(props.aggregatedView.viewer, issue.viewpoint.selections)
  }

  const toIssue = (issue: IssueData) => {
    return (
      <Card key={issue.markup.guid} sx={{ margin: spacing }}>
        <CardActionArea onClick={() => handleClick(issue)}>
          {issue.markup.guid === selected ? (
            <Details projectId={props.projectId} issueData={issue} />
          ) : (
            <Info projectId={props.projectId} issueData={issue} />
          )}
        </CardActionArea>
      </Card>
    )
  }

  const kindOptions = distinctOptions(issues.map((issue) => issue.markup.kind))
  const statusOptions = distinctOptions(issues.map((issue) => issue.markup.status))
  const priorityOptions = distinctOptions(issues.map((issue) => issue.markup.priority))
  const authorOptions = distinctOptions(
    issues.flatMap((issue) => [issue.markup.creationAuthor, issue.markup.modifiedAuthor])
  )
  const assignedOptions = distinctOptions(issues.map((issue) => issue.markup.assignedTo))

  const filteredIssues = issues.filter(
    (issue) =>
      (title.length === 0 || issue.markup.title.includes(title)) &&
      (kinds.length === 0 || kinds.includes(issue.markup.kind ?? "")) &&
      (statuses.length === 0 || statuses.includes(issue.markup.status ?? "")) &&
      (priorities.length === 0 || priorities.includes(issue.markup.priority ?? "")) &&
      (authors.length === 0 ||
        authors.includes(issue.markup.creationAuthor ?? "") ||
        authors.includes(issue.markup.modifiedAuthor ?? "")) &&
      (assigned.length === 0 || assigned.includes(issue.markup.assignedTo ?? ""))
  )

  const renderedIssues = filteredIssues.map(toIssue)

  return (
    <>
      <ActionButton startIcon={<ClearIcon />} onClick={clearFilters}>
        Clear Filters
      </ActionButton>
      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1} style={{ margin: spacing, padding: spacing }}>
        <TextField
          id="issue-filter-title"
          label="Title"
          size="small"
          sx={{ minWidth: 200 }}
          value={title}
          onChange={(event) => {
            setTitle(event.target.value)
          }}
        />
        <IssueFilter label="Kind" options={kindOptions} values={kinds} onChange={setKinds} />
        <IssueFilter label="Status" options={statusOptions} values={statuses} onChange={setStatuses} />
        <IssueFilter label="Priority" options={priorityOptions} values={priorities} onChange={setPriorities} />
        <IssueFilter label="Author" options={authorOptions} values={authors} onChange={setAuthors} />
        <IssueFilter label="Assigned To" options={assignedOptions} values={assigned} onChange={setAssigned} />
      </Stack>
      {renderedIssues}
    </>
  )
}

export default IssuesList

import { CircularProgress, TableCell, Typography, TableRow } from "@mui/material"
import { Error as ErrorIcon } from "@mui/icons-material"
import Tabular from "../FilterTab/Tabular"
import { useState } from "react"
import useParameterDeclarations from "../../useHooks/useParameterDeclarations"
import { ParameterInstance } from "../AssignParametersTab/AssignParameters"
import { useFetchWithToken } from "../../Auth/Msal"
import { Viewer3D } from "../../Viewer/Viewer"
import { useAppDispatch } from "../../app/hooks"
import { setNotification } from "../../features/notification/notificationSlice"

export interface ParameterDeclaration {
  projectId: string
  parameterId: string
  name: string
  datatype: string
  unit: string
  query: string
  azeUserId: string
  azeUserName: string
}

/** Fetch parameters with 'name'. Throws an error, if !response.ok */
export const fetchParametersWith = async (
  fetchWithToken: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  projectId: string,
  name: string
): Promise<ParameterInstance[]> => {
  if (!name?.length) return []
  const search = "?" + new URLSearchParams({ name: name }).toString()
  const url = `/api/twin/viewer/projects/${projectId}/parametersWithName` + search
  const response = await fetchWithToken(url)
  if (!response.ok) {
    console.error("Failed to fetch parameter.", response)
    throw Error("Failed to fetch Parameters")
  }
  const data = await response.json()
  return data
}

interface AssignedParameterListProps {
  projectId: string
  selection: string[] | undefined
  viewer: Viewer3D
  onSelect: (parameter: ParameterDeclaration) => void
}

export const AssignedParameterList = (props: AssignedParameterListProps) => {
  const elementIds = props.selection
  const [parameters, setParameters] = useState<ParameterDeclaration[] | undefined | "invalid">()
  const [selected, setSelected] = useState<string | undefined>()
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  if (elementIds !== undefined && parameters === undefined) {
    if (elementIds.length === 0) {
      setParameters([])
    } else {
      const url = `/api/twin/viewer/projects/${props.projectId}/assignedParameters`

      fetchWithToken(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ elementIds: elementIds })
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setParameters(json)
            })
          } else {
            setParameters("invalid")
            dispatch(setNotification({ status: "error", message: "Failed to fetch parameters." }))
            console.error(response.statusText)
          }
        })
        .catch((_) => {
          dispatch(setNotification({ status: "error", message: "Failed to fetch parameters." }))
        })
    }
  }

  if (parameters === "invalid") return <ErrorIcon />

  if (parameters === undefined || elementIds === undefined) return <CircularProgress />

  const toHeader = () =>
    ["Name", "Type", "User"].map((text) => (
      <TableCell key={text} onClick={() => setSelected(undefined)}>
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </TableCell>
    ))

  const handleSelect = (parameter: ParameterDeclaration) => {
    props.onSelect(parameter)
    setSelected(parameter.parameterId)
  }

  const toRow = (parameter: ParameterDeclaration) => {
    return (
      <>
        <TableRow
          key={parameter.parameterId}
          hover={true}
          selected={selected === parameter.parameterId}
          onClick={() => handleSelect(parameter)}
        >
          <TableCell>{parameter.name}</TableCell>
          <TableCell>{parameter.datatype}</TableCell>
          <TableCell>{parameter.azeUserName}</TableCell>
        </TableRow>
      </>
    )
  }

  const toRows = () => parameters.map(toRow)

  return <Tabular toHeader={toHeader} toRows={toRows} />
}

interface ParameterListProps {
  projectId: string
  onSelect: (parameter: ParameterDeclaration | undefined) => void
}

const ParameterList = (props: ParameterListProps) => {
  const { data: parameters, loading, error } = useParameterDeclarations(props.projectId)
  const [selected, setSelected] = useState<string | undefined>()

  if (loading) return <CircularProgress />
  if (error) return <ErrorIcon />

  const toHeader = () =>
    ["Name", "Type", "User"].map((text) => (
      <TableCell key={text} onClick={() => handleSelect(undefined)}>
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </TableCell>
    ))

  const handleSelect = (parameter: ParameterDeclaration | undefined) => {
    setSelected((old) => {
      const newer = parameter?.parameterId === old ? undefined : parameter
      props.onSelect(newer)
      return newer?.parameterId
    })
  }

  // const toRow = (parameter: Parameter) => (
  //   <PRow parameter={parameter} onClick={() => handleClick(parameter)} onDelete={handleDelete} selected={selected} />
  // )

  const toRow = (parameter: ParameterDeclaration) => {
    return (
      <>
        <TableRow
          key={parameter.parameterId}
          hover={true}
          selected={selected === parameter.parameterId}
          onClick={() => handleSelect(parameter)}
        >
          <TableCell>{parameter.name}</TableCell>
          <TableCell>{parameter.datatype}</TableCell>
          <TableCell>{parameter.azeUserName}</TableCell>
        </TableRow>
      </>
    )
  }

  const toRows = () => parameters.map(toRow)

  return <Tabular toHeader={toHeader} toRows={toRows} />
}

export default ParameterList

import { LoadingButton } from "@mui/lab"
import { Button } from "@mui/material"

const ActionButton = (props: {
  startIcon: React.ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
  disabled?: boolean
}) => (
  <Button
    style={{ margin: 10 }}
    size="small"
    variant="contained"
    startIcon={props.startIcon}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.children}
  </Button>
)

/** Button with loading indicator. */
export const ActionLoadingButton = (props: {
  startIcon: React.ReactNode
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
  loading: boolean
  disabled?: boolean
}) => (
  <LoadingButton
    style={{ margin: 10 }}
    size="small"
    variant="contained"
    loading={props.loading}
    startIcon={props.startIcon}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.children}
  </LoadingButton>
)

export default ActionButton

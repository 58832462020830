//** Use component to fetch admin projects. */
import useSWR from "swr"
import { useFetchWithTokenSWR } from "../Auth/Auth0"
import { SWRData } from "../utils/swr"
import { Project } from "./Admin"

export const useProjects = (): SWRData<Project[]> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error, mutate } = useSWR<Project[]>("/api/twin/admin/projects", fetcher)
  return {
    data: data ?? [],
    loading: !error && !data,
    error,
    mutate
  }
}

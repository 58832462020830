// Custom Property Panel
//
// Uses the Forge Panel to show 'Custom Parameters' and 'Attachments'. There is
//
// Note: There is a lot of duplication going on, since it is not a React component and
// useHooks can not used.
// Maybe it would be better to make a custom property extension with a React panel.

import { MsalClient } from "../Auth/Msal"
import { ParameterInstance } from "../Dashboard/AssignParametersTab/AssignParameters"
import { getExternalParametersMap, getParameterNamesFromFilter, Query, runQuery } from "../Dashboard/FilterTab/query"
import { fetchParametersWith } from "../Dashboard/ManageParametersTab/ParameterList"
import { DtDocument, AzureDocumentData } from "../useHooks/useDocuments"
import { Filter } from "../useHooks/useFilters"
import { getBulkPropertyMap, Selections, toParameterSelection } from "./utils"
import ViewerPropertyPanel = Autodesk.Viewing.Extensions.ViewerPropertyPanel
import GuiViewer3D = Autodesk.Viewing.GuiViewer3D
import { uniq } from "lodash"

// Revit Category is hidden by default.
// Add if category is the same for the selection.
// @ts-ignore
const addRevitCategory = (_this, props) => {
  const categories = props.map["__category__/Category"] as { displayValue: string }[]
  const sameCategory =
    categories?.length && categories.every((prop) => prop.displayValue === categories[0].displayValue)
  if (sameCategory) _this.addProperty("Category", categories[0].displayValue, "Revit")
}

// Revit ElementId is hidden by default.
// @ts-ignore
const addRevitElementId = (_this, props) => {
  const elementIds = props.map["__revit__/ElementId"] as { displayValue: string }[]
  if (elementIds?.length === 1) {
    const elementId = elementIds[0].displayValue
    _this.addProperty("ElementId", elementId, "Revit")
  }
}

//@ts-ignore
const addExternalProperties = async (_this) => {
  const viewer = _this.viewer
  const projectId = _this.viewer.projectId
  const selections = _this.getSelections()
  const parameters = await fetchExternalProperties(viewer, projectId, selections)
  for (const parameter of parameters)
    _this.addProperty(parameter.name, parameter.value + " " + parameter.unit, "Custom Parameters")
}

const fetchExternalProperties = async (
  viewer: Autodesk.Viewing.Viewer3D,
  projectId: string,
  selections: Selections
): Promise<ParameterInstance[]> => {
  if (selections.length !== 1 || selections[0].dbIds.length !== 1) return []

  const parameterSelection = await toParameterSelection(viewer, selections)
  const elementId = parameterSelection?.length ? parameterSelection[0] : undefined

  if (!elementId) return []

  const encodedElementId = encodeURIComponent(elementId)
  const url = `/api/twin/viewer/projects/${projectId}/parametersWithElementId/${encodedElementId}`
  const response = await MsalClient.fetch(url)
  if (response.status !== 200) {
    console.error("Failed to fetch parameters: ", url)
  }
  return response.json()
}

//@ts-ignore
const addAttachments = async (_this) => {
  const viewer = _this.viewer
  const projectId = _this.viewer.projectId
  const selections = _this.getSelections()
  const attachments = await fetchAttachments(viewer, projectId, selections)
  for (const attachment of attachments) _this.addProperty(attachment.name, attachment.url, "Attachments")
}

const fetchDocuments = async (projectId: string, filterId: string): Promise<DtDocument[]> => {
  const url = `/api/twin/viewer/projects/${projectId}/filters/${filterId}/documents`
  const response = await MsalClient.fetch(url)
  if (response.status !== 200) {
    console.error("Failed to fetch documents.")
  }
  return response.json()
}

const fetchElementDocuments = async (projectId: string, elementId: string): Promise<AzureDocumentData[]> => {
  const url = `/api/twin/viewer/projects/${projectId}/attachments/${encodeURIComponent(elementId)}/azureDocuments`
  const response = await MsalClient.fetch(url)
  if (!response.ok) {
    console.error("Failed to fetch documents.")
  }
  return response.json()
}

const fetchAzureDocuments = async (projectId: string, document: DtDocument): Promise<AzureDocumentData> => {
  const url = `/api/azure/${document.driveId}/document/${document.id}`
  const response = await MsalClient.fetch(url)
  if (response.status !== 200) {
    console.error("Failed to fetch azure documents.")
  }
  return response.json()
}

const fetchAttachments = async (
  viewer: Autodesk.Viewing.Viewer3D,
  projectId: string,
  selections: Selections
): Promise<AzureDocumentData[]> => {
  const url = `/api/twin/viewer/projects/${projectId}/filters`
  const response = await MsalClient.fetch(url)
  if (response.status !== 200) {
    console.error("Failed to fetch parameters: ", url)
  }
  const filters: Filter[] = await response.json()

  const fetchWithToken = async (input: RequestInfo, init?: RequestInit): Promise<Response> =>
    MsalClient.fetch(input, init)

  const fetchParameters = async (name: string): Promise<ParameterInstance[]> => {
    const parameters = await fetchParametersWith(fetchWithToken, projectId, name)
    if (!parameters) return []
    return parameters
  }

  const parameterNames = uniq(filters.flatMap(getParameterNamesFromFilter))
  const externalParameters = await getExternalParametersMap(parameterNames, fetchParameters)

  console.debug("External parameters", externalParameters.size)

  const internalParameters = await getBulkPropertyMap(
    viewer.getAllModels(),
    {
      propFilter: parameterNames,
      needsExternalId: true,
      ignoreHidden: false
    },
    selections,
    false
  )

  const usedFilters = await Promise.all(
    filters.map(async (filter) => {
      const query: Query = JSON.parse(filter.query)
      if (!query) return []
      const result = await runQuery(externalParameters, internalParameters, viewer, query, {
        selectionFilter: selections
      })
      return result.length ? filter : undefined
    })
  ).then((resultsM) => resultsM.filter((filter) => filter) as Filter[])

  console.debug("Used filters", usedFilters.length)

  const filterDocuments = await Promise.all(
    usedFilters.map(async (filter) => fetchDocuments(projectId, filter.id))
  ).then((documentss) => documentss.flatMap((documents) => documents))

  console.debug("Filter documents", filterDocuments.length)

  const filterAzureDocuments = await Promise.all(
    filterDocuments.map(async (document) => fetchAzureDocuments(projectId, document))
  )

  console.debug("Filter Azure documents", filterAzureDocuments.length)

  const parameterSelection = await toParameterSelection(viewer, selections)

  console.debug("Selection parameters", parameterSelection.length)

  const elementId = parameterSelection?.length ? parameterSelection[0] : undefined
  const elementAzureDocuments = elementId ? await fetchElementDocuments(projectId, elementId) : []

  console.debug("Azure documents", elementAzureDocuments.length)

  return [...elementAzureDocuments, ...filterAzureDocuments]
}

export default class PropertyPanel extends ViewerPropertyPanel {
  viewer: GuiViewer3D
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(viewer: GuiViewer3D) {
    super(viewer)
    this.viewer = viewer
  }

  getSelections(): Selections {
    // @ts-ignore
    const aggregateSelection = this.currentSelections
    // @ts-ignore
    const selections = aggregateSelection.map((sel) => ({ modelId: sel.model.id, dbIds: sel.selection }))
    return selections
  }

  // @ts-ignore
  displayProperty(property, parent, options) {
    const [attEl, valueEl] = super.displayProperty(property, parent, options)
    if (property.category === "Attachments" && property.value.startsWith("http")) {
      valueEl.innerHTML = `<a target="blank" class="propertyLink" href="${property.value}">${attEl.title}</a>`
    }
  }

  // @ts-ignore
  setProperties(props, options) {
    // @ts-ignore
    super.setProperties(props, options)
    addRevitCategory(this, props)
    addRevitElementId(this, props)
  }

  showDefaultProperties() {
    this.resizeToContent()
  }

  // removes a category
  removeCategory(name: string) {
    const loading = { name, type: "category" }
    // @ts-ignore
    var element = this.tree.getElementForNode(loading)
    if (element) {
      // @ts-ignore
      delete this.highlightableElements[this.tree.delegate().getTreeNodeId(loading)]
      element.parentNode.removeChild(element)
    }
  }

  // @ts-ignore
  setAggregatedProperties(props) {
    // async updates
    super.setAggregatedProperties(props)
    addRevitCategory(this, props)
    addRevitElementId(this, props)

    // simple loading indicator adding/removing category "Loading"
    this.addProperty("Loading", "Custom Parameters", "Loading")
    this.addProperty("Loading", "Attachments", "Loading")

    addExternalProperties(this).then(() => this.removeProperty("Loading", "Custom Parameters", "Loading"))
    addAttachments(this).then(() => {
      this.removeProperty("Loading", "Attachments", "Loading")
      this.removeCategory("Loading")
    })

    // sync variant
    // feels slow for elements without parameters/filters

    // const viewer = this.viewer
    // const projectId = this.viewer.projectId
    // const selections = this.getSelections()
    // fetchExternalProperties(viewer, projectId, selections).then((parameters) => {
    //   fetchAttachments(viewer, projectId, selections).then((attachments) => {
    //     super.setAggregatedProperties(props)
    //     for (const parameter of parameters) this.addProperty(parameter.name, parameter.value, "Custom Parameters")
    //     for (const attachment of attachments) this.addProperty(attachment.name, attachment.url ?? "", "Attachments")
    //     this.resizeToContent()
    //   })
    // })
  }
}

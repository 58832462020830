import { TextareaAutosize } from "@mui/base"
import { DragIndicator } from "@mui/icons-material"
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField
} from "@mui/material"
import { QueryBuilderMaterial } from "@react-querybuilder/material"
import QueryBuilder, { formatQuery, RuleGroupTypeAny, ValueSelectorProps } from "react-querybuilder"
import { Group, Query } from "./FilterTab/query"

// Assign the MUI components to an object
const muiComponents = {
  Button,
  Checkbox,
  DragIndicator,
  FormControl,
  FormControlLabel,
  Input,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextareaAutosize
}

const toBuilderQuery = (query: Query): RuleGroupTypeAny => query.data as RuleGroupTypeAny

const fromBuilderQuery = (builderQuery: RuleGroupTypeAny): Query => {
  const builderQueryString = formatQuery(builderQuery, { format: "json_without_ids", parseNumbers: false })
  const query: Group = JSON.parse(builderQueryString)
  return { version: "v1", data: query }
}

const operators = [
  { name: "is", label: "=" },
  { name: "includes", label: "includes" },
  { name: "starts with", label: "starts with" },
  { name: "matches", label: "matches" },
  { name: "lt", label: "<" },
  { name: "lte", label: "<=" },
  { name: "gte", label: ">=" },
  { name: "gt", label: ">" }
]

export const emptyQuery: Query = { version: "v1", data: { combinator: "and", rules: [] } }

const FieldSelector = ({ className, handleOnChange, title, value }: ValueSelectorProps) => {
  return (
    <TextField
      variant="standard"
      className={className}
      title={title}
      value={value}
      onChange={(e) => handleOnChange(e.target.value)}
    />
  )
}

FieldSelector.displayName = "FieldSelector"

const QueryComponent = (props: { query: Query; onChange: (_: Query) => void }) => {
  return (
    <QueryBuilderMaterial muiComponents={muiComponents}>
      <QueryBuilder
        query={toBuilderQuery(props.query)}
        operators={operators}
        controlElements={{ fieldSelector: FieldSelector }}
        showCombinatorsBetweenRules={true}
        resetOnFieldChange={true}
        onQueryChange={(q) => props.onChange(fromBuilderQuery(q))}
      />
    </QueryBuilderMaterial>
  )
}

export default QueryComponent

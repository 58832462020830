/** Fetch Forge model information for project. */
import useSWR from "swr"
import { useFetchWithTokenSWR } from "../Auth/Msal"
import { SWRData } from "../utils/swr"

export interface ModelData {
  name: string
  lastModified: string
  version: string
  urn: string
}

const useModels = (projectId: string | undefined): SWRData<ModelData[]> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error } = useSWR<ModelData[]>(
    projectId ? `/api/twin/viewer/projects/${projectId}/models` : null,
    fetcher
  )

  return {
    data: data ?? [],
    loading: !error && !data,
    error
  }
}

export default useModels

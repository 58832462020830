import { styled } from "@mui/material/styles"
import { InputBase, FormControl, InputLabel } from "@mui/material"
import { MutableRefObject } from "react"

// standard mui textfield is a bit 'noisy'
// use Bootstrap recipe from mui documentation

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3)
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))

export const TextFieldBooted = (props: {
  id: string
  inputRef?: MutableRefObject<HTMLInputElement | undefined>
  label: string
  defaultValue?: string
  disabled?: boolean
  required?: boolean
  autoFocus?: boolean
}) => {
  return (
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input" required={props.required ?? false}>
        {props.label}
      </InputLabel>
      <BootstrapInput
        id={props.id}
        inputRef={props.inputRef}
        defaultValue={props.defaultValue ?? ""}
        disabled={props.disabled ?? false}
        autoFocus={props.autoFocus ?? false}
      />
    </FormControl>
  )
}

/// Controlled component (use a React state for value, setValue). Will cause re-renders for every key-stroke I suppose.
export const TextFieldBootedControlled = (props: {
  id: string
  value: string
  setValue: (newValue: string) => void
  label: string
  disabled?: boolean
  required?: boolean
  autoFocus?: boolean
}) => {
  return (
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input" required={props.required ?? false}>
        {props.label}
      </InputLabel>
      <BootstrapInput
        id={props.id}
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
        disabled={props.disabled ?? false}
        autoFocus={props.autoFocus ?? false}
      />
    </FormControl>
  )
}

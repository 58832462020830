import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material"
import useExternalSelection from "../../useHooks/useExternalSelection"
import { TextFieldBooted } from "../../utils/bootstrap"
import { ParameterDeclaration } from "./ParameterList"
import { ParameterElements } from "./ManageParametersTab"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

interface SetParameterDialogProps {
  viewer: Viewer3D
  open: ParameterDeclaration | undefined
  projectId: string
  onCancel: () => void
  onSubmit: (parameter: ParameterElements | undefined) => void
}

export const SetParameterDialog = (props: SetParameterDialogProps) => {
  const externalSelection = useExternalSelection(props.viewer)

  const handleCancel = () => props.onCancel()

  const handleSubmit = () => {
    // should be defined if sbumit is rendered
    if (!externalSelection) return

    const elementIds = externalSelection.flatMap((sel) =>
      sel.externalIds.map((externalId) => `${sel.documentId}+${externalId}`)
    )

    const parameter =
      props.open && elementIds?.length
        ? {
            projectId: props.open?.projectId,
            id: props.open?.parameterId,
            elementIds
          }
        : undefined
    props.onSubmit(parameter)
  }

  const parameter = props.open

  // useEffect(() => {
  //   if (props.open !== undefined) setTimeout(() => inputValue.current?.focus(), 1000)
  // }, [props.open])

  if (!parameter) return null

  return (
    <Dialog open={props.open !== undefined}>
      <DialogTitle>Digital Twin Set Parameter</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <TextFieldBooted id={"projectId"} label={"Project Id"} defaultValue={props.projectId} disabled />
          <TextFieldBooted id={"parameterId"} label={"Parameter Id"} defaultValue={parameter.parameterId} disabled />
          <TextFieldBooted id={"name"} defaultValue={parameter.name} label={"Name"} disabled />
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Type
            </InputLabel>
            <ToggleButtonGroup color="primary" value={parameter.datatype} exclusive disabled style={{ marginTop: 24 }}>
              <ToggleButton value="text">Text</ToggleButton>
              <ToggleButton value="number">Number</ToggleButton>
              <ToggleButton value="boolean">Boolean</ToggleButton>
              <ToggleButton value="date">Date</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
          <TextFieldBooted id={"value"} defaultValue={"null"} label={"Value"} disabled />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={externalSelection === undefined}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import { ContentTypeInfo } from "@microsoft/microsoft-graph-types"
import { Grid, Typography, Box, Chip, styled } from "@mui/material"

interface ContentTypesProps {
  contentTypes: ContentTypeInfo[]
  activeChipsIndex: string[]
  onContentTypesClickedHandler: (chipIndex: string | undefined) => void
}

//generate spacing between chips
const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.25)
}))

export const ContentTypes = (props: ContentTypesProps) => {
  return (
    <>
      <Grid container spacing={2} height="Auto" alignItems="center">
        <Grid item xs={3}>
          <Typography variant="body2">Content Types</Typography>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.25,
              m: 0
            }}
            component="ul"
          >
            {props.contentTypes.map((cType, index) => {
              return (
                <ListItem key={index}>
                  <Chip
                    size={"small"}
                    color={props.activeChipsIndex.indexOf(cType.name!) === -1 ? "primary" : "secondary"}
                    clickable
                    label={cType.name!}
                    onClick={() => {
                      props.onContentTypesClickedHandler(cType.name!)
                    }}
                  />
                </ListItem>
              )
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

/** List/Create document attachments for a parameter filter.  */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  Button,
  TableCell,
  Typography,
  TableRow,
  IconButton
} from "@mui/material"
import { useState } from "react"
import { mutate } from "swr"
import { useAppDispatch } from "../../app/hooks"
import { useFetchWithToken } from "../../Auth/Msal"
import { setNotification } from "../../features/notification/notificationSlice"
import Tabular from "./Tabular"
import { AttachFile, Error as ErrorIcon, Launch, Remove } from "@mui/icons-material"
import { AzureDocument, DtDocument, useAzureDocumentsByFilter } from "../../useHooks/useDocuments"
import ActionButton from "../ActionButton"
import { AddDocumentDTO, AddDocumentDialog } from "../Documents/DocumentDialog"
import useUser from "../../useHooks/useUser"
import { Filter } from "../../useHooks/useFilters"

export const toLauncher = (url: string | undefined) =>
  url?.length ? (
    <IconButton href={url} target="_blank" rel="noopener noreferrer">
      <Launch />
    </IconButton>
  ) : (
    <IconButton disabled={true}>
      <Launch />
    </IconButton>
  )

interface DeleteDocument {
  projectId: string
  filterId: string
  id: string
}

interface DeleteDocumentDialogProps {
  open: DeleteDocument | undefined
  onCancel: () => void
  onSubmit: (document: DeleteDocument | undefined) => void
}

const DeleteDocumentDialog = (props: DeleteDocumentDialogProps) => {
  const document = props.open
  const handleCancel = () => props.onCancel()

  const handleSubmit = () => props.onSubmit(document)

  return (
    <Dialog open={props.open !== undefined}>
      <DialogTitle>Remove Filter</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>Remove document.</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

interface DocumentListProps {
  projectId: string
  filter: Filter
  // onClick: (_: Document) => void
}

const DocumentList = (props: DocumentListProps) => {
  const projectId = props.projectId
  const filter = props.filter
  const filterId = filter.id

  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  // security
  const { data: user, isUser } = useUser(projectId)

  // list
  const { data: azureDocuments, loading, error } = useAzureDocumentsByFilter(projectId, filterId)

  // selection
  const [selected, setSelected] = useState<DtDocument | undefined>()
  const handleSelect = (document: DtDocument | undefined) => () => setSelected(document)

  // add
  const [openAddDocumentDialog, setOpenAddDocumentDialog] = useState<boolean>(false)
  const handleOpenAddDocumentDialog = () => setOpenAddDocumentDialog(true)
  const handleCancelAddDocumentDialog = () => setOpenAddDocumentDialog(false)
  const handleSubmitAddDocumentDialog = (documentDTO: AddDocumentDTO | undefined) => {
    setOpenAddDocumentDialog(false)

    if (!documentDTO) {
      dispatch(setNotification({ status: "error", message: "Failed to add document." }))
      console.error("Trying to submit empty document")
      return
    }

    const document = { ...documentDTO, filterId: filterId }

    const url = `/api/twin/viewer/projects/${projectId}/filters/${filterId}/documents`

    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(document)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate(`/api/twin/viewer/projects/${projectId}/filters/${filterId}/azureDocuments`)
          dispatch(setNotification({ status: "success", message: "Document(s) added." }))
        } else {
          dispatch(setNotification({ status: "error", message: "Failed to add document." }))
        }
      })
      .catch((reason) => {
        dispatch(setNotification({ status: "error", message: "Failed to add document." }))
      })
  }

  // delete

  // delete
  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] = useState<DeleteDocument | undefined>()
  const handleOpenDeleteDocumentDialog = () =>
    setOpenDeleteDocumentDialog(selected ? { projectId: projectId, filterId: filterId, id: selected.id } : undefined)
  const handleCloseDeleteDocumentDialog = () => setOpenDeleteDocumentDialog(undefined)

  const handleSubmitDeleteDocument = (document: DeleteDocument | undefined) => {
    setOpenDeleteDocumentDialog(undefined)
    if (!document) return
    console.debug("Delete document", document)

    const url = `/api/twin/viewer/projects/${projectId}/filters/${filterId}/documents`

    fetchWithToken(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(document)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate(url)
          dispatch(setNotification({ status: "success", message: "Document removed." }))
        } else {
          console.warn("Failed to delete document.")
          dispatch(setNotification({ status: "error", message: "Failed to remove document." }))
        }
      })
      .catch((reason) => {
        console.warn("Failed to delete document: ", reason)
        dispatch(setNotification({ status: "error", message: "Failed to remove document." }))
      })
  }

  if (loading) return <CircularProgress />
  if (error) return <ErrorIcon />

  const toHeader = () =>
    ["Name"].map((text) => (
      <TableCell key={text} onClick={handleSelect(undefined)}>
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </TableCell>
    ))

  const toRow = (document: AzureDocument) => (
    <TableRow key={document.id} hover={true} selected={document.id === selected?.id} onClick={handleSelect(document)}>
      <TableCell>
        {toLauncher(document.url)}
        {document.name}
      </TableCell>
    </TableRow>
  )

  const toRows = () => azureDocuments.map(toRow)

  return (
    <>
      <ActionButton
        startIcon={<AttachFile />}
        onClick={handleOpenAddDocumentDialog}
        disabled={!isUser || user?.userId !== filter.azeUserId}
      >
        Attach
      </ActionButton>
      <ActionButton
        startIcon={<Remove />}
        onClick={handleOpenDeleteDocumentDialog}
        disabled={!selected || !isUser || user?.userId !== filter.azeUserId}
      >
        Remove
      </ActionButton>
      <Tabular toHeader={toHeader} toRows={toRows} />
      {openAddDocumentDialog && (
        <AddDocumentDialog
          projectId={projectId}
          open={openAddDocumentDialog}
          onCancel={handleCancelAddDocumentDialog}
          onSubmit={handleSubmitAddDocumentDialog}
        />
      )}
      {openDeleteDocumentDialog && (
        <DeleteDocumentDialog
          open={openDeleteDocumentDialog}
          onCancel={handleCloseDeleteDocumentDialog}
          onSubmit={handleSubmitDeleteDocument}
        />
      )}
    </>
  )
}

export default DocumentList

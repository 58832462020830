import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Selections } from "../../Viewer/utils"

export type Selection = Selections

const initialState: Selection = []

export const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setSelection: (state, action: PayloadAction<Selection>) => {
      const selection = action.payload
      console.debug("Selection: ", selection)
      return selection
    }
  }
})

export const { setSelection } = selectionSlice.actions

export const selectSelection = (state: RootState): Selection => state.selection

export default selectionSlice.reducer

/** Single and Bulk edit for custom parameters. */
//
// Treatsingle and bulk edit separately (since we query all existing parameters for bulk parameters to compute a diff).
import { Tune } from "@mui/icons-material"
import { CircularProgress, Paper, TableCell, TableRow, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { mutate } from "swr"
import { useAppDispatch } from "../../app/hooks"
import { useFetchWithToken } from "../../Auth/Msal"
import { setNotification } from "../../features/notification/notificationSlice"
import { AssignedParameterList, ParameterDeclaration } from "../ManageParametersTab/ParameterList"
import { Error as ErrorIcon } from "@mui/icons-material"

import { SetParameterDialog } from "./SetParameterValueDialog"
import ActionButton from "../ActionButton"
import useUser from "../../useHooks/useUser"

import { Selections } from "../../Viewer/utils"
import Tabular from "../FilterTab/Tabular"
import { useSelection } from "../../features/selection/Selection"
import { useExternalProperties } from "../../useHooks/useProperties"
import useParameterSelection from "../../useHooks/useParameterSelection"
import { Information } from "../../Info"
import ElementDocuments from "../Attachments/ElementDocumentPanel"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

export interface ParameterInstance {
  projectId: string
  parameterId: string
  elementId: string
  name: string
  datatype: string
  unit: string
  value: string
  azeUserId: string
  azeUserName: string
}

interface ParameterTabProps {
  viewer: Viewer3D
  projectId: string
}

export interface UpdateParameter {
  projectId: string
  id: string
  values: [string, string][] // elementId, value
}

const ParameterInstanceList = (props: {
  viewer: Viewer3D
  projectId: string
  selection: Selections
  onSelect: (parameter: ParameterInstance) => void
}) => {
  const { data: parameters, loading, error } = useExternalProperties(props.viewer, props.projectId, props.selection)

  const [selected, setSelected] = useState<string | undefined>()

  if (loading) return <CircularProgress />
  if (error) return <ErrorIcon />
  if (props.selection.length !== 1 || props.selection[0].dbIds.length !== 1)
    return <Information compact message="Please select exactly one element." />

  const toHeader = () =>
    ["Name", "Type", "Value"].map((text) => (
      <TableCell key={text} onClick={() => setSelected(undefined)}>
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </TableCell>
    ))

  const handleSelect = (parameter: ParameterInstance) => {
    props.onSelect(parameter)
    setSelected(parameter.parameterId)
  }

  const toRow = (parameter: ParameterInstance) => {
    return (
      <TableRow
        key={parameter.parameterId}
        hover={true}
        selected={selected === parameter.parameterId}
        onClick={() => handleSelect(parameter)}
      >
        <TableCell>{parameter.name}</TableCell>
        <TableCell>{parameter.datatype}</TableCell>
        <TableCell>{parameter.value}</TableCell>
      </TableRow>
    )
  }

  const toRows = () => parameters.map(toRow)

  return <Tabular toHeader={toHeader} toRows={toRows} />
}

const SingleEdit = (props: ParameterTabProps) => {
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  const projectId = props.projectId

  const selection = useSelection()

  // selected parameter
  const [selectedParameter, setSelectedParameter] = useState<ParameterInstance | undefined>()
  const handleSelectParameter = (parameter: ParameterInstance) => setSelectedParameter(parameter)

  // set parameter values
  const [parameterToSet, setParameterToSet] = useState<ParameterInstance | undefined>(undefined)
  const handleOpenSetParameterDialog = () => setParameterToSet(selectedParameter)

  const handleCancelDialog = () => {
    setParameterToSet(undefined)
  }

  // security
  const { isUser } = useUser(projectId)

  const handleSubmitSetParameterValue = (updateParameter: UpdateParameter | undefined) => {
    setParameterToSet(undefined)
    const successMessage = "Parameter value set"
    const errorMessage = "Failed to set Parameter value"

    if (!updateParameter) {
      dispatch(setNotification({ status: "error", message: errorMessage }))
      return
    }

    const parameter =
      updateParameter.values.length === 1
        ? {
            projectId: updateParameter.projectId,
            id: updateParameter.id,
            elementId: updateParameter.values[0][0],
            value: updateParameter.values[0][1]
          }
        : undefined

    if (!parameter) {
      dispatch(setNotification({ status: "error", message: errorMessage }))
      return
    }

    const encodedElementId = encodeURIComponent(parameter.elementId)
    const url = `/api/twin/viewer/projects/${projectId}/parametersWithId/${parameter.id}/withElementId/${encodedElementId}`

    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(parameter)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate(`/api/twin/viewer/projects/${projectId}/parametersWithId/${parameter.id}`)
          mutate(`/api/twin/viewer/projects/${projectId}/parametersWithElementId/${encodedElementId}`)
          dispatch(setNotification({ status: "success", message: successMessage }))
        } else {
          dispatch(setNotification({ status: "error", message: errorMessage }))
        }
      })
      .catch((_reason) => {
        dispatch(setNotification({ status: "error", message: errorMessage }))
      })
  }

  return (
    <>
      <Typography variant="h5" sx={{ color: "primary.text" }}>
        Single Edit Parameter
      </Typography>

      <ActionButton
        startIcon={<Tune />}
        onClick={handleOpenSetParameterDialog}
        disabled={!selectedParameter || !isUser}
      >
        Set
      </ActionButton>

      <ParameterInstanceList
        viewer={props.viewer}
        projectId={projectId}
        onSelect={handleSelectParameter}
        selection={selection}
      />
      <SetParameterDialog
        viewer={props.viewer}
        projectId={projectId}
        open={parameterToSet}
        onCancel={handleCancelDialog}
        onSubmit={handleSubmitSetParameterValue}
      />
    </>
  )
}

const BulkEdit = (props: ParameterTabProps) => {
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  const projectId = props.projectId

  const elementIds = useParameterSelection(props.viewer)
  const [activated, setActivated] = useState<boolean>()

  // selected parameter
  const [selectedParameter, setSelectedParameter] = useState<ParameterDeclaration | undefined>()
  const handleSelectParameter = (parameter: ParameterDeclaration) => setSelectedParameter(parameter)

  // set parameter values
  const [parameterToSet, setParameterToSet] = useState<ParameterDeclaration | undefined>(undefined)
  const handleOpenSetParameterDialog = () => setParameterToSet(selectedParameter)

  const handleCancelDialog = () => {
    setParameterToSet(undefined)
  }

  // security
  const { isUser } = useUser(projectId)

  useEffect(() => {
    if (activated) setActivated(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementIds])

  const handleSubmitSetParameterValue = (updateParameter: UpdateParameter | undefined) => {
    setParameterToSet(undefined)
    const errorMessage = "Failed to set Parameter value"

    if (!updateParameter) {
      dispatch(setNotification({ status: "error", message: errorMessage }))
      return
    }

    if (!updateParameter.values.length) {
      dispatch(setNotification({ status: "success", message: `Updated ${0} parameter values.` }))
      return
    }

    const url = `/api/twin/viewer/projects/${props.projectId}/parametersWithId/${updateParameter.id}`

    const updateParameterMapping = {
      projectId: updateParameter.projectId,
      id: updateParameter.id,
      values: Object.fromEntries(updateParameter.values)
    }

    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(updateParameterMapping)
    })
      .then(async (response) => {
        if (response.status === 200) {
          const count: number = await response.json()
          dispatch(setNotification({ status: "success", message: `Updated ${count} parameter values.` }))
        } else {
          dispatch(setNotification({ status: "error", message: "Failed to update parameter values." }))
        }
      })
      .catch((_reason) => {
        dispatch(setNotification({ status: "error", message: "Failed to update parameter values." }))
      })
  }

  const titleComponent = (
    <Typography variant="h5" sx={{ color: "primary.text" }}>
      Bulk Edit Parameter
    </Typography>
  )

  if (!activated) {
    return (
      <>
        {titleComponent}
        <ActionButton startIcon={<Tune />} onClick={() => setActivated(true)}>
          Load Parameters
        </ActionButton>
      </>
    )
  }

  return (
    <>
      {titleComponent}

      <ActionButton
        startIcon={<Tune />}
        onClick={handleOpenSetParameterDialog}
        disabled={!selectedParameter || !isUser}
      >
        Set
      </ActionButton>

      <AssignedParameterList
        projectId={projectId}
        viewer={props.viewer}
        onSelect={handleSelectParameter}
        selection={elementIds}
      />
      <SetParameterDialog
        viewer={props.viewer}
        projectId={projectId}
        open={parameterToSet}
        onCancel={handleCancelDialog}
        onSubmit={handleSubmitSetParameterValue}
      />
    </>
  )
}

const AssignParametersTab = (props: ParameterTabProps) => {
  const theme = useTheme()
  const spacing = theme.spacing(1)

  return (
    <>
      <Paper style={{ margin: spacing }}>
        <SingleEdit viewer={props.viewer} projectId={props.projectId} />
      </Paper>
      <Paper style={{ margin: spacing }}>
        <BulkEdit viewer={props.viewer} projectId={props.projectId} />
      </Paper>
      <Paper style={{ margin: spacing }}>
        <ElementDocuments viewer={props.viewer} projectId={props.projectId} />
      </Paper>
    </>
  )
}

export default AssignParametersTab

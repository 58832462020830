import { Auth0P } from "./Auth0"
import { MsalP } from "./Msal"

//** Authprovider for Auth0 and Msal. */
export const AuthProvider = (props: { children: JSX.Element }) => {
  return (
    <MsalP>
      <Auth0P>{props.children}</Auth0P>
    </MsalP>
  )
}

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import notificationReducer from "../features/notification/notificationSlice"
import selectionReducer from "../features/selection/selectionSlice"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    notification: notificationReducer,
    selection: selectionReducer
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

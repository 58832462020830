import { ColumnDefinition } from "@microsoft/microsoft-graph-types"
import { AutocompleteChangeDetails } from "@mui/material"
import { Field, SharepointData } from "../../useHooks/useDocuments"
import { getTree, TreeNode } from "../../utils/pathListToTree"
import { SearchOrChipSelect } from "./MetaDataColumns"
import { MetaDataColumns } from "./MetaDataColumns"

export interface MetaDataFilterProps {
  azureDocuments: SharepointData[]
  filteredColumns: ColumnDefinition[]
  activeChipsIndex: string[]
  selectedMetaDataFields: Field[]
  onMetaDataChanged: (
    filteredTree: TreeNode[],
    newActiveChipsIndex: string[],
    newSelectedMetaDataFields: Field[]
  ) => void
}

export const MetaDataFilter = (props: MetaDataFilterProps) => {
  const allLinks = props.azureDocuments.flatMap((document) => document._Links)
  const allFields = allLinks.flatMap((link) => link.fields)

  //handler for metadata (click on chips or autocomplete-search changed)
  const onMetaDataChangedHandler = (
    fields: (Field | undefined)[],
    searchOrChip: SearchOrChipSelect,
    index: string | undefined,
    removeOption: AutocompleteChangeDetails<Field | undefined> | undefined
  ) => {
    const tmpSelectedMetaDataFields = [...props.selectedMetaDataFields]
    const tmpChipsIndex = [...props.activeChipsIndex]

    //if fields are removed from search field Autocopmplete returns a "removeOption" and we get the removed field
    if (removeOption && searchOrChip === SearchOrChipSelect.Search)
      tmpSelectedMetaDataFields.splice(tmpSelectedMetaDataFields.indexOf(removeOption.option!), 1)

    //when search changed we only add the new selected fields to the array
    if (!removeOption && searchOrChip === SearchOrChipSelect.Search)
      fields.forEach((field) => {
        if (field && tmpSelectedMetaDataFields.indexOf(field) === -1) tmpSelectedMetaDataFields.push(field)
      })

    if (!removeOption && searchOrChip === SearchOrChipSelect.Chip && index) {
      //change color of Chips when clicked
      props.activeChipsIndex.indexOf(index) === -1
        ? tmpChipsIndex.push(index)
        : tmpChipsIndex.splice(tmpChipsIndex.indexOf(index), 1)
      fields.forEach((field) => {
        if (field)
          tmpSelectedMetaDataFields.indexOf(field) === -1
            ? tmpSelectedMetaDataFields.push(field)
            : tmpSelectedMetaDataFields.splice(tmpSelectedMetaDataFields.indexOf(field), 1)
      })
    }

    //when no MetadataChip is selected we go back to the initialTree
    //check all driveitems if selected field-value exists and return the ids as array
    if (tmpSelectedMetaDataFields.length === 0 || fields.length === 0) {
      props.onMetaDataChanged(getTree(props.azureDocuments), tmpChipsIndex, tmpSelectedMetaDataFields)
    } else {
      var selectedItemIds: string[] = []
      if (tmpSelectedMetaDataFields.length > 0) {
        tmpSelectedMetaDataFields.forEach((field) =>
          allLinks.forEach((link) => {
            link.fields?.forEach((existingField) => {
              if (existingField.value === field.value && selectedItemIds.indexOf(link.id) === -1)
                selectedItemIds.push(link.id)
            })
          })
        )
      }

      //get new sharepointdata from selected / filtered driveItemIds for each sharepoint document(library) to generate a new folder-tree
      var filteredDocuments: SharepointData[] = []
      if (selectedItemIds.length > 0) {
        props.azureDocuments.forEach((document) => {
          filteredDocuments.push({
            libraryName: document.libraryName,
            libraryId: document.libraryId,
            _Links: allLinks,
            allItems: document.allItems.filter(
              (item) => selectedItemIds.indexOf(item.id!) >= 0 || item.folder !== null
            ),
            allColumns: []
          })
        })

        const newTree = getTree(filteredDocuments)
        props.onMetaDataChanged(newTree, tmpChipsIndex, tmpSelectedMetaDataFields)
      }
    }
  }

  return (
    <>
      {props.filteredColumns.map((column, index) => {
        const fields = allFields.filter((field) => field?.key === column.displayName)
        return (
          <MetaDataColumns
            key={index}
            column={column}
            title={column.displayName!}
            fields={fields}
            activeChipsIndex={props.activeChipsIndex}
            onMetaDataChangedHandler={onMetaDataChangedHandler}
          />
        )
      })}
    </>
  )
}

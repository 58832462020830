import { useEffect, useState } from "react"
import { useSelection } from "../features/selection/Selection"
import { ExternalSelection, toExternalSelection } from "../Viewer/utils"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

/** Use component for external selection. */
// NOTE: Needs to call model.getProperties which can take some time.
const useExternalSelection = (viewer: Viewer3D): ExternalSelection | undefined => {
  const selections = useSelection()
  const [externalSelection, setExternalSelection] = useState<ExternalSelection | undefined>()

  useEffect(() => {
    setExternalSelection(undefined)
    const updateExternalSelection = async () => {
      const newExternalSelection = await toExternalSelection(viewer, selections)
      setExternalSelection(newExternalSelection)
    }
    updateExternalSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections])

  return externalSelection
}

export default useExternalSelection

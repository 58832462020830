// Dump Parameters for Revit Import
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from "@mui/material"
import { useState } from "react"
import { Check as CheckIcon, Download as DownloadIcon } from "@mui/icons-material"
import { ErrorInfo, LoadingInfo } from "../../Info"
import { useFetchWithToken } from "../../Auth/Msal"
import { Viewer3D } from "../../Viewer/Viewer"
import { getDocumentId, getLinkedModels, getModelName } from "../../Viewer/utils"

interface DumpDialogProps {
  open: boolean
  projectId: string
  viewer: Viewer3D
  onCancel: () => void
  onSubmit: () => void
}

const DumpRevitParametersDialog = (props: DumpDialogProps) => {
  const fetchWithToken = useFetchWithToken()
  const [data, setData] = useState<undefined | "preparing" | "error" | Blob>(undefined)

  const handleCancel = () => props.onCancel()

  const makeDialog = (diagProps: { content: JSX.Element; customActions?: JSX.Element }) => {
    return (
      <Dialog fullWidth open={props.open} keepMounted={false}>
        <DialogTitle>Export Parameters for Revit</DialogTitle>
        <DialogContent sx={{ minWidth: 400 }}>{diagProps.content}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          {diagProps.customActions}
        </DialogActions>
      </Dialog>
    )
  }

  if (data === "preparing")
    return makeDialog({
      content: <LoadingInfo message="Preparing data for download. This may take some time." />
    })

  if (data === "error")
    return makeDialog({
      content: <ErrorInfo message="Failed to prepare data." />
    })

  if (data) {
    const handleDownload = () => {
      const dataURL = window.URL.createObjectURL(data)

      const tempLink = document.createElement("a")
      tempLink.href = dataURL
      tempLink.setAttribute("data", "prameters.json")
      tempLink.download = "parameters.json"
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)

      props.onSubmit()
    }
    return makeDialog({
      content: (
        <>
          <CheckIcon />
          <Typography variant="body1">Data ready.</Typography>
        </>
      ),
      customActions: (
        <Button startIcon={<DownloadIcon />} onClick={handleDownload}>
          Download
        </Button>
      )
    })
  }

  const handleSubmit = async () => {
    setData("preparing")
    try {
      // The parameter export is prepared in the backend.
      // Provide the doucment name - id mapping here.
      const models = await Promise.all(
        props.viewer.getAllModels().map(async (model) => {
          let documentId = getDocumentId(model)
          let name = getModelName(model)
          let linkedModels = await getLinkedModels(model)
          return { ...documentId, name, linkedModels }
        })
      )
      console.log(">>> MODELS", models)
      const url = `/api/twin/viewer/projects/${props.projectId}/parameters/download`
      const response = await fetchWithToken(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(models)
      })
      if (!response.ok) {
        console.error("Export Parameters: Failed to fetch data:", response)
        setData("error")
        return
      }
      const blob = await response.blob()
      setData(blob)
    } catch (err) {
      console.error("Failed to prepare parameter data for download.", err)
      setData("error")
    }
  }

  return makeDialog({
    content: (
      <>
        Download the parameter table for Revit import. The export depends on the loaded models. To export all data,
        select the 'main' model.
      </>
    ),
    customActions: <Button onClick={handleSubmit}>Submit</Button>
  })
}

export default DumpRevitParametersDialog

import { Typography, CircularProgress, TableCell, TableRow } from "@mui/material"
import { useState } from "react"

import { useFetchWithTokenSWR } from "../../Auth/Msal"
import { SWRData } from "../../utils/swr"
import useSWR from "swr"
import { Error as ErrorIcon } from "@mui/icons-material"
import Tabular from "../FilterTab/Tabular"

export interface TemplateDeclaration {
  projectId: string
  templateId: string
  name: string
  azeUserId: string
  azeUserName: string
}

export const useTemplates = (projectId: string): SWRData<TemplateDeclaration[]> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error, mutate } = useSWR<TemplateDeclaration[]>(
    `/api/twin/viewer/projects/${projectId}/templates`,
    fetcher
  )
  return {
    data: data ?? [],
    loading: !error && !data,
    error,
    mutate
  }
}

export interface Template {
  projectId: string
  templateId: string
  name: string
  propertyNames: string[]
  parameterIds: string[]
  azeUserId: string
  azeUserName: string
}

export const useTemplate = (template: TemplateDeclaration | undefined): SWRData<Template | undefined> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error } = useSWR<Template>(
    template ? `/api/twin/viewer/projects/${template.projectId}/templates/${template.templateId}` : null,
    fetcher
  )
  return {
    data: data ?? undefined,
    loading: !error && !data && template !== undefined,
    error
  }
}

interface TemplateProps {
  projectId: string
  onSelect: (template: TemplateDeclaration | undefined) => void
}

const Templates = (props: TemplateProps) => {
  const { data: templates, loading, error } = useTemplates(props.projectId)
  const [selected, setSelected] = useState<string | undefined>()

  if (loading) return <CircularProgress />
  if (error) return <ErrorIcon />

  const toHeader = () =>
    ["Name", "User"].map((text) => (
      <TableCell key={text} onClick={() => setSelected(undefined)}>
        <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
      </TableCell>
    ))

  const handleSelect = (template: TemplateDeclaration) => {
    if (template.templateId === selected) {
      props.onSelect(undefined)
      setSelected(undefined)
    } else {
      props.onSelect(template)
      setSelected(template.templateId)
    }
  }

  const toRow = (template: TemplateDeclaration) => {
    return (
      <TableRow
        key={template.templateId}
        hover={true}
        selected={selected === template.templateId}
        onClick={() => handleSelect(template)}
      >
        <TableCell>{template.name}</TableCell>
        <TableCell>{template.azeUserName}</TableCell>
      </TableRow>
    )
  }

  const toRows = () => templates.map(toRow)

  return <Tabular toHeader={toHeader} toRows={toRows} />
}

export default Templates

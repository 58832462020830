/** Use Hooks for DT/Azure documents */
import { ListItem as MuiListItem, TableCell, TableRow, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import useSWR from "swr"
import { useFetchWithTokenSWR } from "../Auth/Msal"
import { SWRData } from "../utils/swr"
import { OpenInNew as OpenInNewIcon, OpenInNewOff as OpenInNewOffIcon } from "@mui/icons-material"
import { ColumnDefinition, DriveItem } from "@microsoft/microsoft-graph-types"
import { chunk } from "lodash"

/** A DTDocument is a persistable reference to an Azure document */
export interface DtDocument {
  id: string
  driveId: string
}

// if this makes problems with Authentication use MSAL.fetch
export const fetchFilterDocumentsWith = async (
  fetchWithToken: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  projectId: string,
  filterId: string
): Promise<DtDocument[]> => {
  const url = `/api/twin/viewer/projects/${projectId}/filters/${filterId}/documents`
  const response = await fetchWithToken(url)
  if (!response.ok) {
    console.error("Failed to fetch documents.", response)
    throw Error("Failed to fetch documents")
  }
  const data = await response.json()
  return data
}

// NOTE This sends a large payload and seems to be error prone, so we chunk it
// and retry if something goes wrong. This may well be the case elsewhere, so we
// could generalize this approach.
const CHUNK_SIZE = 512
const RETRIES = 3
export const fetchElementsWithDocumentsWith = async (
  fetchWithToken: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  projectId: string,
  elementIds: string[]
): Promise<string[]> => {
  const url = `/api/twin/viewer/projects/${projectId}/attachments/bulkHasDocuments`
  const fetchWithRetries = async (ids: string[], retriesLeft: number): Promise<string[]> => {
    const response = await fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ elementIds: ids })
    })
    if (!response.ok) {
      if (retriesLeft >= 1) {
        console.warn("Failed to fetch documents. Retries left ", retriesLeft)
        return await fetchWithRetries(ids, retriesLeft - 1)
      }

      console.error("Failed to fetch documents.", response)
      throw Error("Failed to fetch documents")
    }
    const data = (await response.json()) as string[]
    return data
  }

  const chunked = await Promise.all(
    chunk(elementIds, CHUNK_SIZE).map(async (eIds) => await fetchWithRetries(eIds, RETRIES))
  )
  return chunked.flat()
}

// Azure Document / Sharepointdata
export interface FieldValue {
  Label: string
  TermGuid: string
  WssId: number
}

export interface Field {
  key: string
  value: FieldValue
}

export interface AzureDocumentData {
  id: string
  name: string
  url: string | undefined
  driveId: string
  driveItem: DriveItem | undefined
  fields: Field[] | undefined
}

export interface SharepointData {
  libraryName: string
  libraryId: string
  _Links: AzureDocumentData[]
  allItems: DriveItem[]
  allColumns: ColumnDefinition[]
}
export const useAzureSharepointData = (azeProjectId: string | undefined): SWRData<SharepointData[]> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error } = useSWR<SharepointData[]>(
    azeProjectId ? `/api/azure/sharepoint/${azeProjectId}` : null,
    fetcher
  )
  return {
    data: data!,
    loading: !error && !data,
    error
  }
}

// export const useAzureDocumentsFromFilters = (projectId: string, filterId: string): SWRData<AzureDocumentData[]> => {
//   const { data: documents, error: docsError, loading: docsLoading } = useDocuments(projectId, filterId)
//   const fetcher = useFetchWithTokenSWRMulti()

//   const { data, error } = useSWR<AzureDocumentData[]>(() => {
//     if (!documents || !documents.length || docsError || docsLoading) return null
//     return documents.map((d) => `/api/azure/${d.driveId}/document/${d.id}`)
//   }, fetcher)

//   return {
//     // this is simply to keep the name in case the azure doc endpoint doesn't give us anything
//     data:
//       data?.map((d) => {
//         if (d.url) return d
//         const doc = documents.find((document) => document.id === d.id)
//         return doc ? { ...doc, url: undefined, driveItem: undefined, fields: undefined } : d
//       }) ?? [],
//     loading: !error && !data && documents.length > 0,
//     error
//   }
// }

// backend may return empty name and url
interface AzureDocumentDTO {
  id: string
  driveId: string
  name: string
  url: string
}

export interface AzureDocument {
  id: string
  driveId: string
  name?: string
  url?: string
}

const fromAzureDocumentDTO = (document: AzureDocumentDTO): AzureDocument => {
  const name = document.name.length ? document.name : undefined
  const url = document.url.length ? document.url : undefined
  return { ...document, name, url }
}

export const useAzureDocumentsByFilter = (projectId: string, filterId: string): SWRData<AzureDocument[]> => {
  const fetcher = useFetchWithTokenSWR()
  const url = `/api/twin/viewer/projects/${projectId}/filters/${filterId}/azureDocuments`
  const { data, error } = useSWR<AzureDocumentDTO[]>(url, fetcher)
  const documents = data ? data.map(fromAzureDocumentDTO) : []
  return {
    data: documents,
    loading: !error && !data,
    error
  }
}

// if this makes problems with Authentication use MSAL.fetch
export const fetchAzureDocumentsExpandedWith = async (
  fetchWithToken: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  projectId: string,
  filterId: string
): Promise<AzureDocument[]> => {
  const url = `/api/twin/viewer/projects/${projectId}/filters/${filterId}/azureDocuments?expand=true`
  const response = await fetchWithToken(url)
  if (!response.ok) {
    console.error("Failed to fetch azure documents.", response)
    throw Error("Failed to fetch azure documents")
  }
  const data = await response.json()
  return data
}

export const fetchAzureDocumentsForProject = async (
  fetchWithToken: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  projectId: string
): Promise<{ elementId: string; documents: AzureDocument[] }[]> => {
  const url = `/api/twin/viewer/projects/${projectId}/attachments/bulkAzureDocuments?expand=true`
  const response = await fetchWithToken(url)
  if (!response.ok) {
    console.error("Failed to fetch azure documents.", response)
    throw Error("Failed to fetch azure documents")
  }
  const data = await response.json()
  return data
}

export const DocumentTableRow = (document: AzureDocument) => {
  return (
    <TableRow key={document.id} hover={true}>
      <TableCell>{document.name}</TableCell>
      <TableCell>{document.id}</TableCell>
    </TableRow>
  )
}

export const DocumentListItem = (azureDoc: AzureDocumentData) => {
  const icon = azureDoc.url ? <OpenInNewIcon /> : <OpenInNewOffIcon />

  return (
    <MuiListItem disablePadding key={azureDoc.id}>
      <ListItemButton component="a" href={azureDoc.url} target="_blank" disabled={!azureDoc.url}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={azureDoc.name} />
      </ListItemButton>
    </MuiListItem>
  )
}

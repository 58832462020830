/** Use Hook for DT Filter. */
import useSWR from "swr"
import { useFetchWithTokenSWR } from "../Auth/Msal"
import { SWRData } from "../utils/swr"

export interface Filter {
  projectId: string
  id: string
  name: string
  query: string
  azeUserId: string
  azeUserName: string
}

export const useFilters = (projectId: string): SWRData<Filter[]> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error, mutate } = useSWR<Filter[]>(`/api/twin/viewer/projects/${projectId}/filters`, fetcher)
  return {
    data: data ?? [],
    loading: !error && !data,
    error,
    mutate
  }
}

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Button,
  useTheme
} from "@mui/material"
import { MuiFileInput } from "mui-file-input"
import Papa from "papaparse"
import { useState } from "react"
import { useAppDispatch } from "../../app/hooks"
import { useFetchWithToken } from "../../Auth/Msal"
import { setNotification } from "../../features/notification/notificationSlice"
import { afterCharacter, beforeCharacter } from "../../Viewer/utils"
import { AddLog, addLog } from "../HistoryDialog"

interface ImportDialogProps {
  open: boolean
  projectId: string
  onCancel: () => void
  onSubmit: () => void
}

type Row = any //(string | number | undefined)[]

// formFields('name#key') = { column: 'name#key', name:'name', id:'key'}
const fromFields = (name: string) => {
  const idx = name.lastIndexOf("#")
  return { column: name, name: name.substring(0, idx), id: name.substring(idx + 1) }
}
// toProjectId('projectId+documentId+externalId') = 'projectId'
const toProjectId = (key: string) => beforeCharacter(key, "+")
// toElementId('projectId+documentId+externalId') = 'documentId+externalId'
const toElementId = (key: string) => afterCharacter(key, "+")

const ImportDialog = (props: ImportDialogProps) => {
  const [csvFile, setCsvFile] = useState<File | null | undefined>(undefined)
  const [csv, setCsv] = useState<Row[] | undefined>(undefined)
  const [csvFields, setCsvFields] = useState<string[] | undefined>(undefined)

  const theme = useTheme()
  const spacing = theme.spacing(1)
  const fetchWithToken = useFetchWithToken()
  const dispatch = useAppDispatch()

  const parameters = csvFields?.filter((s) => s.includes("#")).map(fromFields)

  const handleCancel = () => props.onCancel()
  const handleSubmit = () => {
    if (!csv || !parameters) return

    parameters.forEach((parameter) => {
      const url = `/api/twin/viewer/projects/${props.projectId}/parametersWithId/${parameter.id}`

      const reducedRows = csv
        .filter((row) => row["elementId"] && row[parameter.column] && toProjectId(row["elementId"]) === props.projectId)
        .map((row) => [toElementId(row["elementId"]), row[parameter.column]])
      const values = Object.fromEntries(reducedRows)

      const updateParam = {
        projectId: props.projectId,
        id: parameter.id,
        values: values
      }

      fetchWithToken(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(updateParam)
      })
        .then(async (response) => {
          if (response.status === 200) {
            const count: number = await response.json()
            dispatch(setNotification({ status: "success", message: `Updated ${count} parameter values.` }))
            let log: AddLog = {
              projectId: props.projectId,
              elementId: "import_parameters",
              message: `Import parameters from file ${csvFile?.name ?? "unknwon"}`,
              logType: "Import Parameters"
            }
            addLog(fetchWithToken, log)
          } else {
            dispatch(setNotification({ status: "error", message: "Failed to update parameter values." }))
          }
        })
        .catch((_reason) => {
          dispatch(setNotification({ status: "error", message: "Failed to update parameter values." }))
        })
    })
    props.onSubmit()
  }

  const handleFileSelected = (newFile: File | null) => {
    setCsvFile(newFile)

    if (newFile) {
      Papa.parse(newFile, {
        header: true,
        // everything is a string here
        dynamicTyping: false,
        complete: function (results: Papa.ParseResult<Row>) {
          setCsv(results.data)
          setCsvFields(results.meta.fields)
        }
      })
    } else {
      setCsv(undefined)
      setCsvFields(undefined)
    }
  }

  return (
    <Dialog open={props.open} keepMounted={false}>
      <DialogTitle>Import CSV</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Paper elevation={0} style={{ margin: spacing }}>
          <Typography variant="h5">Select CSV File</Typography>
          <MuiFileInput value={csvFile} onChange={handleFileSelected} />
        </Paper>
        {csv && (
          <Paper elevation={0} style={{ margin: spacing }}>
            <Typography variant="h5">Update the following parameters for {csv.length} elements?</Typography>
            <Paper elevation={0} variant="outlined">
              <List
                dense
                disablePadding
                role="list"
                sx={{
                  maxHeight: 250,
                  overflowY: "auto"
                }}
              >
                {parameters?.map((p) => {
                  return (
                    <ListItem key={p.id} dense role="listitem">
                      <ListItemText key={p.id} primary={p.name} />
                    </ListItem>
                  )
                })}
              </List>
            </Paper>
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!csv}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImportDialog

/** Drawer Navigation with 2D/3D/Custom View selection.
 */
import { ChevronLeft } from "@mui/icons-material"
import { Box, Drawer, IconButton, Divider, Tabs, Tab, styled } from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { Viewables } from "../Viewer/Viewer"
import ViewableList from "./Viewables"
import Views from "./Views"

interface NavigationProps {
  aggregatedView: Autodesk.Viewing.AggregatedView | undefined
  viewables: Viewables | undefined
  open: boolean
  toggle: (open: boolean) => void
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  )
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  // height: "48px",
  justifyContent: "flex-end"
}))

const Navigation = (props: NavigationProps) => {
  const params = useParams()
  const projectId = params.projectId

  // tab index
  const [tab, setTab] = useState(0)
  const handleSetTab = (_event: React.SyntheticEvent, newValue: number) => setTab(newValue)

  const viewables = props.viewables
  const viewables2d = viewables?.twod
  const viewables3d = viewables?.main && props.viewables?.threed && [...viewables.main, ...props.viewables.threed]

  return (
    <Drawer open={props.open} variant="temporary">
      <DrawerHeader>
        <IconButton onClick={() => props.toggle(false)}>
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {projectId && props.aggregatedView && viewables && viewables2d && viewables3d && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0, margin: 0 }}>
            <Tabs value={tab} onChange={handleSetTab}>
              <Tab label="2D" />
              <Tab label="3D" />
              <Tab label="Views" />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <ViewableList
              aggregatedView={props.aggregatedView}
              viewables={viewables2d}
              onSelect={() => props.toggle(false)}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <ViewableList
              aggregatedView={props.aggregatedView}
              viewables={viewables3d}
              onSelect={() => props.toggle(false)}
            />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Views
              projectId={projectId}
              aggregatedView={props.aggregatedView}
              viewables={viewables}
              onSelect={() => props.toggle(false)}
            />
          </TabPanel>
        </>
      )}
    </Drawer>
  )
}

export default Navigation

/** Fetches user information. Provides.
 * Provides Security.Role
 */
import useSWR from "swr"
import { useFetchWithTokenSWR } from "../Auth/Msal"

export type Role = "Admin" | "SuperUser" | "User" | "Read" | "Unprivileged"

export interface User {
  projectId: string
  projectName: string
  userRole: Role
  userName: string
  userId: string
  azeProjectId: string
}

export const isAdmin = (user: User | undefined) => user?.userRole === "Admin"

const isSuperUser = (user: User | undefined) => ["Admin", "SuperUser"].includes(user?.userRole ?? "")

const isUser = (user: User | undefined) => ["Admin", "SuperUser", "User"].includes(user?.userRole ?? "")

const isRead = (user: User | undefined) => ["Admin", "SuperUser", "User", "Read"].includes(user?.userRole ?? "")

const isUnprivileged = (user: User | undefined) => user?.userRole === "Unprivileged"

export const useUser = (projectId: string) => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error } = useSWR<User>(`/api/twin/viewer/projects/${projectId}/user`, fetcher)

  return {
    data: data,
    loading: !error && !data,
    error,
    isAdmin: data !== undefined && isAdmin(data),
    isSuperUser: data !== undefined && isSuperUser(data),
    isUser: data !== undefined && isUser(data),
    isRead: data !== undefined && isRead(data),
    isUnprivileged: data !== undefined && isUnprivileged(data)
  }
}

export default useUser

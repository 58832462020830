import { Add, Tune, History as HistoryIcon, PlayArrow, SelectAll, ChangeHistory, Delete } from "@mui/icons-material"
import { CircularProgress, Paper, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { mutate } from "swr"
import { useAppDispatch } from "../../app/hooks"
import { useFetchWithToken } from "../../Auth/Msal"
import { setNotification } from "../../features/notification/notificationSlice"
import { AddParameterDialog } from "./ParameterDialogs"
import ParameterList, { fetchParametersWith, ParameterDeclaration } from "./ParameterList"
import { SetParameterDialog } from "./SetParameterDialog"
import ActionButton, { ActionLoadingButton } from "../ActionButton"
import useUser from "../../useHooks/useUser"
import { HistoryDialog } from "../HistoryDialog"

import { getExternalParametersMap, getParameterNamesFromQuery, Query, runQuery } from "../FilterTab/query"
import QueryComponent, { emptyQuery } from "../QueryComponent"
import { selectionsDifference, fromParameterSelection, select, getBulkPropertyMap } from "../../Viewer/utils"
import { ParameterInstance } from "../AssignParametersTab/AssignParameters"
import { DeleteDialog } from "../DeleteDialog"
import { uniq } from "lodash"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

interface ParameterTabProps {
  viewer: Viewer3D
  projectId: string
}

export type Datatype = "number" | "text" | "date" | "boolean"

export interface AddParameter {
  projectId: string
  name: string
  datatype: Datatype
  unit: string
  query: string
}

export interface ParameterElements {
  projectId: string
  id: string
  elementIds: string[]
}

const ManageParametersTab = (props: ParameterTabProps) => {
  const theme = useTheme()
  const spacing = theme.spacing(1)
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()

  const projectId = props.projectId

  // selected parameter
  const [selectedParameter, setSelectedParameter] = useState<ParameterDeclaration | undefined>()
  const handleSelectParameter = (parameter: ParameterDeclaration | undefined) => setSelectedParameter(parameter)

  // add parameter
  const [openAddParameterDialog, setOpenAddParameterDialog] = useState<boolean>(false)
  const handleOpenAddParameterDialog = () => setOpenAddParameterDialog(true)

  // set parameter values
  const [parameterToSet, setParameterToSet] = useState<ParameterDeclaration | undefined>(undefined)
  const handleOpenSetParameterDialog = () => setParameterToSet(selectedParameter)

  const handleCancelDialog = () => {
    setOpenAddParameterDialog(false)
    setParameterToSet(undefined)
  }

  // security
  const { data: user, isAdmin, isRead, isSuperUser, isUser } = useUser(projectId)

  const handleSubmitAddParameterDialog = (parameter: AddParameter | undefined) => {
    setOpenAddParameterDialog(false)
    const successMessage = "Parameter added"
    const errorMessage = "Failed to add Parameter"

    const url = `/api/twin/viewer/projects/${projectId}/parameterdeclarations`

    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(parameter)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate(url)
          dispatch(setNotification({ status: "success", message: successMessage }))
        } else {
          response
            .text()
            .then((text) => dispatch(setNotification({ status: "error", message: errorMessage + " " + text })))
        }
      })
      .catch((reason) => {
        dispatch(setNotification({ status: "error", message: errorMessage }))
      })
  }

  const handleSubmitSetParameters = (parameter: ParameterElements | undefined) => {
    setParameterToSet(undefined)
    const successMessage = "Parameter values set"
    const errorMessage = "Failed to set Parameter values"

    if (!parameter) {
      dispatch(setNotification({ status: "error", message: errorMessage }))
      return
    }

    const url = `/api/twin/viewer/projects/${projectId}/parameterinitialisations`

    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(parameter)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate(url)
          dispatch(setNotification({ status: "success", message: successMessage }))
        } else {
          dispatch(setNotification({ status: "error", message: errorMessage }))
        }
      })
      .catch((reason) => {
        dispatch(setNotification({ status: "error", message: errorMessage }))
      })
  }

  const fetchInstances = async (parameterId: string) => {
    // fetch instances
    const url = `/api/twin/viewer/projects/${projectId}/parametersWithId/${parameterId}`

    const dispatchError = () =>
      dispatch(
        setNotification({
          status: "error",
          message: "Failed to fetch parameter instances."
        })
      )

    const properties: ParameterInstance[] | undefined = await fetchWithToken(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else {
          response.text().then((text) => console.error(response.text))
          dispatchError()
          return
        }
      })
      .catch((reason) => {
        console.error(reason)
        dispatchError()
        return
      })

    return properties
  }

  // delta
  const [loadingDelta, setLoadingDelta] = useState<boolean>(false)
  const handleDelta = async (parameter: ParameterDeclaration | undefined) => {
    if (!parameter) return
    setLoadingDelta(true)
    const instances = await fetchInstances(parameter.parameterId)
    if (instances) {
      const elementIds = instances.map((instance) => instance.elementId)
      const propertySelection = await fromParameterSelection(props.viewer, elementIds)

      const parameterNames = uniq(getParameterNamesFromQuery(query))
      const externalParameters = await getExternalParametersMap(parameterNames, fetchParameters)
      const internalParameters = await getBulkPropertyMap(
        props.viewer!.getAllModels(),
        {
          propFilter: parameterNames,
          needsExternalId: true,
          ignoreHidden: false
        },
        undefined,
        true
      )
      const querySelection = await runQuery(externalParameters, internalParameters, props.viewer, query)

      const delta = selectionsDifference(querySelection, propertySelection)
      select(props.viewer, delta)
    }

    setLoadingDelta(false)
  }

  // select element
  const [loadingSelectElements, setLoadingSelectElements] = useState<boolean>(false)
  const handleSelectElements = async (parameter: ParameterDeclaration | undefined) => {
    if (!parameter) return
    setLoadingSelectElements(true)
    const instances = await fetchInstances(parameter.parameterId)
    if (instances) {
      const elementIds = instances.map((instance) => instance.elementId)
      const propertySelection = await fromParameterSelection(props.viewer, elementIds)
      select(props.viewer, propertySelection)
    }
    setLoadingSelectElements(false)
  }

  // history
  const [openParameterHistoryDialog, setOpenParameterHistoryDialog] = useState<boolean>(false)

  // delete
  const [deleteParameter, setDeleteParameter] = useState<ParameterDeclaration | undefined>()
  const handleOpenDeleteParameterDialog = () => setDeleteParameter(selectedParameter)
  const handleCancelDeleteParameterDialog = () => setDeleteParameter(undefined)

  const handleSubmitDeleteParameterDialog = (_parameter: ParameterDeclaration | undefined) => {
    setDeleteParameter(undefined)
    setSelectedParameter(undefined)
  }

  // query
  const [query, setQuery] = useState<Query>(emptyQuery)
  const hanldeSetQuery = (query: Query) => setQuery(query)
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<{ modelId: number; dbIds: number[] }[] | undefined>()

  const fetchParameters = async (name: string) => {
    const parameters = await fetchParametersWith(fetchWithToken, projectId, name)
    if (!parameters) {
      dispatch(setNotification({ status: "error", message: "Failed to fetch Parameters." }))
      return []
    } else return parameters
  }

  const handleQueryRun = async () => {
    if (!props.viewer) return
    setLoading(true)
    const parameterNames = uniq(getParameterNamesFromQuery(query))
    const externalParameters = await getExternalParametersMap(parameterNames, fetchParameters)
    const internalParameters = await getBulkPropertyMap(
      props.viewer.getAllModels(),
      {
        propFilter: parameterNames,
        needsExternalId: true,
        ignoreHidden: false
      },
      undefined,
      true
    )
    const result = await runQuery(externalParameters, internalParameters, props.viewer, query)
    setResult(result)
    setLoading(false)
  }

  const handleApplyResult = () => {
    if (!props.viewer) return
    if (!result?.length) return
    console.debug("Filter Selection", result)
    select(props.viewer, result)
  }

  useEffect(() => {
    if (!selectedParameter?.query?.length) return
    const parsedQuery = JSON.parse(selectedParameter.query)
    setQuery(parsedQuery)
  }, [selectedParameter])

  // Note: buttons and list should be scoped together, is Ok as long as ParameterTab has no other components
  return (
    <>
      <Paper style={{ margin: spacing }}>
        <Typography variant="h5" sx={{ color: "primary.text" }}>
          Parameters
        </Typography>
        <ActionButton startIcon={<Add />} onClick={handleOpenAddParameterDialog} disabled={!isSuperUser}>
          New
        </ActionButton>
        <ActionButton
          startIcon={<Tune />}
          onClick={handleOpenSetParameterDialog}
          disabled={!selectedParameter || !isSuperUser}
        >
          Set
        </ActionButton>
        <ActionLoadingButton
          startIcon={<SelectAll />}
          onClick={() => handleSelectElements(selectedParameter)}
          loading={loadingSelectElements}
          disabled={!selectedParameter || !isRead}
        >
          Select
        </ActionLoadingButton>
        <ActionLoadingButton
          startIcon={<ChangeHistory />}
          onClick={() => handleDelta(selectedParameter)}
          loading={loadingDelta}
          disabled={!selectedParameter || !isRead}
        >
          Delta
        </ActionLoadingButton>
        <ActionButton
          startIcon={<HistoryIcon />}
          onClick={() => setOpenParameterHistoryDialog(true)}
          disabled={!selectedParameter || !isRead}
        >
          History
        </ActionButton>
        <ActionButton
          startIcon={<Delete />}
          onClick={handleOpenDeleteParameterDialog}
          disabled={!selectedParameter || !(isAdmin || (isUser && user?.userId === selectedParameter?.azeUserId))}
        >
          Delete
        </ActionButton>
        <ParameterList projectId={projectId} onSelect={handleSelectParameter} />
      </Paper>
      <AddParameterDialog
        projectId={projectId}
        open={openAddParameterDialog}
        query={query}
        onCancel={handleCancelDialog}
        onSubmit={handleSubmitAddParameterDialog}
      />
      <SetParameterDialog
        viewer={props.viewer}
        projectId={projectId}
        open={parameterToSet}
        onCancel={handleCancelDialog}
        onSubmit={handleSubmitSetParameters}
      />
      {openParameterHistoryDialog && selectedParameter && (
        <HistoryDialog
          open={openParameterHistoryDialog}
          projectId={projectId}
          elementId={selectedParameter.parameterId}
          onClose={() => setOpenParameterHistoryDialog(false)}
        />
      )}
      {deleteParameter && (
        <DeleteDialog
          open={{ ...deleteParameter, id: deleteParameter?.parameterId }}
          message={(declaration: ParameterDeclaration) =>
            `Delete parameter '${declaration.name}' with id '${declaration.parameterId}'?`
          }
          url={(declaration: ParameterDeclaration) =>
            `/api/twin/viewer/projects/${declaration.projectId}/parameterdeclarations`
          }
          mutate={true}
          onCancel={handleCancelDeleteParameterDialog}
          onSubmit={handleSubmitDeleteParameterDialog}
        />
      )}
      <>
        <ActionButton startIcon={<PlayArrow />} onClick={handleQueryRun} disabled={!query?.data.rules.length}>
          Run
        </ActionButton>
        <ActionButton startIcon={<SelectAll />} onClick={handleApplyResult} disabled={!result?.length}>
          Select
        </ActionButton>
        <QueryComponent query={query} onChange={hanldeSetQuery} />
        {loading && <CircularProgress />}
      </>
    </>
  )
}

export default ManageParametersTab

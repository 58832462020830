/** Run Query for
 *   - parameter properties of the Forge viewer, and
 *   - DT parameters
 *
 * Parameter names are not unique. We take the union of individual tests.
 */

// Note: Supporting **Category*
// Categories are not really a concept in Forge.
//
// Revit: The instance tree has usually the following structure: 'category/family name/type name/instance'
// See also hidden "_RC/_RFN/_RTN" parameters.
// Not sure if this is always true.
// Taking the leave elements of a eg 'Doors' does not necessarily provide all door elements. A door could have child elements.
// Revit models do  have a hidden (not displayed in properties panel) parameter 'Category'.
// So we do not treat categories separately in queries.

import { PromisePool } from "@supercharge/promise-pool/dist/promise-pool"
import { AzureDocument, DtDocument } from "../../useHooks/useDocuments"
import { Filter } from "../../useHooks/useFilters"
import {
  ExternalSelection,
  fromParameterSelection,
  fromParameterSelectionFromModels,
  getBulkPropertyMap,
  getLevels,
  isOnLevel,
  Level,
  Selections,
  selectionsDifference,
  selectionsDifferences,
  toExternalSelection,
  toParameterSelection
} from "../../Viewer/utils"
import { ParameterInstance } from "../AssignParametersTab/AssignParameters"
import { uniq } from "lodash"

export type Operator = "is" | "includes" | "starts with" | "matches" | "lt" | "lte" | "gte" | "gt"
export type Rule = { field: string; operator: Operator; value: string }
// Group Type corresponds to QueryBuilder.
export type Group = { combinator: "and" | "or"; rules: (Rule | Group)[] }
export type Query = { version: string; data: Group }
export type QueryResult = Set<number>

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D
type Model = Autodesk.Viewing.Model
type Property = Autodesk.Viewing.Property

type ExternalParameters = Map<string, ParameterInstance[]>
type InternalParameters = Map<number, Autodesk.Viewing.PropertyResult[]>

// NOTE: There is a worker thread with a property databas.
// Could be useful to gather all attributes for example.
// But there are multiple thousand attributes.
// @ts-ignore
// function userFunction(pdb) {
//   // @ts-ignore
//   pdb.enumAttributes(function (i, attrDef, attrRaw) {
//     console.log(i, attrDef, attrRaw)
//   })
// }

// const atts = async (model: Model) => {
//   let pdb = model.getPropertyDb()
//   await pdb.executeUserFunction(userFunction)
// }

const getParameterNamesFromRule = (rule: Rule): string => rule.field

const getParameterNamesFromGroup = (group: Group): string[] =>
  group.rules.flatMap((rg) => (isRule(rg) ? getParameterNamesFromRule(rg) : getParameterNamesFromGroup(rg)))

export const getParameterNamesFromQuery = (query: Query): string[] => getParameterNamesFromGroup(query.data)

export const getParameterNamesFromFilter = (filter: Filter): string[] =>
  getParameterNamesFromQuery(JSON.parse(filter.query))

// set union
const union = <T>(sets: (Set<T> | undefined)[]): Set<T> => {
  if (!sets.length) return new Set()
  const set = new Set(sets[0])
  for (const other of sets.slice(1)) other?.forEach((v) => set.add(v))
  return set
}

// set intersection
const intersection = <T>(sets: (Set<T> | undefined)[]): Set<T> => {
  if (!sets.length) return new Set()
  const set = new Set(sets[0])
  for (const other of sets.slice(1))
    set.forEach((v) => {
      if (!other?.has(v)) set.delete(v)
    })
  return set
}

const compareProperty = (property: Property | undefined, rule: Rule): boolean => {
  if (!property) return false
  const value = property.displayValue
  return compare(value, rule)
}

// wild card match
// - case insensitiv
// - * ... word
// - ? ... letter
// matches('b*, "bird") == true
// matches('b?ird, "bird") == true
// matches('b?rd, "bird") == false
// https://stackoverflow.com/questions/26246601/wildcard-string-comparison-in-javascript
const matches = (wildcard: string, str: string) => {
  let w = wildcard.replace(/[.+^${}()|[\]\\]/g, "\\$&")
  const re = new RegExp(`^${w.replace(/\*/g, ".*").replace(/\?/g, ".")}$`, "i")
  return re.test(str)
}

const epsilon = 0.01
export const almostEqual = (v1: number, v2: number): boolean => Math.abs(v1 - v2) < epsilon
export const lte = (v1: number, v2: number): boolean => v1 < v2 || almostEqual(v1, v2)
export const lt = (v1: number, v2: number): boolean => v1 < v2 && !almostEqual(v1, v2)
export const gte = (v1: number, v2: number): boolean => !lt(v1, v2)
export const gt = (v1: number, v2: number): boolean => !lte(v1, v2)

// Both, Forge parameter values and external parameter values have type 'string | number'.
// A rule has always a value of type 'string'.
// Has no special treatement for float-like numbers.
const compare = (value: string | number, rule: Rule): boolean => {
  if (typeof value === "string") {
    switch (rule.operator) {
      case "is":
        return value === rule.value
      case "includes":
        return value.includes(rule.value)
      case "starts with":
        return value.startsWith(rule.value)
      case "matches":
        return matches(rule.value, value)
    }
  }
  if (typeof value === "number") {
    let numValue = parseFloat(rule.value)
    switch (rule.operator) {
      case "is":
        return almostEqual(value, numValue)
      case "lt":
        return lt(value, numValue)
      case "lte":
        return lte(value, numValue)
      case "gte":
        return gte(value, numValue)
      case "gt":
        return gt(value, numValue)
    }
  }
  return false
}

// This is a rather basic implementation that relies on 'model.findProperty(name)' to get all dbIds.
// Tests each rule separately, ie no prograpagtaion.

// Custom parameters can also have type bool or date but is stored and handled here as a string.
const queryRuleExternal = async (
  externalParameters: ExternalParameters,
  models: Model[],
  selectionFilter: QueryResult[] | undefined,
  rule: Rule
): Promise<QueryResult[]> => {
  const name = rule.field
  const parameters = externalParameters.get(name) ?? []
  // filtered [key, value] list
  const externalResult = parameters.filter((parameter) => {
    const displayValue = parameter.value
    const value: string | number = parameter.datatype === "number" ? parseFloat(displayValue) : displayValue
    return compare(value, rule)
  })

  const parameterSelection = externalResult.map((parameter) => parameter.elementId)
  const selections = await fromParameterSelectionFromModels(models, parameterSelection)
  const result: QueryResult[] = []
  for (const selection of selections) result[selection.modelId] = new Set(selection.dbIds)

  const filteredResult = selectionFilter ? qintersection([result, selectionFilter]) : result

  return filteredResult
}

// returns all dbids form externalidmapping
// const getDbIds = async (model: Model): Promise<number[]> => {
//   const exIds = getExternalIdMapping(model)
//   const dbIds = Object.values(exIds)
//   return dbIds
// }

// The element name is not a property, so findProperty does not return the dbIds (though there may be parameters with attribute 'Name').
// Iterate through model tree and compare with 'getNodeName'.
const queryRuleName1 = async (model: Model, dbIdFilter: Set<number> | undefined, rule: Rule): Promise<QueryResult> => {
  if (rule.field !== "name" && rule.field !== "Name") return new Set()

  const instances = model.getInstanceTree()
  const root = instances.getRootId()
  if (!root) return new Set()

  const dbIds: number[] = []

  if (dbIdFilter) {
    dbIdFilter.forEach((id) => {
      const name = instances.getNodeName(id)
      if (compare(name, rule)) dbIds.push(id)
    })
  } else {
    instances.enumNodeChildren(
      root,
      (id: number) => {
        const name = instances.getNodeName(id)
        if (compare(name, rule)) dbIds.push(id)
      },
      true
    )
  }

  return new Set(dbIds)
}

const queryRuleName = async (
  models: Model[],
  selectionFilter: QueryResult[] | undefined,
  rule: Rule
): Promise<QueryResult[]> => {
  const resultsM = models.map(async (model) => {
    const dbFilter = selectionFilter ? selectionFilter?.[model.id] ?? new Set() : undefined
    const result = await queryRuleName1(model, dbFilter, rule)
    return { modelId: model.id, dbIds: result }
  })
  const results = await Promise.all(resultsM)
  const result = []
  for (const r of results) result[r.modelId] = r.dbIds
  return result
}

/** Returns elements on some level
 * [Level] is "00 EG RDOK" */
// Not really efficient. Iterates through whole tree.
const queryLevel1 = async (
  levels: Level[],
  model: Model,
  dbIdFilter: Set<number> | undefined,
  rule: Rule
): Promise<QueryResult> => {
  if (rule.field !== "[Level]" && rule.operator === "is") return new Set()

  const instances = model.getInstanceTree()
  const root = instances.getRootId()
  if (!root) return new Set()

  const dbIds: number[] = []
  const level = levels.find((level) => level.name === rule.value)

  if (dbIdFilter) {
    instances.enumNodeChildren(
      root,
      (id: number) => {
        if (dbIdFilter.has(id) && isOnLevel(model, level, id)) dbIds.push(id)
      },
      true
    )
  } else {
    instances.enumNodeChildren(
      root,
      (id: number) => {
        if (isOnLevel(model, level, id)) dbIds.push(id)
      },
      true
    )
  }

  return new Set(dbIds)
}

const queryLevel = async (
  levels: Level[],
  models: Model[],
  selectionFilter: QueryResult[] | undefined,
  rule: Rule
): Promise<QueryResult[]> => {
  const resultsM = models.map(async (model) => {
    const dbFilter = selectionFilter ? selectionFilter?.[model.id] ?? new Set() : undefined
    const result = await queryLevel1(levels, model, dbFilter, rule)
    return { modelId: model.id, dbIds: result }
  })
  const results = await Promise.all(resultsM)
  const result = []
  for (const r of results) result[r.modelId] = r.dbIds
  return result
}

// NOTE:
// We have used 'model.findProperties(name)', which should return all elements for a given property name.
// Though, for some properties not all elements have been returned.
const queryRuleInternal1 = async (
  internalParameters: Autodesk.Viewing.PropertyResult[],
  rule: Rule
): Promise<QueryResult> => {
  const filteredDbIds = internalParameters
    .filter((p) => p.properties.some((property) => compareProperty(property, rule)))
    .map((p) => p.dbId)
  return new Set(filteredDbIds)
}

const queryRuleInternal = async (
  internalParameters: InternalParameters,
  models: Model[],
  rule: Rule
): Promise<QueryResult[]> => {
  const resultsM = models.map(async (model) => {
    const result = await queryRuleInternal1(internalParameters.get(model.id) ?? [], rule)
    return { modelId: model.id, dbIds: result }
  })
  const results = await Promise.all(resultsM)
  const result = []
  for (const r of results) result[r.modelId] = r.dbIds
  return result
}

const isRule = (ruleOrGroup: Rule | Group): ruleOrGroup is Rule => "operator" in ruleOrGroup

const queryRuleOrGroup = async (
  levels: Level[],
  externalParameters: ExternalParameters,
  internalParameters: InternalParameters,
  models: Model[],
  selectionFilter: QueryResult[] | undefined,
  rule: Rule | Group
): Promise<QueryResult[]> => {
  if (isRule(rule)) return queryRule(levels, externalParameters, internalParameters, models, selectionFilter, rule)
  else return queryGroup(levels, externalParameters, internalParameters, models, selectionFilter, rule)
}

const keys = (results: QueryResult[][]): number[] => {
  const keys: Set<number> = new Set()
  for (const result of results) for (const key of Array.from(result.keys())) keys.add(key)
  return Array.from(keys)
}

const qunion = (results: QueryResult[][]): QueryResult[] => {
  const result: QueryResult[] = []
  for (const key of keys(results)) result[key] = union(results.map((result) => result[key]))
  return result
}

const qintersection = (results: QueryResult[][]): QueryResult[] => {
  const result: QueryResult[] = []
  for (const key of keys(results)) result[key] = intersection(results.map((result) => result[key]))
  return result
}

const queryRule = async (
  levels: Level[],
  externalParameters: ExternalParameters,
  internalParameters: InternalParameters,
  models: Model[],
  selectionFilter: QueryResult[] | undefined,
  rule: Rule
) => {
  const name = await queryRuleName(models, selectionFilter, rule)
  const level = await queryLevel(levels, models, selectionFilter, rule)
  const intern = await queryRuleInternal(internalParameters, models, rule)
  const extern = await queryRuleExternal(externalParameters, models, selectionFilter, rule)
  return qunion([name, level, intern, extern])
}

const queryGroup = async (
  levels: Level[],
  externalParameters: ExternalParameters,
  internalParameters: InternalParameters,
  models: Model[],
  selectionFilter: QueryResult[] | undefined,
  group: Group
): Promise<QueryResult[]> => {
  const results = await Promise.all(
    group.rules.map((rule) =>
      queryRuleOrGroup(levels, externalParameters, internalParameters, models, selectionFilter, rule)
    )
  )
  return group.combinator === "and" ? qintersection(results) : qunion(results)
}

const fromSelections = (selections: Selections) => {
  const result: QueryResult[] = []
  for (const sel of selections) result[sel.modelId] = new Set(sel.dbIds)
  return result
}

const toSelections = (result: QueryResult[]) => {
  const selections: Selections = []
  for (const key of Array.from(result.keys())) {
    const dbIds = Array.from(result[key])
    if (dbIds.length) selections.push({ modelId: key, dbIds })
  }
  return selections
}

export interface QueryOptions {
  modelFilter?: Model[]
  selectionFilter?: Selections
}

export const getExternalParametersMap = async (
  ps: string[],
  fetchParameters: (name: string) => Promise<ParameterInstance[]>
): Promise<ExternalParameters> => {
  const externalParameters = new Map<string, ParameterInstance[]>()

  for (const p of ps) {
    const external = await fetchParameters(p)
    if (external?.length) externalParameters.set(p, external)
  }

  return externalParameters
}

/** Run a query. Returns a Viewer 'Selections'. */
export const runQuery = async (
  externalParameters: ExternalParameters,
  internalParameters: InternalParameters,
  viewer: Viewer3D,
  query: Query,
  options?: QueryOptions
): Promise<Selections> => {
  const levels = getLevels(viewer)

  if (options?.selectionFilter?.length === 0) return []
  const resultFilter = options?.selectionFilter ? fromSelections(options.selectionFilter) : undefined

  const models = options?.selectionFilter
    ? viewer.getAllModels().filter((model) => options?.selectionFilter?.find((sel) => sel.modelId === model.id))
    : viewer.getAllModels()

  const result = await queryGroup(levels, externalParameters, internalParameters, models, resultFilter, query.data)

  const selections = toSelections(result)
  return selections
}

// #region filter > documents

const runFilterWithDocument = async (
  externalParameters: ExternalParameters,
  internalParameters: InternalParameters,
  fetchDocuments: (filterId: string) => Promise<DtDocument[]>,
  viewer: Viewer3D,
  filter: Filter,
  options?: QueryOptions
): Promise<{ filter: Filter; selections: Selections; documents: DtDocument[] }> => {
  let query = JSON.parse(filter.query) as Query | undefined
  if (!query) {
    throw new Error("Failed to parse query.")
  }
  const selections = await runQuery(externalParameters, internalParameters, viewer, query, options)
  const documents = await fetchDocuments(filter.id)
  return { filter: filter, selections: selections, documents: documents }
}

/** Specialised query operation, mapping selection to attachments. */
const runFiltersWithDocument = async (
  externalParameters: ExternalParameters,
  internalParameters: InternalParameters,
  fetchDocuments: (filterId: string) => Promise<DtDocument[]>,
  viewer: Viewer3D,
  filters: Filter[],
  options?: QueryOptions
): Promise<{ filter: Filter; selections: Selections; documents: DtDocument[] }[]> => {
  const { results, errors } = await PromisePool.withConcurrency(5)
    .for(filters)
    .process(async (filter) =>
      runFilterWithDocument(externalParameters, internalParameters, fetchDocuments, viewer, filter, options)
    )
  if (errors.length) {
    console.error("Failed to execute filters:", errors)
  }
  return results
}

export const runQueryNoAttachments = async (
  fetchParameters: (name: string) => Promise<ParameterInstance[]>,
  fetchFilterDocuments: (filterId: string) => Promise<DtDocument[]>,
  fetchElementsWithDocuments: (elementIds: string[]) => Promise<string[]>,
  viewer: Viewer3D,
  query: Query,
  filters: Filter[]
) => {
  const parameterNames = uniq(filters.flatMap(getParameterNamesFromFilter))
  const externalParameters = await getExternalParametersMap(parameterNames, fetchParameters)
  const internalParameters = await getBulkPropertyMap(
    viewer.getAllModels(),
    {
      propFilter: parameterNames,
      needsExternalId: true,
      ignoreHidden: false
    },
    undefined,
    true
  )
  const selections = await runQuery(externalParameters, internalParameters, viewer, query)

  // TODO Optimization: check if Filter has documents attached before running it at all
  // Unsure which makes more sense - filters first or elements first.
  const results = await runFiltersWithDocument(
    externalParameters,
    internalParameters,
    fetchFilterDocuments,
    viewer,
    filters,
    {
      selectionFilter: selections
    }
  )
  const selectionsWithFilterDocs = results
    .filter((result) => result.documents.length)
    .map((result) => result.selections)
  const selectionsWithoutFilterDocs = selectionsDifferences(selections, selectionsWithFilterDocs)

  const externalIds = await toParameterSelection(viewer, selectionsWithoutFilterDocs)
  const elementsWithDocs = await fetchElementsWithDocuments(externalIds)
  const selectionsWithElementDocuments = await fromParameterSelection(viewer, elementsWithDocs)
  const selectionsWithoutElementDocs = selectionsDifference(selections, selectionsWithElementDocuments)

  return selectionsWithoutElementDocs
}

// #endregion

// #region filter > azure documents

const runFilterWithAzureDocument = async (
  externalParameters: ExternalParameters,
  internalParameters: InternalParameters,
  fetchAzureDocuments: (filterId: string) => Promise<AzureDocument[]>,
  viewer: Viewer3D,
  filter: Filter,
  options?: QueryOptions
): Promise<{ filter: Filter; selections: ExternalSelection; documents: AzureDocument[] }> => {
  let query = JSON.parse(filter.query) as Query | undefined
  if (!query) {
    throw new Error("Failed to parse query.")
  }
  const selections = await runQuery(externalParameters, internalParameters, viewer, query, options)
  const documents = await fetchAzureDocuments(filter.id)
  const externalSelections = await toExternalSelection(viewer, selections)
  return { filter: filter, selections: externalSelections, documents: documents }
}

/** Specialised query operation, mapping (external) elements to Azure documents. */
export const runFiltersWithAzureDocument = async (
  fetchParameters: (name: string) => Promise<ParameterInstance[]>,
  fetchAzureDocuments: (filterId: string) => Promise<AzureDocument[]>,
  viewer: Viewer3D,
  filters: Filter[],
  options?: QueryOptions
): Promise<{ filter: Filter; selections: ExternalSelection; documents: AzureDocument[] }[]> => {
  const parameterNames = uniq(filters.flatMap(getParameterNamesFromFilter))
  const externalParameters = await getExternalParametersMap(parameterNames, fetchParameters)
  const internalParameters = await getBulkPropertyMap(
    viewer.getAllModels(),
    {
      propFilter: parameterNames,
      needsExternalId: true,
      ignoreHidden: false
    },
    options?.selectionFilter,
    true
  )

  const { results, errors } = await PromisePool.withConcurrency(5)
    .for(filters)
    .process(async (filter) =>
      runFilterWithAzureDocument(externalParameters, internalParameters, fetchAzureDocuments, viewer, filter, options)
    )
  if (errors.length) {
    console.error("Failed to execute filters:", errors)
  }
  return results
}

// #endregion

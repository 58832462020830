// Authentication using Auth0 SPA
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import { Button, Paper, Typography, useTheme } from "@mui/material"
import { Forward as ForwardIcon, VpnKey as KeyIcon } from "@mui/icons-material"
import { useNavigate } from "react-router"
import { SWRError } from "../utils/swr"
import { Link } from "react-router-dom"

const clientId = process.env.REACT_APP_AUTH0_CLIENTID
const authority = process.env.REACT_APP_AUTH0_AUTHORITY
const audience = process.env.REACT_APP_AUTH0_AUDIENCE

if (!clientId) throw new Error("Missing AZUREAD_CLIENTID")
if (!authority) throw new Error("Missing AZUREAD_AUTHORITY")
if (!audience) throw new Error("Missing AZUREAD_AUDIENCE")

//** Auth0 popup login. */
export const useHandleLogin = () => {
  const { loginWithPopup } = useAuth0()
  const handleLogin = () => {
    try {
      loginWithPopup()
    } catch (err) {
      console.error(err)
    }
  }
  return handleLogin
}

//** Auth0 logout and redirect to origin. */
export const useHandleLogout = () => {
  const { logout } = useAuth0()
  const handleLogout = () => logout({ returnTo: window.location.origin })
  return handleLogout
}

//** Use component for Auth0 access token. */
export const useGetAccessToken = (): (() => Promise<{ headers: { Authorization: string } }>) => {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently({ audience: audience })
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  }
  return getToken
}

//** Fetcher with Auth0 authorization header. */
export const useFetchWithToken = () => {
  const getToken = useGetAccessToken()
  const fetcher = async (input: RequestInfo, init?: RequestInit) => {
    const token = await getToken()
    const headersWithToken = { ...init?.headers, ...token.headers }
    const initWithToken = { ...init, headers: headersWithToken }
    return fetch(input, initWithToken)
  }

  return fetcher
}

//** SWR fetcher with Auth0 authorization header. */
export const useFetchWithTokenSWR = () => {
  const fetchWithToken = useFetchWithToken()
  const fetcher = async (input: RequestInfo, init?: RequestInit) => {
    const response = await fetchWithToken(input, init)
    if (!response.ok) {
      const text = await response.text()
      throw new SWRError("An error occured while fetching the data!", response.status, text, response)
    } else {
      const json = await response.json()
      return json
    }
  }
  return fetcher
}

//** Wrapper for Auth0 Authentication provider. */
// Auth0 react components have to be called within provider.
//
// Support history with react-router.
// https://auth0.com/blog/complete-guide-to-react-user-authentication/
//
// Use 'withAuthenticationRequired'
//  - redirects to redirectUri if not (authenticated)
//  - redirects to co
//
// Looks like it does confilict with lazy loading components.
//
// 'audience' option required when getAccessToken is called
export const Auth0P = (props: { children: JSX.Element }) => {
  const navigate = useNavigate()
  // @ts-ignore
  const handleRedirectCallback = (state) => {
    navigate(state?.returnTo || window.location.pathname)
  }
  return (
    <>
      <Auth0Provider
        domain={authority}
        clientId={clientId}
        audience={audience}
        redirectUri={window.location.origin}
        onRedirectCallback={handleRedirectCallback}
      >
        {props.children}
      </Auth0Provider>
    </>
  )
}

//** Auth0 Usage example. */
export const Auth0Profile = () => {
  const { user, isAuthenticated } = useAuth0()
  const handleLogin = useHandleLogin()
  const theme = useTheme()
  const spacing = theme.spacing(1)

  return (
    <>
      <Paper style={{ margin: spacing, padding: spacing }}>
        {!isAuthenticated && (
          <>
            <Typography style={{ margin: spacing }} variant="h5">
              Admin Profile
            </Typography>
            <Button variant="contained" startIcon={<KeyIcon />} onClick={handleLogin}>
              Auth0 Sign In
            </Button>
          </>
        )}

        {isAuthenticated && user && (
          <>
            <Typography style={{ margin: spacing }} variant="h5">
              Welcome {user.name}
            </Typography>
            <Button variant="contained" startIcon={<ForwardIcon />} component={Link} to="/admin">
              Admin Page
            </Button>
          </>
        )}
      </Paper>
    </>
  )
}

import { useMsal } from "@azure/msal-react"
import { Forward } from "@mui/icons-material"
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material"
import { useEffect, useState } from "react"

const api = process.env.REACT_APP_AZUREAD_API
if (!api) throw new Error("Missing AZUREAD_API")

export const scopes = [api]

interface UserProject {
  id: string
  name: string
  role: string
}

const ProjectTable = (props: { projects: UserProject[] }) => {
  const toRow = (project: UserProject) => (
    <TableRow key={project.id} hover={true}>
      <TableCell>{project.name}</TableCell>
      <TableCell>{project.id}</TableCell>
      <TableCell>{project.role}</TableCell>
      <TableCell>
        {project.role !== "Unprivileged" ? (
          <IconButton href={"/viewer/" + project.id}>
            <Forward />
          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  )

  const rows = props.projects.map(toRow)

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {["Name", "Id", "Role", "Viewer"].map((text) => (
              <TableCell key={text}>
                <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  )
}

const Projects = () => {
  const { instance, accounts } = useMsal()
  const [accessToken, setAccessToken] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState<UserProject[] | undefined>()

  const loginRequest = {
    scopes
  }

  useEffect(() => {
    if (!loading) return
    if (!accessToken?.length) return

    fetch("/api/twin/viewer/projects", { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => response.json())
      .then((json) => {
        //@ts-links
        console.debug("Fetch user projects:", json)
        setProjects(json)
        setLoading(false)
      })
  }, [loading, accessToken])

  const requestAccessToken = () => {
    const request = {
      ...loginRequest,
      redirectUri: window.location.origin + "/blank.html",
      account: accounts[0]
    }

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken)
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setAccessToken(response.accessToken)
        })
      })
  }

  useEffect(() => {
    requestAccessToken()
  })
  return !projects ? <CircularProgress /> : <ProjectTable projects={projects} />
}

interface UserData {
  me: { id: string; displayName: string }
  groups: { id: string; displayName: string }[]
}

const User = () => {
  const theme = useTheme()
  const spacing = theme.spacing(1)

  const { instance, accounts } = useMsal()
  const [accessToken, setAccessToken] = useState<string>()
  const [user, setUser] = useState<UserData>()

  const loginRequest = {
    scopes
  }

  useEffect(() => {
    if (!accessToken?.length) return
    fetch("/api/azure/user", { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((resp) => {
        const json = resp.json()
        console.debug("Azure User", json)
        return json
      })
      .then((user) => setUser(user))
  }, [accessToken])

  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      redirectUri: window.location.origin + "/blank.html",
      account: accounts[0]
    }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken)
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setAccessToken(response.accessToken)
        })
      })
  }

  if (!accessToken) RequestAccessToken()
  return (
    <>
      <Paper style={{ margin: spacing, padding: spacing }}>
        <Typography style={{ margin: spacing }} variant="h5">
          Welcome {user?.me.displayName ?? "unknown"}
        </Typography>
        <Projects />
      </Paper>
    </>
  )
}

export default User

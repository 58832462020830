import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material"
import { useRef, useState } from "react"
import useExternalSelection from "../../useHooks/useExternalSelection"
import { TextFieldBooted } from "../../utils/bootstrap"
import { ParameterDeclaration } from "../ManageParametersTab/ParameterList"

import dayjs, { Dayjs } from "dayjs"
import TextField from "@mui/material/TextField"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { ParameterInstance, UpdateParameter } from "./AssignParameters"

type Viewer3D = Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D

interface SetParameterDialogProps {
  viewer: Viewer3D
  open: ParameterDeclaration | ParameterInstance | undefined
  projectId: string
  onCancel: () => void
  onSubmit: (parameter: UpdateParameter | undefined) => void
}

// @ts-ignore
const DatePicker = (props: { date; onSetDate }) => {
  // @ts-ignore
  const textField = (params) => (
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="bootstrap-input" required>
        Date
      </InputLabel>
      <TextField {...params} label="" style={{ marginTop: 24 }} />
    </FormControl>
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label="Date desktop"
        inputFormat="YYYY-MM-DD"
        value={props.date}
        onChange={props.onSetDate}
        renderInput={textField}
      />
    </LocalizationProvider>
  )
}

export const SetParameterDialog = (props: SetParameterDialogProps) => {
  const inputValue = useRef<HTMLInputElement>()
  const externalSelection = useExternalSelection(props.viewer)

  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const handleSetDate = (newDate: Dayjs | null) => setDate(newDate)

  const [boolean, setBoolean] = useState<"true" | "false" | "null">("true")
  const handleSetBoolean = (_event: React.MouseEvent<HTMLElement>, value: "true" | "false" | "null") =>
    setBoolean(value)

  const handleCancel = () => props.onCancel()

  const parameter = props.open

  const handleSubmit = () => {
    const value =
      parameter?.datatype === "text" ||
      parameter?.datatype === "number" ||
      (parameter?.datatype === "date" && !date?.format("YYYY-MM-DD"))
        ? inputValue.current?.value
          ? inputValue.current?.value
          : "null" //to allow reset of text, number and date parameters the initial value "null" will be returned
        : parameter?.datatype === "boolean"
        ? boolean
        : parameter?.datatype === "date"
        ? date?.format("YYYY-MM-DD")
        : undefined

    if (!value) return
    // should be defined if submit is rendered
    if (!externalSelection) return

    const keys = externalSelection.flatMap((sel) =>
      sel.externalIds.map((externalId) => `${sel.documentId}+${externalId}`)
    )

    const mapping = keys.map((key) => [key, value]) as [string, string][]

    const updateParameter: UpdateParameter | undefined = props.open
      ? {
          projectId: props.open?.projectId,
          id: props.open?.parameterId,
          values: mapping
        }
      : undefined

    props.onSubmit(updateParameter)
  }

  // useEffect(() => {
  //   if (props.open !== undefined) setTimeout(() => inputValue.current?.focus(), 1000)
  // }, [props.open])

  if (!parameter) return null

  return (
    <Dialog open={props.open !== undefined}>
      <DialogTitle>Digital Twin Set Parameter</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack spacing={2}>
          <TextFieldBooted id={"id"} label={"Project Id"} defaultValue={props.projectId} disabled />
          <TextFieldBooted id={"id"} label={"Parameter Id"} defaultValue={parameter.parameterId} disabled />
          <TextFieldBooted id={"name"} defaultValue={parameter.name} label={"Name"} disabled />
          <FormControl variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Type
            </InputLabel>
            <ToggleButtonGroup color="primary" value={parameter.datatype} exclusive disabled style={{ marginTop: 24 }}>
              <ToggleButton value="text">Text</ToggleButton>
              <ToggleButton value="number">Number</ToggleButton>
              <ToggleButton value="boolean">Boolean</ToggleButton>
              <ToggleButton value="date">Date</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
          {(parameter.datatype === "number" || parameter.datatype === "text") && (
            <Stack direction="row" spacing={2}>
              <TextFieldBooted id={"value"} inputRef={inputValue} label={"Value"} autoFocus required />
              {parameter.unit?.length && parameter.unit !== "<null>" && (
                <TextFieldBooted id={"unit"} label={"Unit"} defaultValue={parameter.unit} disabled />
              )}
            </Stack>
          )}
          {parameter.datatype === "boolean" && (
            <FormControl variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input" required>
                Boolean
              </InputLabel>
              <ToggleButtonGroup
                color="primary"
                value={boolean}
                exclusive
                style={{ marginTop: 24 }}
                onChange={handleSetBoolean}
              >
                <ToggleButton value="true">true</ToggleButton>
                <ToggleButton value="false">false</ToggleButton>
                <ToggleButton value="null">reset</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          )}
          {parameter.datatype === "date" && <DatePicker date={date} onSetDate={handleSetDate} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={externalSelection === undefined}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// Get/Set camera for AggregatedView */
import { AggregatedView, Viewer3D } from "./Viewer"

type UnifiedCamera = Autodesk.Viewing.UnifiedCamera

export interface Camera {
  readonly isPerspective: boolean
  readonly fov: number
  readonly position: THREE.Vector3
  readonly target: THREE.Vector3
  readonly pivot: THREE.Vector3
  readonly up: THREE.Vector3
}

const getGlobalOffset = (aggregatedView: AggregatedView): THREE.Vector3 =>
  // @ts-ignore
  aggregatedView.globalOffset.clone()

const getUnifiedCamera = (viewer: Viewer3D): UnifiedCamera => viewer.impl.camera as UnifiedCamera

const fromUnifiedCamera = (camera: UnifiedCamera, globalOffset: THREE.Vector3): Camera => ({
  isPerspective: camera.isPerspective,
  fov: Math.round(camera.fov),
  position: camera.position.clone().add(globalOffset),
  target: camera.target.clone().add(globalOffset),
  pivot: camera.pivot.clone().add(globalOffset),
  up: camera.up.clone()
})

/** Returns a primitive camera record. */
export const getCamera = (aggregatedView: AggregatedView): Camera => {
  // @ts-ignore
  const offset = getGlobalOffset(aggregatedView)
  const camera = getUnifiedCamera(aggregatedView.viewer)
  const ucam = fromUnifiedCamera(camera, offset)
  return ucam
}

export const cameraFromString = (data: string): Camera | undefined => {
  const camera = JSON.parse(data)
  if (!camera) return
  const p = camera.position
  const t = camera.target
  const v = camera.pivot
  const u = camera.up
  return {
    ...camera,
    position: new THREE.Vector3(p.x, p.y, p.z),
    target: new THREE.Vector3(t.x, t.y, t.z),
    pivot: new THREE.Vector3(v.x, v.y, v.z),
    up: new THREE.Vector3(u.x, u.y, u.z)
  }
}

const subGlobalOffset = (camera: Camera, globalOffset: THREE.Vector3): Camera => ({
  ...camera,
  position: camera.position.clone().sub(globalOffset),
  target: camera.target.clone().sub(globalOffset),
  pivot: camera.pivot.clone().sub(globalOffset),
  up: camera.up.clone()
})

const syncCamera = (aggregatedView: AggregatedView, camera: Camera, globalOffset: THREE.Vector3) => {
  const withOffset = subGlobalOffset(camera, globalOffset)

  const viewer = aggregatedView.viewer

  const cam = viewer.impl.camera as UnifiedCamera
  cam.position.copy(withOffset.position)
  cam.target.copy(withOffset.target)
  cam.pivot.copy(withOffset.pivot)
  cam.up.copy(withOffset.up)
  cam.fov = withOffset.fov
  cam.isPerspective = withOffset.isPerspective

  //@ts-ignore
  // aggregatedView.cameraInitialized = true
  viewer.impl.syncCamera()
}

/** Apply camera. */
export const setCamera = (aggregatedView: AggregatedView, camera: Camera) => {
  const offset = getGlobalOffset(aggregatedView)
  syncCamera(aggregatedView, camera, offset)
}

/** Support for custom views.
 * A custom view has a camera + viewableIds + levelId.
 * - List Views
 * - Save View
 * - Select/Apply Viw
 */

import { Add } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText
} from "@mui/material"
import { useMemo, useRef, useState } from "react"
import useSWR, { mutate } from "swr"
import { useAppDispatch } from "../app/hooks"
import { useFetchWithToken, useFetchWithTokenSWR } from "../Auth/Msal"
import ActionButton from "../Dashboard/ActionButton"
import { setNotification } from "../features/notification/notificationSlice"
import useUser from "../useHooks/useUser"
import { TextFieldBooted } from "../utils/bootstrap"
import { SWRData } from "../utils/swr"
import { Camera, getCamera, setCamera } from "../Viewer/camera"
import { applyLevel } from "../Viewer/utils"
import { AggregatedView, BubbleNode, Model, Viewables, Viewer3D } from "../Viewer/Viewer"

/** Primite view state object for persistency. */
export interface ViewState {
  /** List of viewable guids. Empty indicates main view. */
  viewableIds: string[]
  /** Current level guid. Undefined indicates NonFloor. */
  levelId?: string
  /** cutplane is a 4 item number array*/
  cutplanes: number[][] | THREE.Vector4[]
  /** Storeable camera state*/
  camera: Camera
}

// #region ViewState

const getViewableIds = (models: Model[]): string[] => models.map((model) => model.getDocumentNode().guid())
const sameIds = (viewableIds1: string[], viewableIds2: string[]) =>
  viewableIds1.sort().join(",") === viewableIds2.sort().join(",")

const getCurrentLevel = (viewer: Viewer3D): string | undefined => viewer.getState().floorGuid

// a cutplane is a 4item number array
const getCutplanes = (viewer: Viewer3D): number[][] => viewer.getState().cutplanes

/** Returns a 'ViewState' of the active view. */
export const getViewState = (aggregatedView: AggregatedView, viewables: Viewables): ViewState => {
  // For robustness, the aggregated view is treated specially. The main view uses an empty viewable list.
  const activeViewableIds = getViewableIds(aggregatedView.viewer.getAllModels())
  const mainViewableIds = viewables.main[0].viewables[0].nodes.flatMap((node) => node.data.guid)
  const viewableIds = sameIds(activeViewableIds, mainViewableIds) ? [] : activeViewableIds

  const levelId = getCurrentLevel(aggregatedView.viewer)
  const cutplanes = getCutplanes(aggregatedView.viewer)
  const camera = getCamera(aggregatedView)

  return { viewableIds, levelId, cutplanes, camera }
}

/** Returns a list of 'BubbleNode' objects to load from a given view state.
 * Uses 'viewables' and not the viewer documents to search for the node.
 * So viewables should contain all 2d/3d viewables.
 */
const getNodes = (viewables: Viewables, viewState: ViewState): BubbleNode[] => {
  const viewableIds = viewState.viewableIds

  // If empty, use main nodes.
  if (!viewableIds.length) return viewables.main[0].viewables[0].nodes

  const viewableNodes3d = viewables.threed.flatMap((namedViewable) =>
    namedViewable.viewables.flatMap((group) => group.nodes)
  )
  const viewableNodes2d = viewables.twod.flatMap((namedViewable) =>
    namedViewable.viewables.flatMap((group) => group.nodes)
  )
  const viewableNodes = [...viewableNodes3d, ...viewableNodes2d]
  return viewableNodes.filter((node) => viewableIds.includes(node.data.guid))
}

export const applyViewState = async (aggregatedView: AggregatedView, viewables: Viewables, viewState: ViewState) => {
  const nodes = getNodes(viewables, viewState)
  aggregatedView.unloadAll((item) => {
    const urn = item.model.myData.urn
    return !nodes.find((node) => node.getRootNode().urn === urn)
  })

  // reset before setting view, otherwise there are prooblems with 2D views
  aggregatedView.viewer.setCutPlanes([])
  await aggregatedView.switchView(nodes, null)
  await aggregatedView.waitForLoadDone()
  const camera = viewState.camera
  setCamera(aggregatedView, camera)
  aggregatedView.viewer.setCutPlanes(viewState.cutplanes as THREE.Vector4[])
  applyLevel(aggregatedView.viewer, viewState.levelId)
}
// #endregion ViewState

// #region ViewList

// NOTE: view state is persistet as string using JSON.stringify.
// Further, the camera uses THREE.Vector3 object

interface View {
  name: string
  id: string
  viewState: ViewState
}

interface ViewDTO {
  name: string
  id: string
  viewState: string
}

export const fromViewStateString = (data: string): ViewState | undefined => {
  try {
    const viewState: ViewState = JSON.parse(data)
    if (!viewState.camera) return

    const cameraDTO = viewState.camera
    const p = cameraDTO.position
    const t = cameraDTO.target
    const v = cameraDTO.pivot
    const u = cameraDTO.up
    const camera = {
      ...cameraDTO,
      position: new THREE.Vector3(p.x, p.y, p.z),
      target: new THREE.Vector3(t.x, t.y, t.z),
      pivot: new THREE.Vector3(v.x, v.y, v.z),
      up: new THREE.Vector3(u.x, u.y, u.z)
    }

    const cutplanesDTO = (viewState.cutplanes as number[][]) ?? []
    const cutplanes = cutplanesDTO.map(
      (cutplane) => new THREE.Vector4(cutplane[0], cutplane[1], cutplane[2], cutplane[3])
    )
    return { ...viewState, cutplanes, camera }
  } catch (error) {
    console.error("Failed to parse view state.", error)
    return
  }
}

const useViews = (projectId: string): SWRData<View[]> => {
  const fetcher = useFetchWithTokenSWR()

  const { data, error } = useSWR<ViewDTO[]>(`/api/twin/viewer/projects/${projectId}/views`, fetcher)

  const views = useMemo(() => {
    if (!data) return
    return data
      .map((dto) => ({ ...dto, viewState: fromViewStateString(dto.viewState) }))
      .filter((view) => view.viewState) as View[]
  }, [data])

  return {
    data: views ?? [],
    loading: !error && !views,
    error
  }
}

interface ViewListProps {
  projectId: string
  onSelect: (view: View) => void
}

const ViewList = (props: ViewListProps) => {
  const { data: views } = useViews(props.projectId)
  const items = views.map((view) => (
    <ListItemButton divider key={view.id}>
      <ListItemText primary={view.name} onClick={() => props.onSelect(view)} />
    </ListItemButton>
  ))
  return <List>{items}</List>
}

// #endregion

// #region AddViewStateDialog

interface AddViewStateDialogProps {
  open: ViewState | undefined
  onCancel: () => void
  onSubmit: (addView: AddView | undefined) => void
}

interface AddView {
  name: string
  viewState: string
}

const AddViewStateDialog = (props: AddViewStateDialogProps) => {
  const inputRefName = useRef<HTMLInputElement>()

  const viewState = props.open
  if (!viewState) return <></>

  const handleCancel = () => props.onCancel()
  const handleSubmit = () => {
    const name = inputRefName.current?.value

    const addView: AddView | undefined =
      name?.length && viewState
        ? {
            name,
            viewState: JSON.stringify(viewState)
          }
        : undefined

    props.onSubmit(addView)
  }

  return (
    <Dialog open={props.open !== undefined}>
      <DialogTitle>Digital Twin Add View</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <TextFieldBooted
          id={"name"}
          inputRef={inputRefName}
          defaultValue={"View Name"}
          label={"Name"}
          autoFocus={true}
          required={true}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}
// #endregion

// #region Views
interface ViewsProps {
  projectId: string
  aggregatedView: AggregatedView
  viewables: Viewables
  onSelect: () => void
}

const Views = (props: ViewsProps) => {
  const projectId = props.projectId
  const dispatch = useAppDispatch()
  const fetchWithToken = useFetchWithToken()
  const { isUser } = useUser(projectId)

  const [openAddViewDialog, setOpenAddViewDialog] = useState<ViewState>()
  const handleCloseAddViewDialog = () => setOpenAddViewDialog(undefined)
  const handleOpenAddViewDialog = () => {
    const viewState = getViewState(props.aggregatedView, props.viewables)
    setOpenAddViewDialog(viewState)
  }

  const handleCancelAddView = () => setOpenAddViewDialog(undefined)
  const handleSubmitAddView = (addView: AddView | undefined) => {
    handleCloseAddViewDialog()
    if (!addView) {
      dispatch(setNotification({ status: "success", message: "Failed to add view." }))
      return
    }

    const url = `/api/twin/viewer/projects/${props.projectId}/views`

    fetchWithToken(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(addView)
    })
      .then((response) => {
        if (response.status === 200) {
          mutate(url)
          dispatch(setNotification({ status: "success", message: "View added" }))
        } else {
          console.error(response.text)
          dispatch(setNotification({ status: "error", message: "Failed to add view." }))
        }
      })
      .catch((reason) => {
        console.error(reason)
        dispatch(setNotification({ status: "error", message: "Failed to add view." }))
      })
  }

  const handleSelect = (view: View) => {
    applyViewState(props.aggregatedView, props.viewables, view.viewState).then(() => props.onSelect())
  }

  return (
    <>
      <ActionButton startIcon={<Add />} onClick={handleOpenAddViewDialog} disabled={!isUser}>
        Save
      </ActionButton>
      <AddViewStateDialog open={openAddViewDialog} onCancel={handleCancelAddView} onSubmit={handleSubmitAddView} />
      <ViewList projectId={projectId} onSelect={handleSelect}></ViewList>
    </>
  )
}
// # endregion

export default Views

import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./index.scss"
import { createTheme, ThemeProvider } from "@mui/material"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { CaptureConsole } from "@sentry/integrations"
import GitInfo from "../macro"

import App from "./App"

export const environment = process.env.REACT_APP_ENV
if (!environment) throw new Error("REACT_APP_ENV not defined")

const gitInfo = GitInfo()
export const version: string = gitInfo.info ?? "no-version"

Sentry.init({
  dsn: "https://dd9b31e2575b4528942eba3e35480e58@o931378.ingest.sentry.io/4504605020192768",
  integrations: [
    new BrowserTracing(),
    // capture console.error
    new CaptureConsole({ levels: ["error"] })
  ],
  environment,
  release: version,
  tracesSampleRate: 0.2
})

const container = document.getElementById("root")!
const root = createRoot(container)

// use window.store.getState() in the console to inspect state
// @ts-ignore
// window.store = store

const muiTheme = createTheme({
  // example for re-styling e.g. query-builder
  // palette: {
  //   secondary: {
  //     main: teal[500],
  //   },
  // },
})
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
